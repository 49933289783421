import { Radio } from "@mui/material";
import React from "react";
import {
  RBIconCheckedCustom,
  RBIconCustom,
} from "../../constant/textFieldConstant";

function RadioButtonCustom(props) {
  return (
    // Inspired by blueprintjs

    <Radio
      sx={{
        "&:hover": { bgcolor: "transparent" },
        padding: "9px 9px 9px 0"
      }}
      disableRipple
      color="default"
      checkedIcon={<RBIconCheckedCustom />}
      icon={<RBIconCustom />}
      {...props}
    />
  );
}

export default RadioButtonCustom;
