import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// MATERIAL UI
import { Box, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
// ICONS
import LinkedInIcon from '../icons/LinkedInIcon'
// CONSTANT
import { boxShadowStyle } from '../../constant/shadowConstant'
import { fontPreset } from '../../constant/fontConstant'
import { paletteColorConstant } from '../../constant/colorConstant'

const PersonCard = ({ data }) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box className='person__card' 
        sx={{ 
            borderRadius: '20px',
            width: downMediumScreen ? '160px' : '360px',
            boxShadow: downMediumScreen ? boxShadowStyle.generalShadow.boxShadow : "none",
            '&:hover': { ...boxShadowStyle.generalShadow }
        }}
    >
        <Box className='person__image'
            sx={{ height: downMediumScreen ? "160px" : '360px', width: downMediumScreen ? '160px' : '360px', borderRadius: '20px 20px 0 0' }}
        >
            <LazyLoadImage 
                alt={`person-c-level`}
                src={data.foto}
                width={'100%'}
                height={'100%'}
                style={{ borderRadius: '20px 20px 0 0' }}
                effect={"blur"}
            />
        </Box>
        <Box className='person__desc'
            sx={{ 
                height: downMediumScreen ? "130px" : '210px',
                width: downMediumScreen ? '160px' : '360px',
                borderRadius: '0 0 20px 20px',
                padding: downMediumScreen ? "12px 8px 8px" : '20px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}
        >
            <Box className='person__identity' 
                sx={{ marginBottom: downMediumScreen ? '16px' : '12px' }}
            >
                <Typography
                    variant='body1' 
                    sx={{ color: paletteColorConstant.black1, marginBottom: downMediumScreen ? '4px' : '8px' }}
                    style={downMediumScreen ? fontPreset.body12Reg : fontPreset.body18Reg}
                    align='center'
                >
                    { data.nama }
                </Typography>
                <Typography
                    variant='body1'
                    sx={{ color: paletteColorConstant.black2, marginBottom: downMediumScreen ? '4px' : '8px' }}
                    style={downMediumScreen ? fontPreset.body12Reg : fontPreset.body18Reg}
                    align='center'
                >
                    { data.level }
                </Typography>
                <Box>
                    <Typography 
                        variant='body1'
                        sx={{ color: paletteColorConstant.black2 }}
                        style={downMediumScreen ? { fontSize: "8px", fontWeight: "300" } : fontPreset.body16Lig }
                        align='center'
                    >
                        { data.quotes }
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Link
                    href={data.linkedIn}
                    underline="none"
                    target="_blank"
                    rel={"noopener"}
                    sx={{ 
                        width: downMediumScreen ? "20px" : "40px",
                        height: downMediumScreen ? "20px" : "40px",
                        display: "flex",
                        alignItems: 'center'
                    }}
                >
                    <LinkedInIcon color={paletteColorConstant.primaryBlue150} />
                </Link>
            </Box>
        </Box>
    </Box>
  )
}

export default PersonCard