import { React, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./style/home.css";
import imagesPeople from "../images/lets-find-what-you-need.png";
// MATERIAL UI
import { Box, Button, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
// COMPONENTS
import WhatWeDoSlider from "../components/ProductAndServices/WhatWeDoSlider";
import ConsultationDialog from "../components/Home/ConsultationDialog";
import ProductList from "../components/ProductAndServices/ProductList";
// CONSTANT
import { fontPreset, mobileFontPreset } from "../constant/fontConstant";
import { paletteColorConstant } from "../constant/colorConstant";
import { scrollToContent } from "../constant/stylePreset";
import { Helmet } from "react-helmet-async";

const style = {
  sectionOne: {},
  sectionTwo: {
    display: "flex",
    flexDirection: "column",
  },
  productAndService_Container: {
    width: "100%",
    textAlign: "center"
  },
  emptyResult: {
    height: "50vh",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  consultationSection: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
};

const ProductServices = () => {
  const location = useLocation()
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  const [openConsultationDialog, setOpenConsultationDialog] = useState(false);

  const divRef = useRef(null);
  
  const handleOpenConsultationDialog = () => {
    setOpenConsultationDialog(true);
  };

  const handleCloseConsultationDialog = () => {
    setOpenConsultationDialog(false);
  };

  useEffect(() => {
    if(location.search.slice(0).includes('category=')) {
      setTimeout(() => {
        scrollToContent("coming__soon__text")
      }, 700)
    }

    if(location.search.slice(0).includes('section=our-package')) {
      setTimeout(() => {
        scrollToContent("our__package__section")
      }, 700)
    }
  }, []);

  return (
    <>
      <Helmet>
          <title>Product & Services</title>
          <meta name="description" content="Divine Pro menyediakan jasa layananan dan peralatan untuk kebutuhan event" />

          <meta property="og:title" content="Product & Services" />
          <meta property="og:description" content="Divine Pro menyediakan jasa layananan dan peralatan untuk kebutuhan event" />
          <meta property="og:site_name" content="Divine Pro" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content="/divine-logo.png" />
      </Helmet>

      <div className="productAndService__container"
        style={style.productAndService_Container}
      >
        <section className="section_one_container"
          style={{
            backgroundImage: `url(/images/Product_And_Service/BackgroundImages_Product_And_Service.png)`,
            padding: downMediumScreen ? "60px 0 20px 0" : "192px 0",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "85vh",
            maxHeight: "683px",
          }}
        >
          <Container>
            <Typography
              variant="h1"
              sx={{ color: paletteColorConstant.black2 }}
              style={downMediumScreen ? mobileFontPreset.titleXL : fontPreset.titleL}
            >
              Yang Kami Lakukan
            </Typography>
          </Container>

          <div className="section_one" style={style.sectionOne}>
            <WhatWeDoSlider />
          </div>
        </section>

        <section className="section__two">
          <Box
            ref={divRef}
            style={{ ...style.sectionTwo }}
            sx={{ padding: downMediumScreen ? "60px 0" : "50px 0" }}
          >
            <Container>
              <Typography
                variant="h1"
                sx={{ marginBottom: downMediumScreen ? "40px" : "50px", color: paletteColorConstant.black2 }}
                style={downMediumScreen ? mobileFontPreset.titleXL : fontPreset.titleL}
              >
                Daftar Harga Kami
              </Typography>
            </Container>

            <ProductList />
          </Box>
        </section>

        <section className="section_three">
          <Box 
            sx={{ paddingY: "0px", backgroundColor: "#FFF" }}
          >
            <Container sx={{ padding: downMediumScreen && "0 30px 0 0" }}>
              <Box sx={{ display: "flex", paddingTop: downMediumScreen ? "10px" : "40px" }}>
                <Box
                  sx={{
                    width: "50%",
                    minHeight: downMediumScreen ? "222px" : "412px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: downMediumScreen ? "65% 100%" : "50% 100%",
                    backgroundImage: `url(${imagesPeople})`,
                    backgroundSize: "contain"
                  }}
                />

                <Box sx={style.consultationSection}>
                  <Typography
                    align="left"
                    component={"h3"}
                    sx={{ marginBottom: downMediumScreen ? "12px" : "20px", color: paletteColorConstant.black2 }}
                    style={downMediumScreen ? fontPreset.body14Med : fontPreset.titleL}
                  >
                    Sesuaikan Budget dengan Eventmu!
                  </Typography>

                  <Typography
                    align="left"
                    variant="body1"
                    sx={{ lineHeight: downMediumScreen ? "14.06px" : "28.13px", color: paletteColorConstant.black2 }}
                    style={downMediumScreen ? mobileFontPreset.body12Lig : fontPreset.body20Reg}
                  >
                    Hubungi kami segera, sehingga Anda dapat merinci semua kebutuhan yang diperlukan. Tim kami akan menghubungi Anda secara cepat untuk membicarakan informasi lebih lanjut.
                  </Typography>

                  <Box sx={{ marginTop: downMediumScreen ? "20px" : "40px" }}>
                    <Button
                      size={downMediumScreen ? "medium" : "large"}
                      variant="contained"
                      onClick={handleOpenConsultationDialog}
                    >
                      Diskusi dengan Kami
                    </Button>
                    <ConsultationDialog
                      open={openConsultationDialog}
                      handleClose={handleCloseConsultationDialog}
                    />
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        </section>
      </div>
    </>
  );
};

export default ProductServices;
