import axios from 'axios';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    cityList: null,
    loading: false,
    error: false
}

export const getCity = createAsyncThunk('city/getAllCity', async (_, thunkAPI) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/city/v1/getAllCity`)
        return res.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

const citySlice = createSlice({
    name: 'cityList',
    initialState,
    extraReducers: {
        [getCity.pending]: (state) => {
            state.loading = true
        },
        [getCity.fulfilled]: (state, action) => {
            state.loading = false
            state.cityList = action.payload
        },
        [getCity.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export default citySlice.reducer