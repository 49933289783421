import React from 'react'
import PropTypes from 'prop-types'
// MATERIAL UI
import { Box, Typography } from '@mui/material'
// COMPONENTS
import { PhoneNumberFormatCustom } from './PhoneNumberFormatCustom'
// CONSTANT
import { CustomTextField } from '../../constant/textFieldConstant'

const PhoneNumberTextField = ({ 
    label,
    placeholder = '81234567890',
    size = 'small',
    value,
    onChange,
    textFieldError = { error: false, helperText: '' },
    setTextFieldError,
    readOnly,
    disableLabel = false
}) => {
  return (
    <Box>
        { !disableLabel && 
            <Box mb={.5}>
                <Typography variant='body1'>
                    { label }
                </Typography>
            </Box>
        }
        <CustomTextField
            placeholder={placeholder}
            size={size}
            value={value}
            onChange={(e) => {
                setTextFieldError && setTextFieldError({ error: false, helperText: '' })
                onChange(e.target.value)
            }}
            InputProps={{ 
                inputComponent: PhoneNumberFormatCustom,
                readOnly
            }}
            fullWidth
        />
    </Box>
  )
}

PhoneNumberTextField.propTypes = {
    label: PropTypes.string,
    textFieldLabel: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    textFieldError: PropTypes.object,
    backgroundColor: PropTypes.string,
    readOnly: PropTypes.bool
}

export default PhoneNumberTextField