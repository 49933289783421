import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import validator from "validator";
import moment from "moment";
import { LazyLoadImage } from 'react-lazy-load-image-component';
//CSS
import "./style/ScrollBar.css";
import "./style/ConsultationDialog.css";
// MATERIAL UI
import { Autocomplete, Box, Button, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, MenuItem, RadioGroup, Select, Snackbar, Step, StepLabel, Stepper, ThemeProvider, Typography, useMediaQuery, useTheme, } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import LoadingButton from '@mui/lab/LoadingButton';
// ICONS
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// CONSTANT
import { fontPreset, mobileFontPreset } from "../../constant/fontConstant";
import { ColorlibStepIcon, CustomAutoCompleteTextField, CustomConnector, CustomDialog, CustomTextField, CustomTimePickerField, menuSelectStyle, priceFormat, SelectInputCustom, } from "../../constant/textFieldConstant"; 
import { paletteColorConstant } from "../../constant/colorConstant";
import { FadeTransition, UpTransition } from "../../constant/stylePreset";
// COMPONENTS
import RadioButtonCustom from "../common/RadioButtonCustom";
import PriceTextField from "../common/PriceTextField";
import PhoneNumberTextField from "../common/PhoneNumberTextField";
// SLICE
import { postConsultation, resetConsultation, startToFalse } from "../../features/consultation/consultationSlice";
import { getCity } from "../../features/consultation/citySlice";

const steps = [
  "Nama & Instansi",
  "Jenis & Kebutuhan Event",
  "Tanggal & Lokasi Event",
  "Konfirmasi",
];

const style = {
  centerAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stepper: {
    width: "820px",
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
  },
  confirmationSection: {
    padding: "45px",
    position: "relative",
    height: "480px",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "20px",
    position: "absolute",
    bottom: "40px",
    right: "40px",
  },
  buttonSectionMobile: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "20px",
    marginTop: "20px",
  },
  illustrationImage: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  formField: {
    display: "flex",
    flexDirection: "column",
    rowGap: "25px",
    height: "480px",
  },
  step4FontMobileTitle: {
    ...fontPreset.body12Lig,
    color: paletteColorConstant.gray1,
    marginBottom: "4px",
  },
  step4FontMobile: {
    ...fontPreset.body12Lig,
    color: paletteColorConstant.black1,
  },
  step4FontWeb: {
    color: "#707070",
    marginBottom: "4px",
    fontSize: "12px",
  },
};

const menuSelect = {
  companyTypeSelect: [
    { menu: "Perusahaan", value: "Perusahaan", id: "perusahaan" },
    {
      menu: "Organisasi Non-profit / Komunitas",
      value: "Organisasi Non-profit / Komunitas",
      id: "komunitas",
    },
    {
      menu: "Sekolah / Universitas",
      value: "Sekolah / Universitas",
      id: "sekolah",
    },
    { menu: "Individual", value: "Individual", id: "individual" },
  ],
  eventTypeSelect: [
    { menu: "Full Online", value: "Full Online" },
    { menu: "Full Offline", value: "Full Offline" },
    { menu: "Hybrid", value: "Hybrid" },
  ],
  totalAttendanceSelect: [
    { menu: "1 - 50 Peserta", value: "1 - 50 Peserta" },
    { menu: "51 - 100 Peserta", value: "51 - 100 Peserta" },
    { menu: "101 - 500 Peserta", value: "101 - 500 Peserta" },
    { menu: "501 - 1000 Peserta", value: "501 - 1000 Peserta" },
    { menu: "> 1000 Peserta", value: "> 1000 Peserta" },
  ],
};

const contactOptions = ["Email Kamu", "WhatsApp Kamu"]

const ConsultationDialog = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const CHARACTER_LIMIT = 280;

  const consultationState = useSelector((state) => state.consultationForm)
  const { loading, error, success, start } = consultationState

  const cityState = useSelector((state) => state.cityList)
  const { cityList } = cityState

  const [radioVal, setRadioVal] = useState("Email Kamu");
  const [step, setStep] = useState(1);
  // STEP 1 FORM
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [companyType, setCompanyType] = useState("placeholder");
  const [companyName, setCompanyName] = useState("");
  const [display, setDisplay] = useState("none");
  // STEP 2 FORM
  const [eventType, setEventType] = useState("placeholder");
  const [totalAttendance, setTotalAttendance] = useState("placeholder");
  const [eventDesc, setEventDesc] = useState("");
  // STEP 3 FORM
  const [eventDate, setEventDate] = useState(new Date());
  const [cityName, setCityName] = useState("");
  const [budgetEstimation, setBudgetEstimation] = useState("");

  const [errorSubmit, setErrorSubmit] = useState({ error: false, helperText: "" })
  const [done, setDone] = useState(false);

  const nextStep = () => {
    if (
      (step === 1 && !validator.isEmail(contact) && radioVal === "Email Kamu") ||
      (step === 1 && !validator.isMobilePhone(contact) && radioVal === "WhatsApp Kamu")
    ) {
      setDisplay("error3")
      setContact("")
    } else if (step === 2 && eventDesc.length < 20) {
      setDisplay("error4")
      setEventDesc("")
    } else {
      if (start) dispatch(startToFalse())
      if (step === 2) dispatch(getCity())
      if (step > 4) setDone(true)

      setDisplay("none")
      setStep((step) => step + 1)
    }
  }

  const jumpToStep = (stepNumber) => {
    setStep(stepNumber);
  }

  const checkDisabledButton = () => {
    let disableButton = true;
    if (name !== "" && contact !== "" && companyType !== "placeholder") {
      if (companyType === "Perusahaan") {
        if (companyName !== "") disableButton = false;
        else disableButton = true;
      } else {
        disableButton = false;
      }
    }
    return disableButton;
  }

  const checkDisabledButtonTwo = () => {
    let disableButton = true;
    if (
      eventType !== "placeholder" &&
      totalAttendance !== "placeholder" &&
      eventDesc !== ""
    ) {
      disableButton = false;
    }
    return disableButton;
  }

  const checkDisabledButtonThree = () => {
    let disableButton = true;
    if (
      eventDate !== "" &&
      cityName !== "placeholder" &&
      budgetEstimation !== ""
    ) {
      disableButton = false;
    }
    return disableButton;
  }

  const submitConsultation = () => { 
    const postData = {
      name: name,
      contactChanel: radioVal,
      contact: contact,
      agencyType: companyType,
      agencyName: companyName,
      eventType: eventType,
      numberOfParticipants: totalAttendance,
      description: eventDesc,
      eventDate: printTimeFormat(eventDate),
      eventLocation: cityName,
      budgetEstimation: budgetEstimation
    }
    dispatch(postConsultation(postData))
  }

  const printTimeFormat = (date) => {
    const DAY_CONSTANT = date.getDate()
    const MONTH_CONSTANT = date.getMonth() + 1

    const year = date.getFullYear()
    const month = MONTH_CONSTANT < 10 ? `0${MONTH_CONSTANT}` : MONTH_CONSTANT
    const day = DAY_CONSTANT < 10 ? `0${DAY_CONSTANT}` : DAY_CONSTANT
    
    return `${year}-${month}-${day}`
  }

  const resetAllValue = () => {
    setStep(1);
    // STEP 1 FORM
    setName("");
    setContact("");
    setCompanyType("placeholder");
    setCompanyName("");
    // STEP 2 FORM
    setEventType("placeholder");
    setTotalAttendance("placeholder");
    setEventDesc("");
    // STEP 3 FORM
    setEventDate(new Date());
    setCityName("");
    setBudgetEstimation("");
  }

  const handleFinishButton = () => {
    handleClose(done)
    // resetAllValue()
    dispatch(resetConsultation())
  }

  const handleCloseErrorSubmitSnackbar = (event, reason) => {
    if (reason === 'clickaway') { return }

    setErrorSubmit({ error: false, helperText: "" })
  }

  useEffect(() => {
    if(start) {
      // STATE START === TRUE
      // UNTUK PERTAMA KALI BUKA DIALOG
      resetAllValue()
    } else if(error) { 
      // STATE ERROR SETELAH SUBMIT
      setErrorSubmit({ error: true, helperText: `Oops, terjadi kesalahan pada server. ${error.message ?? ""}` })
    } else if(success) { 
      // STATE SUCCESS SUBMIT
      nextStep() 
    }
  }, [error, success, start])

  return (
    <CustomDialog
      open={open}
      fullWidth
      fullScreen={downMediumScreen}
      maxWidth={"xmd"}
      PaperProps={{
        style: { borderRadius: downMediumScreen ? "0" : "12px" },
      }}
      TransitionComponent={downMediumScreen ? UpTransition : FadeTransition}
    >
      <DialogTitle sx={{ display: step > 4 ? "none" : "block" }}>
        <Box
          sx={{ display: "flex", padding: downMediumScreen ? "30px 20px 0" : "0 40px" }}
        >
          <Typography
            variant="h1"
            sx={{ color: paletteColorConstant.black2, flexGrow: 1 }}
            style={
              downMediumScreen
                ? { ...mobileFontPreset.titleL }
                : { ...fontPreset.titleL }
            }
            align="center"
          >
            Konsultasikan Kebutuhan Eventmu
          </Typography>

          <IconButton
            aria-label="close"
            size={downMediumScreen ? "small" : "large"}
            sx={{ position: "absolute", top: "20px", right: "20px" }}
            onClick={() => {
              // resetAllValue();
              handleClose(done);
              dispatch(resetConsultation());
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      {step <= 4 && 
        <Box className="stepper"
          sx={{ 
            ...style.centerAlign,
            paddingBottom: downMediumScreen ? "30px" : "40px"
          }}
        >
          <Box
            sx={{
              ...style.stepper,
              padding: downMediumScreen ? "0 20px" : "0",
              marginTop: downMediumScreen ? "10px" : "0",
            }}
          >
            <Stepper
              activeStep={step - 1}
              connector={<CustomConnector />}
              alternativeLabel={downMediumScreen}
            >
              {steps.map((label, index) => (
                <Step
                  key={label}
                  sx={{
                    position: "relative",
                    padding: downMediumScreen ? "0 0 4px 0" : "0 0 8px 0",
                    transition: "all 250ms ease",
                    "&:after": {
                      content: '""',
                      position: step === index + 1 ? "absolute" : "none",
                      backgroundColor: paletteColorConstant.primaryBlue150,
                      height:
                        step >= index + 1 ? 
                          downMediumScreen ? "2px" : "4px" : 
                        // STEP < INDEX
                          "0",
                      width: step >= index + 1 ? "100%" : "0",
                      left: 0,
                      bottom: "-4px",
                      borderRadius: "2px",
                      transition: "all 250ms ease",
                    },
                  }}
                >
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <Typography
                      sx={{
                        fontSize: downMediumScreen ? "10px" : "18px",
                        transition: "all 150ms ease",
                        color:
                          step >= index + 1
                            ? paletteColorConstant.primaryBlue150
                            : "#CACACA",
                      }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      }

      <DialogContent
        sx={{ alignItems: "center", /* overflowY: downMediumScreen ? "hidden" : "visible" */ }}
      >
        {step <= 4 && (
          <>
            <div className="consutation__content"
              style={{
                display: step !== 4 ? "flex" : "none",
                flexDirection: downMediumScreen ? "column-reverse" : "row",
                position: "relative",
                overflowY: "auto"
              }}
            >
              <div className="illustration_image"
                style={{
                  ...style.illustrationImage,
                  width: downMediumScreen ? "100%" : "50%",
                  height: downMediumScreen ? "300px" : "",
                  backgroundPosition:
                    step === 2 ? 
                      downMediumScreen ? "100% 100%"  : "50% 150%" : 
                    // STEP !== 2
                      "50% 100%",
                  backgroundImage:
                    step === 1
                      ? "url(/images/home/consultation_dialog/step_1.png)"
                      : step === 2
                      ? "url(/images/home/consultation_dialog/step_2.png)"
                      : step === 3
                      ? "url(/images/home/consultation_dialog/step_3.png)"
                      : "",
                }}
              />

              <div className="form__field"
                style={{
                  ...style.formField,
                  width: downMediumScreen ? "100%" : "50%",
                  padding: downMediumScreen
                    ? "0 20px"
                    : "0 40px 0 0",
                }}
              >
                {step === 1 && (
                  <>
                    <div className="step__one__form"
                      style={{ ...style.formSection }}
                    >
                      <div className="nama_lengkap_field">
                        <Typography 
                          sx={{ ...fontPreset.body14Reg, marginBottom: "4px" }}
                        >
                          Nama Lengkap Kamu
                        </Typography>
                        <CustomTextField
                          placeholder="Contoh: Aditya"
                          variant="outlined"
                          size="medium"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          fullWidth
                        />
                      </div>

                      <div className="email_kamu_field">
                        <FormControl>
                          <RadioGroup
                            defaultValue="Email Kamu"
                            row
                            value={radioVal}
                            onChange={(e) => {
                              setContact("");
                              setRadioVal(e.target.value);
                            }}
                          >
                            { contactOptions.map((val, index) => (
                                <FormControlLabel
                                  key={`contact-options-${index + 1}}`}
                                  value={val}
                                  control={<RadioButtonCustom size="small" />}
                                  label={val}
                                  sx={{
                                    "& .MuiFormControlLabel-label": {
                                      ...fontPreset.body14Lig,
                                      color: radioVal === val && paletteColorConstant.primaryBlue150,
                                      transition: "color 100ms ease-in",
                                    },
                                    margin: "0 12px 0 0"
                                  }}
                                />
                              )) }
                          </RadioGroup>
                        </FormControl>

                        { radioVal === "Email Kamu" ? 
                            <CustomTextField
                              placeholder={"Contoh: divine@email.com"}
                              variant="outlined"
                              size="small"
                              value={contact}
                              onChange={(e) => {
                                setContact(e.target.value);
                              }}
                              fullWidth
                            /> : 
                          // NOMOR TELP (whatsapp)
                            <PhoneNumberTextField
                              placeholder={"Contoh: 081234567890"}
                              variant="outlined"
                              size="small"
                              value={contact}
                              onChange={setContact}
                              disableLabel
                              fullWidth
                            />
                        }
                        {display === "error2" || display === "error3" ? (
                          <Typography
                            sx={{ color: "red", ...fontPreset.body14Lig, display: display }}
                          >
                            Mohon isi sesuai format dalam contoh
                          </Typography>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="jenis_instansi_field">
                        <Typography
                          sx={{ ...fontPreset.body14Reg, marginBottom: "4px" }}
                        >
                          Jenis Instansi
                        </Typography>
                        <Select
                          labelId="select-jenis-instansi"
                          id="select-jenis-instansi"
                          size="small"
                          fullWidth
                          input={<SelectInputCustom />}
                          IconComponent={ArrowBackIosNewIcon}
                          value={companyType}
                          onChange={(e) => {
                            setCompanyType(e.target.value);
                            setCompanyName("");
                          }}
                          renderValue={(selected) => {
                            if (selected === "placeholder") {
                              return (
                                <Typography
                                  sx={{ color: paletteColorConstant.gray2 }}
                                >
                                  Pilih Jenis Instansi
                                </Typography>
                              );
                            } else {
                              return selected;
                            }
                          }}
                          MenuProps={{ PaperProps: { style: menuSelectStyle } }}
                        >
                          <MenuItem disabled value={"placeholder"}>
                            Pilih Jenis Instansi
                          </MenuItem>
                          {menuSelect.companyTypeSelect.map((val) => (
                            <MenuItem key={val.value} value={val.value}>
                              {val.menu}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <div className="nama_instansi_field">
                        <Typography
                          sx={{ ...fontPreset.body14Reg, marginBottom: "4px" }}
                        >
                          Nama Instansi
                        </Typography>
                        <CustomTextField
                          placeholder={
                            companyType === "Individual"
                              ? "Kosongkan jika pilihan instansi adalah Individual"
                              : // ORGANISASI / PERUSAHAAN / SEKOLAH
                                "Contoh: Pegadaian"
                          }
                          variant="outlined"
                          size="small"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          disabled={
                            companyType === "Individual" ||
                            companyType === "placeholder"
                          }
                          fullWidth
                        />
                      </div>
                    </div>

                    <div className="action__button"
                      style={downMediumScreen ? style.buttonSectionMobile : style.buttonSection}
                    >
                      <Button
                        sx={{ marginTop: "0px" }}
                        onClick={() => {
                          handleClose();
                          dispatch(resetConsultation());
                          // resetAllValue();
                        }}
                        size="large"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ marginTop: "0px" }}
                        onClick={() => {
                          nextStep();
                        }}
                        disabled={checkDisabledButton()}
                        size="large"
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}

                {step === 2 && (
                  <>
                    <div style={style.formSection} className="step__two__form">
                      <div className="jenis_event_field">
                        <Typography sx={{ ...fontPreset.body14Reg, marginBottom: "4px" }}>
                          Jenis Event
                        </Typography>
                        <Select
                          labelId="select-jenis-event"
                          id="select-jenis-event"
                          size="small"
                          fullWidth
                          input={<SelectInputCustom />}
                          IconComponent={ArrowBackIosNewIcon}
                          value={eventType}
                          onChange={(e) => setEventType(e.target.value)}
                          renderValue={(selected) => {
                            if (selected === "placeholder") {
                              return (
                                <Typography
                                  sx={{ color: paletteColorConstant.gray2 }}
                                >
                                  Pilih Jenis Event
                                </Typography>
                              );
                            } else {
                              return selected;
                            }
                          }}
                          MenuProps={{ PaperProps: { style: menuSelectStyle } }}
                        >
                          <MenuItem disabled value={"placeholder"}>
                            Pilih Jenis Event
                          </MenuItem>
                          {menuSelect.eventTypeSelect.map((val) => (
                            <MenuItem key={val.value} value={val.value}>
                              {val.menu}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <div className="total_jumlah_peserta_field">
                        <Typography sx={{ ...fontPreset.body14Reg, marginBottom: "4px" }}>
                          Estimasi Jumlah Peserta
                        </Typography>
                        <Select
                          labelId="select-total-jumlah-peserta"
                          id="select-total-jumlah-peserta"
                          size="small"
                          fullWidth
                          input={<SelectInputCustom />}
                          IconComponent={ArrowBackIosNewIcon}
                          value={totalAttendance}
                          onChange={(e) => setTotalAttendance(e.target.value)}
                          renderValue={(selected) => {
                            if (selected === "placeholder") {
                              return (
                                <Typography
                                  sx={{ color: paletteColorConstant.gray2 }}
                                >
                                  Pilih Estimasi Jumlah Peseta
                                </Typography>
                              );
                            } else {
                              return selected;
                            }
                          }}
                          MenuProps={{ PaperProps: { style: menuSelectStyle } }}
                        >
                          <MenuItem disabled value={"placeholder"}>
                            Pilih Estimasi Jumlah Peseta
                          </MenuItem>
                          {menuSelect.totalAttendanceSelect.map((val) => (
                            <MenuItem key={val.value} value={val.value}>
                              {val.menu}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <div className="deskripsi_singkat_field">
                        <Typography sx={{ ...fontPreset.body14Reg, marginBottom: "4px" }}>
                          Deskripsi Singkat Eventmu (Minimal 20 Karakter)
                        </Typography>
                        <CustomTextField
                          placeholder="Contoh: Perusahaan kami ingin membuat event Townhall meeting untuk 1000 orang secara online dengan 10 orang di studio. Perkiraan sementara kami, kami akan membutuhkan greenscreen, lighting, dan juga beberapa kru untuk membantu kami."
                          variant="outlined"
                          size="small"
                          multiline
                          rows={5}
                          value={eventDesc}
                          onChange={(e) => setEventDesc(e.target.value)}
                          inputProps={{ maxLength: CHARACTER_LIMIT }}
                          fullWidth
                        />
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            // columnGap: "15%",
                            justifyContent: "flex-end",
                            marginTop: "4px",
                          }}
                        >
                          {display === "error4" ? (
                            <Typography
                              sx={{ flex: "2", color: "red", ...fontPreset.body14Lig, display: display }}
                            >
                              Mohon isi deskripsi singkat dengan minimal 20
                              karakter
                            </Typography>
                          ) : (
                            ""
                          )}
                          <Box>
                            <Typography
                              variant="caption"
                              fontSize={10}
                              fontWeight={"bold"}
                            >
                              {`${eventDesc.length}`}
                            </Typography>
                            <Typography
                              variant="caption"
                              fontSize={10}
                              color={"text.secondary"}
                            >
                              {`/${CHARACTER_LIMIT}`}
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    </div>

                    <div className="action__button"
                      style={downMediumScreen ? style.buttonSectionMobile : style.buttonSection}
                    >
                      <Button
                        sx={{ marginTop: "-10px" }}
                        onClick={() => { jumpToStep(step - 1) }}
                        size="large"
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ marginTop: "-10px" }}
                        onClick={() => { nextStep() }}
                        disabled={checkDisabledButtonTwo()}
                        size="large"
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}

                {step === 3 && (
                  <>
                    <div className="step__three__form"
                      style={style.formSection}
                    >
                      <div className="tanggal_event_field">
                        <Typography 
                          variant="body1"
                          sx={{ ...fontPreset.body14Reg, marginBottom: "4px" }}
                        >
                          Tanggal Event Kamu
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <ThemeProvider>
                            <DesktopDatePicker
                              inputFormat="dd/MM/yyyy"
                              value={eventDate}
                              onChange={(newValue) => setEventDate(newValue)}
                              PaperProps={{ 
                                sx: { 
                                  borderRadius: "6px",
                                  '& .MuiPickersDay-root': {
                                    '&.Mui-selected': { color: "white" }
                                  },
                                } 
                              }}
                              renderInput={(params) => ( <CustomTimePickerField {...params} fullWidth /> )}
                              disablePast
                            />
                          </ThemeProvider>
                        </LocalizationProvider>
                      </div>

                      <div className="lokasi_event_field">
                        <Typography 
                          sx={{ ...fontPreset.body14Reg, marginBottom: "4px" }}
                        >
                          Lokasi Event Kamu
                        </Typography>
                        <Autocomplete
                          autoComplete
                          freeSolo
                          options={
                            cityList !== null ? 
                              cityList?.data?.length !== 0 ? cityList.data.map((option) => option.city) : [] 
                              : []
                          }
                          value={cityName}
                          noOptionsText={'List kota tidak tersedia'}
                          onChange={(event, newValue) => { setCityName(newValue) }}
                          disabled={cityState.loading || cityList === null}
                          renderInput={(params) => (
                            <CustomAutoCompleteTextField
                              {...params}
                              placeholder={cityState.loading ? "Mengambil Data" : "Masukkan Nama Kota"}
                            />
                          )}
                          autoSelect
                        />
                      </div>

                      <div className="estimasi_budget_field">
                        <PriceTextField
                          label={"Estimasi Budget Kamu (dalam IDR)"}
                          placeholder="100.000.000"
                          value={budgetEstimation}
                          onChange={setBudgetEstimation}
                        />
                      </div>
                    </div>

                    <div className="action__button"
                      style={downMediumScreen ? style.buttonSectionMobile : style.buttonSection}
                    >
                      <Button
                        sx={{ marginTop: "40px" }}
                        onClick={() => { jumpToStep(step - 1) }}
                        size="large"
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ marginTop: "40px" }}
                        onClick={() => { nextStep() }}
                        disabled={checkDisabledButtonThree()}
                        size="large"
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="confirmation__content"
              style={{ 
                ...style.confirmationSection,
                display: step !== 4 ? "none" : "flex",
                padding: downMediumScreen ? "0 25px 45px 25px" : "0 60px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: downMediumScreen ? "column" : "row",
                  columnGap: "60px",
                  rowGap: downMediumScreen ? "32px" : "",
                }}
              >
                <div className="confirmation__form"
                  style={{
                    ...style.formSection,
                    width: downMediumScreen ? "100%" : "50%",
                    rowGap: downMediumScreen ? "32px" : "36px",
                  }}
                >
                  {" "}
                  <div style={{ display: "flex", columnGap: "12px" }}>
                    <div className="nama__confirm__section"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                      >
                        Nama Kamu
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobile : {}}
                        flexWrap={"wrap"}
                      >
                        {name}
                      </Typography>
                    </div>

                    <div className="email__confirm__section"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                      >
                        Email Kamu
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobile : {}}
                        flexWrap={"wrap"}
                      >
                        {contact}
                      </Typography>
                    </div>
                  </div>

                  <div style={{ display: "flex", columnGap: "12px" }}>
                    <div className="company__type__section"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                      >
                        Jenis Instansi
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobile : {}}
                        flexWrap={"wrap"}
                      >
                        {companyType}
                      </Typography>
                    </div>

                    <div className="company__name__section"
                      style={{ width: "100%", display: companyName === "" && "none" }}
                    >
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                      >
                        Nama Instansi
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobile : {}}
                        flexWrap={"wrap"}
                      >
                        {companyName ?? ""}
                      </Typography>
                    </div>
                  </div>

                  <div style={{ display: "flex", columnGap: "12px" }}>
                    <div className="event__type__section"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        variant="body2"
                        sx={
                          downMediumScreen
                            ? style.step4FontMobileTitle
                            : style.step4FontWeb
                        }
                      >
                        Jenis Event
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobile : {}}
                        flexWrap={"wrap"}
                      >
                        {eventType}
                      </Typography>
                    </div>

                    <div className="total__attendance__section"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        variant="body2"
                        sx={
                          downMediumScreen
                            ? style.step4FontMobileTitle
                            : style.step4FontWeb
                        }
                      >
                        Total Jumlah Peserta
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobile : {}}
                        flexWrap={"wrap"}
                      >
                        {totalAttendance}
                      </Typography>
                    </div>
                  </div>
                </div>

                <div className="confirmation__form"
                  style={{
                    ...style.formSection,
                    width: downMediumScreen ? "100%" : "50%",
                    rowGap: downMediumScreen ? "32px" : "36px",
                  }}
                >
                  <div style={{ display: "flex", columnGap: "12px" }}>
                    <div className="event__date__confirm__section"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                      >
                        Tanggal Event Kamu
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobile : {}}
                        flexWrap={"wrap"}
                      >
                        {moment(eventDate).format("DD [/] MM [/] YYYY")}
                      </Typography>
                    </div>

                    <div className="location__confirm__section"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                      >
                        Lokasi Event Kamu
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobile : {}}
                        flexWrap={"wrap"}
                      >
                        {cityName}
                      </Typography>
                    </div>
                  </div>

                  <div style={{ display: "flex", columnGap: "12px" }}>
                    <div className="budget__confirm__section"
                      style={{ width: "50%" }}
                    >
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                      >
                        Estimasi Budget Kamu (dalam IDR)
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobile : {}}
                        flexWrap={"wrap"}
                      >
                        IDR {priceFormat(budgetEstimation)}
                      </Typography>
                    </div>
                  </div>

                  <div style={{ display: "flex", columnGap: "12px" }}>
                    <div className="description__confirm__section"
                      style={{ 
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={
                          downMediumScreen
                            ? style.step4FontMobileTitle
                            : style.step4FontWeb
                        }
                      >
                        Deskripsi Event Singkat Kamu
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={downMediumScreen ? style.step4FontMobile : {}}
                        flexWrap={"wrap"}
                      >
                        {eventDesc}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <Box className="action__button"
                style={{ paddingBottom: downMediumScreen ? "20px" : 0 }}
                sx={downMediumScreen ? style.buttonSectionMobile : style.buttonSection}
              >
                <Button
                  sx={{ marginTop: "38px" }}
                  onClick={() => { jumpToStep(1) }}
                  size="large"
                >
                  Edit
                </Button>
                
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  sx={{ marginTop: "38px" }}
                  onClick={submitConsultation}
                  size="large"
                >
                  Submit
                </LoadingButton>
              </Box>

              <Box className="error__submit__snackbar">
                <Snackbar
                  open={errorSubmit.error}
                  autoHideDuration={2000}
                  onClose={handleCloseErrorSubmitSnackbar}
                  message={errorSubmit.helperText}
                />
              </Box>
            </div>
          </>
        )}

        {/* SUCCESS STATE */}
        {step > 4 && (
          <>
            <Box className="success__section" sx={{ height: downMediumScreen ? "100%" : "640px" }}>
              <Box className="success-dialog-img-wrapper">
                
                <LazyLoadImage className="success-dialog-image"
                  src={"/images/home/consultation_dialog/success_step.png"}
                  alt={"divine-pro-team"}
                  style={{ objectPosition: downMediumScreen && "60% 50%" }}
                />

                <div className="success-dialog-filter" />

                <div className="success-dialog-content">
                  <IconButton
                    aria-label="close"
                    size="large"
                    onClick={() => {
                      handleClose();
                      dispatch(resetConsultation());
                    }}
                    sx={{ position: "absolute", top: downMediumScreen ? "20px" : "40px", right: downMediumScreen ? "20px" : "60px" }}
                  >
                    <CloseIcon sx={{ color: paletteColorConstant.white1 }} />
                  </IconButton>

                  <Box
                    style={{ position: "absolute", bottom: "60px", right: downMediumScreen ? "20px" : "60px" }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleFinishButton}
                      size="large"
                    >
                      Done
                    </Button>
                  </Box>
                </div>
              </Box>
            </Box>

            <Box className="success__text">
              <Typography
                component={"h3"}
                sx={{
                  fontSize: downMediumScreen ? fontPreset.titleM : fontPreset.titleXl,
                  color: paletteColorConstant.white1,
                  lineHeight: downMediumScreen ? "28px" : "49.22px",
                  position: "absolute",
                  left: downMediumScreen ? "calc(50% - 192px)" : "calc(50% - 336px)",
                  // top: downMediumScreen ? "60%" : "80px",
                  bottom: downMediumScreen ? "180px" : "148px"
                }}
                align={"center"}
              >
                Terima kasih, <br />
                kami akan segera menghubungimu
              </Typography>
            </Box>
          </>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default ConsultationDialog;
