export const caseStudy = [
    {
      title: "Jakarta Menyapa!",
      desc: 'Acara "Jakarta Menyapa" yang dihadiri oleh Anies Baswedan & Ibu-ibu PKK se-DKI Jakarta berlangsung meriah.',
      photo: "/images/home/section_4/event_img_small.png",
      details: {
        about: `Pemda DKI Jakarta menyelenggarakan acara yang bertajuk “Jakarta Menyapa” pada 16 Juli 2022 yang lalu. Bertempat di stadion kebanggaan Jakarta yakni Jakarta International Stadium, acara ini berlangsung meriah dan aman dengan tetap menerapkan protokol kesehatan ketat. \n\n Dalam acara ini, kami mensupport beragam peralatan multimedia yang ber-spec high end, mulai dari kamera camcorder, jimmyjib, drone, hingga live streaming system.`,
        provides: [
            'Multicamsystem 3 Camera',
            'Kamera Sony PXW Z150',
            'Kamera Sony PMW 300 + Tele Lens',
            'Sony Mirrorless a7iii + DJI Ronin-S',
            'Jimmy Jib + Kamera Sony PXW Z150',
            'SDI/ HDMI Wireless',
            'DJI Phantom 4 Pro + Pilot Drone',
            'Photo & Video Documentation',
            'Live Streaming System (PC Monster)'
        ],
        logo: null,
        quotes: null,
        photoList: [ 
          "/images/home/section_4/event_img_small.png",
          "/images/case_study/case1_2.png",
          "/images/case_study/case1_3.png",
          "/images/case_study/case1_4.png",
        ]
      }
    },
    {
      title: "Festival Merdeka Kemen PUPR 2022",
      desc: "Menyambut HUT ke-77 RI, KemenPUPR menyelenggarakan beragam lomba dan festival kemerdekaan di kantor pusat.",
      photo: "/images/home/section_4/event_img_small_1.png",
      details: {
        about: `Dalam rangka memperingati HUT ke-77 RI, Kementerian Pekerjaan Umum dan Perumahan Rakyat (KemenPUPR) mengadakan berbagai lomba yang diikuti oleh seluruh unit organisasi di kantor PUPR Pusat.
        \n\nTak hanya lomba, berbagai hiburan juga dipersiapkan dalam Festival Kemerdekaan ini. Mulai dari penampilan ‘The Living Legend’ Iwan Fals, hingga beragam jajanan tradisional yang berasal dari seluruh wilayah Indonesia, disajikan guna menambah kesan peringatan Kemerdekaan Indonesia ini.
        \n\nPada festival ini, kami dipercaya untuk menjadi tim Show Management-nya. Sehingga, kami harus memastikan bahwa seluruh hal yang diperlukan untuk ‘show’ telah tersedia dengan baik. Karena kami percaya, setiap event yang kami jalankan harus berkesan atau ‘memorable’ bagi setiap peserta yang hadir.
        `,
        provides: [
            "Show Management's Team (Show Director, Stage Manager, Stage Crew, LO MC, VJ Resolume)",
            'Crew Lomba (Tarik Tambang, Balap Karung, Hortus Fotografi)',
            'Sistem Polling',
            'Laptop dan Operator (Pinner/ PPT/ Host)',
            'Photo & Video Documentation',
        ],
        logo: null,
        quotes: "Timnya asik banget! Makasi kerja samanya, ya. Bulan depan ada event lagi nih, jangan lupa ya!",
        photoList: [
          "/images/home/section_4/event_img_small_1.png",
          "/images/case_study/case2_2.png",
          "/images/case_study/case2_3.png",
          "/images/case_study/case2_4.png",
        ]
      },
    },
    {
      title: "IWTCF 2022 by Kemenparekraf",
      desc: "Rangkaian acara G20 yakni International Wellness Tourism Conference & Festival (IWTCF) di gelar di Solo.",
      photo: "/images/home/section_4/event_img_small_2.png",
      details: {
        about: `
            Kementerian Pariwisata dan Ekonomi Kreatif/ Badan Pariwisata dan Ekonomi Kreatif (Kemenparekraf/ Baparekraf) menggelar salah satu rangkaian acara  G20 yakni “International Wellness Tourism Conference & Festival (IWTCF),” pada 5 - 7 Agustus 2022 yang lalu.\n\n
            Beragam tokoh pemerintahan turut hadir dalam acara ini. Di antaranya yakni Walikota Surakarta Gibran Rakabuming dan Menteri Kemenperakraf Sandiaga Salahuddin Uno.\n\n
            Divine Production mendapat kesempatan untuk menghandle hampir keseluruhan alat multimedia dalam event ini. Mulai dari panggung utama, properti, LED, multicamsystem hingga show management.
        `,
        provides: [
            "Show Management's Team (Show Director, Stage Manager, Stage Crew, VJ Resolume)",
            'Main Stage Finishing Melamine',
            'LED Curved',
            'Kiosk',
            'Booth Translator',
            'Stage Lighting',
            "Sound System 7000 Watt",
            "Multicam System 2 Cam ",
            "Jimmy Jib + Kamera Sony PXW Z150",
            "Laptop Operator (Pinner/ PPT/ Host)",
            "TV Monitor 43",
            "TV Monitor 50",
            "Orbit Internet Portable Up to 50 Mbps",
            "Multimedia Content"
        ],
        logo: null,
        quotes: null,
        photoList: [
          "/images/home/section_4/event_img_small_2.png",
          "/images/case_study/case3_2.png",
          "/images/case_study/case3_3.png",
          "/images/case_study/case3_4.png",
        ]
      }
    },
    {
      title: "Konser KV Fest 2022: Pecah!",
      desc: "Artis papan atas dunia seperti B.I hingga Jessi mengguncang Tennis Indoor Senayan pada acara KV Fest 2022.",
      photo: "/images/home/section_4/event_img_small_3.png",
      details: {
        about: `
            Konser KV Fest sukses digelar di Tennis Indoor Senayan, Jakarta, pada 28 Agustus 2022 silam.\n\n
            Acara yang dipromotori oleh KULTVIZION ini, menyajikan line-up beberapa artis dunia maupun lokal yang sedang naik daun. Mereka adalah Afgan, B.I, Jessi, Ramengvrl, hingga Secret Number.\n\n
            divinepro.id yang memang sejak awal berdiri kerap dipercaya untuk memegang event konser, kali ini diberi mandat untuk menghandle multicamsystemnya. Alhasil, acara dapat berjalan dengan lancar berkat tampilan gambar yang sangat ciamik dari para man powernya.
        `,
        provides: [
            "Multicamsystem 3 Camera",
            'Kamera Sony PXW Z150',
            'Kamera Sony PMW 300 + Tele Lens',
            'PC Monster for Resolume',
        ],
        logo: null,
        quotes: null,
        photoList: [
          "/images/home/section_4/event_img_small_3.png",
          "/images/case_study/case4_2.png",
          "/images/case_study/case4_3.png",
          "/images/case_study/case4_4.png",
        ]
      },
    },
];

export const caseStudy2 = [
  {
    title: "Interaktif Custom Booth Ala Partai NasDem",
    desc: "Guna memuaskan peserta yang hadir dalam Rakornas 2022, Partai NasDem sewa interaktif custom booth.",
    photo: "/images/case_study/case5_1.png",
    details: {
      about: `
        Partai NasDem menyelenggarakan Rapat Koordinasi Nasional (Rakornas) di Jakarta Convention Center (JCC) pada 15 Juni 2022 yang lalu.\n\n
        Dalam acara Rakornas ini, kami diminta untuk membantu memuaskan para tamu yang hadir dengan menyediakan booth 4 sisi, di mana setiap sisinya memiliki tampilan dan fasilitas yang berbeda.\n\n
        Tentunya divinepro.id bisa diandalkan dalam acara offline, mengingat pandemi yang kian surut ini.
      `,
      provides: [
        "LED 2x1 Meter",
        "TV Touch Screen 32",
        "TV Monitor 32",
        "TV Monitor 50",
        "Camera Insta 360",
        "Nintendo Switch",
        "Custom Backdrop and Mealnie Flooring",
        "Neon Box",
      ],
      logo: null,
      quotes: null,
      photoList: [
        "/images/case_study/case5_1.png",
        "/images/case_study/case5_2.png",
        "/images/case_study/case5_3.png",
        "/images/case_study/case5_4.png",
      ]
    },
  },
  {
    title: "Grab it Fast 2022: Pelepas Dahaga Pemuda Bogor",
    desc: "The Changcuters dan Vierratale sukses menggetarkan GOR Pajajaran Bogor dalam rangkaian acara Grab it Fast.",
    photo: "/images/case_study/case6_1.png",
    details: {
      about: `
        The Changcuters sukses menggetarkan GOR Pajajaran Bogor, kala tampil sebagai penutup di acara Grab It Fast, 29 Mei 2022 yang lalu.\n\n
        Grup band asal Bandung ini, seakan menjadi pelepas dahaga masyarakat Bogor- yang sudah haus akan suasana konser offline akibat pandemi COVID-19.\n\n
        Tak hanya Changcuters, Pelantun lagu "Perih", Vierratale juga sukses menghibur muda-mudi Bogor, baik yang hadir secara langsung maupun yang menonton via Zoom.\n\n
        Berkonsepkan "Hybrid Event", divinepro.id dipercaya untuk menghandle acara ini, mulai dari multicamsystem yang outputnya disiarkan ke LED di panggung- hingga tampilan audio visual yang dinikmati oleh para penonton via zoom.
      `,
      provides: [
        "Jimmyjib + Operator",
        "Multicamsystem 3 Camera",
        "Kamera PXW Z150",
        "SDI/HDMI Wireless",
        "Live Streaming System"
      ],
      logo: null,
      quotes: null,
      photoList: [
        "/images/case_study/case6_1.png",
        "/images/case_study/case6_2.png",
        "/images/case_study/case6_3.png",
        "/images/case_study/case6_4.png",
      ]
    },
  },
  {
    title: "Closing TSAC Legacy by BEM UNTAR 2022",
    desc: "Acara Closing TSAC Legacy by BEM UNTAR 2022 yang mengambil konsep full online berlangsung lancar.",
    photo: "/images/case_study/case7_1.png",
    details: {
      about: `
        BEM UNTAR kembali mempercayakan Divine Production dalam penutupan acara TSAC Legacy, yang dilakukan secara online di Pixel Studio by divinepro.id.\n\n
        TSAC Merupakan acara kompetisi yang diadakan BEM UNTAR secara terbuka untuk seluruh mahasiswa UNTAR.\n\n
        Sederetan kompetisi mulai dari dance hingga e-sport dicakup oleh TSAC. Pada acara penutupan ini, pengumuman pemenang baru akan diumumkan ke publik, dengan adanya penilaian dari juri yang berkompeten pada masing-masing kompetisi.
      `,
      provides: [
        "Studio Pixel duration 6 hours",
        "PC Monster",
        "Zoom Meeting HD 300 Participant",
        "Laptop Operator",
        "TV 50"
      ],
      logo: null,
      quotes: null,
      photoList: [
        "/images/case_study/case7_1.png",
        "/images/case_study/case7_2.png",
        "/images/case_study/case7_3.png",
        "/images/case_study/case7_4.png",
      ]
    },
  },
  {
    title: "Selamat Menempuh Hidup Baru, Bintang dan Alca!",
    desc: "Komika Bintang Emon resmi mempersunting pujaan hatinya, Alca Octaviani pada 24 Juli 2022 yang lalu.",
    photo: "/images/case_study/case8_1.png",
    details: {
      about: `
        Siapa yang tak kenal dengan Bintang Emon? Komika yang belakangan ini kerap menyampaikan materi keresahannya tentang politik di Indonesia, pada 24 Juli 2022 yang lalu resmi mempersunting pujaan hatinya, Alca Octaviani.\n\n
        Disiarkan secara langsung melalui kanal YouTube Bintang Emon, pernikahan keduanya digelar dengan mengusung adat Betawi di The Tribrata Darmawangsa.\n\n
        Pada kesempatan ini, kami dipercaya untuk menyediakan LED untuk menayangkan prosesi pernikahannya saat offline. Dengan mendapat relay gambar dari multicamsystem, dan aset-aset multimedia dari resolume, LED yang kami usung menambah kesan megah dalam acara pernikahan komika asal kelahiran Kalideres ini.\n\n
        Sekali lagi, selamat menempuh hidup baru untuk Bintang Emon dan Alca Octaviani!      
      `,
      provides: [
        "LED P3.9 5x3,5 meter + Level 1 Meter",
      ],
      logo: null,
      quotes: null,
      photoList: [
        "/images/case_study/case8_1.png",
        "/images/case_study/case8_2.png",
        "/images/case_study/case8_3.png",
        "/images/case_study/case8_4.png",
      ]
    },
  },
  {
    title: `Jakarta Jadi Tuan Rumah "URBAN 20 MAYORS SUMMIT"`,
    desc: "DKI jadi tuan rumah U20 Mayors Summit, yang diselenggarakan di Hotel Fairmont pada 30-31 Agustus 2022.",
    photo: "/images/case_study/case9_1.png",
    details: {
      about: `
        Pada 30-31 Agustus 2022 lalu, Jakarta menjadi tuan rumah Urban 20 (U20) Mayors Summit yang diselenggarakan di Hotel Fairmont, Jakarta Pusat. U20 Mayors Summit menjadi puncak dari forum kepemimpinan Jakarta pada U20 tahun ini.\n\n
        Salah satu topik yang dibahas yaitu pemulihan krisis akibat pandemi COVID-19. Fokusnya yakni pada isu dan tantangan yang dihadapi oleh kota-kota akibat pandemi COVID-19, dengan tema yang diangkat adalah “Kota Pascapandemi”.\n\n
        Banyak orang-orang penting di Indonesia, yang hadir dalam acara ini. Di antaranya yakni Anies Baswedan, Ridwan Kamil, hingga Kepala Daerah dan Gubernur dari seluruh anggota G20.\n\n
        Karena berkonsep Hybrid event, kami diminta untuk menyiapkan 4 sistem live streaming dan resolume di masing-masing ruangan, dengan kebutuhan yang berbeda-beda.
      `,
      provides: [
        "Live Streaming System 4 Room",
        "Resolume System 4 Room",
        "Multicamsystem 4 Room",
        "Jimmyjib",
        "Camera PMW 300"
      ],
      logo: null,
      quotes: null,
      photoList: [
        "/images/case_study/case9_1.png",
        "/images/case_study/case9_2.png",
        "/images/case_study/case9_3.png",
        "/images/case_study/case9_4.png",
      ]
    },
  },
  {
    title: "Ajang Balap di Toyota Gazoo Racing GT Cup 2022",
    desc: "Ajang e-Motorsport Toyota Gazoo Racing GT Cup 2022 tarik banyak potensi anak muda di Indonesia.",
    photo: "/images/case_study/case10_1.png",
    details: {
      about: `
        Final kompetisi balap virtual atau e-Motorsport Toyota Gazoo Racing GT Cup 2022 Indonesia di gelar offline di Mall Kota Kasablanka pada 4 September 2022 yang lalu.\n\n
        Pada ajang ini, ada 2 kelas yang dilombakan yakni Rising Star Class dan Rookie Class. Di mana pada masing-masing kelas akan diambil 3 juara.\n\n
        Sebelum masuk ke babak final, para pembalap muda ini sudah melaksanakan beberapa pertandingan sebelumnya secara online.\n\n
        Dalam event ini, kami dipercaya untuk menjadi tim Show Managementnya. Berlangsung dari pagi hingga sore hari, acara ini dapat berlangsung lancar tanpa hambatan apapun.      
      `,
      provides: [
        "Show Director",
        "Stage Manager",
        "Stage Crew",
        "Music Director",
        "LO MC",
        "Operator Live Streaming (Visual Jockey)",
        "Operator Pinner",
      ],
      logo: null,
      quotes: null,
      photoList: [
        "/images/case_study/case10_1.png",
        "/images/case_study/case10_2.png",
        "/images/case_study/case10_3.png",
        "/images/case_study/case10_4.png",
      ]
    },
  },
]

export const cityDummy = [
  {
    name: "Bekasi, Jawa Barat",
    island: "Jawa",
  },
  {
    name: "Kebayoran Lama, Jakarta",
    island: "Jawa",
  },
  {
    name: "Cikarang, Jawa Barat",
    island: "Jawa",
  },
  {
    name: "Semarang, Jawa Tengah",
    island: "Jawa",
  },
  {
    name: "Bandung, Jawa Barat",
    island: "Jawa",
  },
];

export const articleDetailDummy = {
  title: "5 Tips Jitu Buat Promotor Bikin Konser yang 'Keren'",
  image: "/images/AboutUs/section1.png",
  cover: "/images/Articles/details/article_image.png",
  content: `
    Siapa yang nggak kangen nonton konser secara offline? Tenang aja, kamu nggak sendiri kok!\n\n
    Bagaimana tidak? Hampir tiga tahun setelah munculnya virus Corona, baru belakangan ini industri hiburan Indonesia perlahan-lahan mulai bangkit. Event konser mulai ramai, diiringi dengan jumlah penontonnya yang membludak.\n\n
    Hausnya dahaga penonton terkait event konser ini, perlu menjadi perhatian serius bagi para penyelenggara/ promotor konser. Berbagai perlengkapan yang mendukung, serta persiapan tim yang matang akan membuat konser terselenggara apik. Harapannya, penonton akan menantikan event serupa di kemudian hari.\n\n
    Agar dapat menyelenggarakan konser dengan baik, berikut divine sajikan 5 tips biar konser kamu berkesan di hati penonton.\n\n
  `,
  contentPoint: [
    { title: "1. Hire Tim Show Management",
      desc: "Banyak yang belum tahu seberapa pentingnya show management dalam kelancaran suatu event. Jika diibaratkan anatomi tubuh, show management bisa dianggap seperti tulang punggung dalam sebuah event. Mengapa? Karena dalam suatu event, mereka membutuhkan seorang expertis yang bisa menganalisa dan memberikan perencanaan yang matang. Perlu ada seseorang yang menjaga / mengarahkan sequence by sequence mood satu konsep, agar acara bisa berjalan dengan mulus sesuai dengan keinginan client. Ada banyak divisi dalam tim show management jika acara kamu mau berjalan dengan lancar. Di antaranya yakni: Show Director, Stage Manager, Stage Crew, Music Director, Lighting Designer, LO MC dan LO setiap penampilan, hingga Sound Engineer." 
    },
    { title: "2. Soundsystem yang Berkualitas",
      desc: "Perlu menjadi kesepakatan bersama bahwa soundsystem yang baik bukan hanya soal pengeras suara, namun lebih dari itu. Soundsystem yang baik perlu memperhatikan banyak aspek. Mulai sound monitor di panggung dan sound PA di floor; peralatan audio untuk setiap alat musik penampil; kebutuhan mic penampil dan pembawa acara; memastikan tidak ada suara yang berbalik (feedback) dari setiap alat-alatnya; hingga memastikan adanya channel berlebih untuk input dan output dari tim-tim lain seperti dokumentasi, multicamsystem, dll. Karenanya, dibutuhkan tim yang handal dan alat-alat yang termutakhir, agar suara yang dihasilkan dapat terdengar ‘sopan’ di telinga penonton." 
    },
    { title: "3. Siapkan Kebutuhan Multimedia Agar Mata Penonton Terhibur",
      desc: "Banyak hal yang bisa kamu tambahkan agar konser kamu membuat penonton terkesan, salah satunya yakni dengan adanya penambahan LED. Dengan adanya LED, kamu bisa menampilkan berbagai aset-aset. Mulai dari menampilkan artis yang sedang on stage, hingga beragam aset video bergerak, yang dapat membuat kesan “megah” pada konser yang kamu buat.\n\nDan jangan lupa, menampilkan artis yang disukai oleh penonton di LED dengan ukuran besar, bisa buat mereka berteriak histeris, sehingga konser kamu akan selalu diingat oleh penonton.\n\n"
    },
    { title: "4. Lighting Stage yang Memukau",
      desc: "Banyak yang belum tahu seberapa pentingnya show management dalam kelancaran suatu event. Jika diibaratkan anatomi tubuh, show management bisa dianggap seperti tulang punggung dalam sebuah event. Mengapa? Karena dalam suatu event, mereka membutuhkan seorang expertis yang bisa menganalisa dan memberikan perencanaan yang matang. Perlu ada seseorang yang menjaga / mengarahkan sequence by sequence mood satu konsep, agar acara bisa berjalan dengan mulus sesuai dengan keinginan client. Ada banyak divisi dalam tim show management jika acara kamu mau berjalan dengan lancar. Di antaranya yakni: Show Director, Stage Manager, Stage Crew, Music Director, Lighting Designer, LO MC dan LO setiap penampilan, hingga Sound Engineer." 
    },
    { title: "5. After Movie",
      desc: "Dokumentasi atau yang kini biasa disebut After Movie menjadi salah satu bagian yang paling penting dalam penyelenggaraan suatu konser. Karena, video after movie ini diperlukan untuk penyelenggaraan event selanjutnya, baik bagi pihak penyelenggara maupun penonton.\n\nBagi penyelenggara, after movie bisa dijadikan sebagai bahan evaluasi ke depannya. Misalnya, saat menjadi penyelenggara konser ternyata terjadi kericuhan. Jika ada dokumentasi, maka kita bisa mengamati bagian mana yang kurang aman, kurang pencahayaan dan sebagainya.\n\nSelain itu, after movie juga bisa dijadikan sebagai bahan promosi bagi penyelenggara/ promotor, saat ingin menyelenggarakan event yang serupa dikemudian hari. Ibaratnya, video ini bisa dijadikan sebagai portolio oleh pihak promotor.\n\nSedangkan bagi penonton, video after movie bisa membuat penonton yang hadir saat konser, merasakan kembali atmosfer dari acara tersebut. Tentunya, membuat mereka jadi menantikan kembali konser yang kamu selenggarakan selanjutnya.\n\n"
    },
  ],
  createdAt: "2022-11-29T14:36:23.052Z"
}