// MATERIAL UI
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material'
// CONSTANTS
import { fontPreset } from '../constant/fontConstant'

const NotFound = () => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <div style={{
        height: "calc(100vh - 92px)",
        background: "url('/images/NotFound/hero_banner.png') no-repeat",
        backgroundSize: "cover",
        backdropFilter: "blur(2px)",
      }}
    >
      <div style={{ 
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          rowGap: downMediumScreen ? "24px" : "32px",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 32px"
        }}
      >
        <Typography
          component={"h1"}
          sx={{ color: "white", lineHeight: downMediumScreen ? "102px" : "118px" }}
          style={{ fontSize: downMediumScreen ? "60px" : "100px", fontWeight: "bold" }}
          align="center"
        >
          Oops!
        </Typography>
        
        <div style={{ 
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: "20px",
            // width: "480px"
          }}
        >
          <Typography
            component={"h2"}
            sx={{ color: "white", lineHeight: "40px" }}
            style={{ fontSize: downMediumScreen ? "20px" : "32px", fontWeight: "500" }}
            align="center"
          >
            404 - Halaman Tidak Ditemukan
          </Typography>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              component={"body1"}
              sx={{ color: "white", lineHeight: "23.44px" }}
              style={downMediumScreen ? fontPreset.body16Reg : fontPreset.body20Reg}
              align="center"
            >
              Halaman yang kamu ketik atau URL yang kamu klik untuk sementara tidak dapat diakses.
            </Typography>
          
            <Typography
              component={"body1"}
              sx={{ color: "white", lineHeight: "23.44px" }}
              style={downMediumScreen ? fontPreset.body16Reg : fontPreset.body20Reg}
              align="center"
            >
              Silakan klik tombol di bawah ini untuk masuk ke website Divine Pro.
            </Typography>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="notFoundPage"
            sx={{ textTransform: "capitalize", backgroundColor: "white", color: "" }}
          >
            Ke Beranda
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NotFound