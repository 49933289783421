import { Box, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

const ArticleLoading = () => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Box>
        <Skeleton
            animation="wave"
            variant="rectangular" 
            width={"100%"}
            height={downMediumScreen ? 136 : 320}
            sx={{ borderRadius: "20px", padding: "0", margin: "0" }}
        />
    </Box>
  )
}

export default ArticleLoading