import { paletteColorConstant } from "./colorConstant";

export const fontStyleConstant = {
  giantHeader: {
    fontSize: "80px",
    fontWeight: "500",
    lineHeight: "117.19px",
  },
  header1: {
    fontSize: "32px",
    fontWeight: "600",
    color: paletteColorConstant.black1,
  },
  header1Medium: {
    fontSize: "32px",
    fontWeight: "500",
  },
  header2: {
    fontSize: "24px",
    fontWeight: "500",
  },
  header3: {
    fontSize: "42px",
    fontWeight: "600",
  },
  header4: {
    fontSize: "20px",
    fontWeight: "600",
  },
  header5: {
    fontSize: "28px",
    fontWeight: "600",
    color: "#2C2C2C",
  },
  body: {
    fontSize: "16px",
  },
  body1: {
    fontSize: "14px",
  },
  body2: {
    fontSize: "12px",
  },
  body3: {
    fontSize: "20px",
  },
  body3SemiBold: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#2C2C2C",
  },
  body4: {
    fontSize: "20px",
  },
  body5: {
    fontSize: "24px",
  },
};

export const fontPreset = {
  titleXXL: { fontSize: "80px", fontWeight: "500", lineHeight: "117.19px" },
  titleXM: { fontSize: "60px", fontWeight: "500" },
  titleXl: { fontSize: "42px", fontWeight: "500" },
  titleL: { fontSize: "32px", fontWeight: "500" },
  titleM: { fontSize: "24px", fontWeight: "500" },
  titleS: { fontSize: "20px", fontWeight: "500" },
  body40Bold: { fontSize: "40px", fontWeight: "600" },
  body40Med: { fontSize: "40px", fontWeight: "500" },
  body40Reg: { fontSize: "40px", fontWeight: "400" },
  body40Lig: { fontSize: "40px", fontWeight: "300" },
  body32Bold: { fontSize: "32px", fontWeight: "600" },
  body32Med: { fontSize: "32px", fontWeight: "500" },
  body32Reg: { fontSize: "32px", fontWeight: "400" },
  body32Lig: { fontSize: "32px", fontWeight: "300" },
  body24Bold: { fontSize: "24px", fontWeight: "600" },
  body24Med: { fontSize: "24px", fontWeight: "500" },
  body24Reg: { fontSize: "24px", fontWeight: "400" },
  body24Lig: { fontSize: "24px", fontWeight: "300" },
  body20Bold: { fontSize: "20px", fontWeight: "600" },
  body20Med: { fontSize: "20px", fontWeight: "500" },
  body20Reg: { fontSize: "20px", fontWeight: "400" },
  body20Lig: { fontSize: "20px", fontWeight: "300" },
  body18Bold: { fontSize: "18px", fontWeight: "600" },
  body18Med: { fontSize: "18px", fontWeight: "500" },
  body18Reg: { fontSize: "18px", fontWeight: "400" },
  body18Lig: { fontSize: "18px", fontWeight: "300" },
  body16Bold: { fontSize: "16px", fontWeight: "600" },
  body16Med: { fontSize: "16px", fontWeight: "500" },
  body16Reg: { fontSize: "16px", fontWeight: "400" },
  body16Lig: { fontSize: "16px", fontWeight: "300" },
  body14Bold: { fontSize: "14px", fontWeight: "600" },
  body14Med: { fontSize: "14px", fontWeight: "500" },
  body14Reg: { fontSize: "14px", fontWeight: "400" },
  body14Lig: { fontSize: "14px", fontWeight: "300" },
  body12Bold: { fontSize: "12px", fontWeight: "600" },
  body12Med: { fontSize: "12px", fontWeight: "500" },
  body12Reg: { fontSize: "12px", fontWeight: "400" },
  body12Lig: { fontSize: "12px", fontWeight: "300" },
  body10Bold: { fontSize: "10px", fontWeight: "600" },
  body10Med: { fontSize: "10px", fontWeight: "500" },
  body10Reg: { fontSize: "10px", fontWeight: "400" },
  body10Lig: { fontSize: "10px", fontWeight: "300" },
  bodyBoldd: { fontSize: "8px", fontWeight: "600" },
  body8Med: { fontSize: "8px", fontWeight: "500" },
  body8Reg: { fontSize: "8px", fontWeight: "400" },
  body8Lig: { fontSize: "8px", fontWeight: "300" },
  buttonLabel: { fontSize: "18px", fontWeight: "500" },
  caption: { fontSize: "10px", fontWeight: "400" },
  titleXlMobile: { fontSize: "24px", fontWeight: "500" },
  titleMMobile: { fontSize: "20px", fontWeight: "500" },
};

export const mobileFontPreset = {
  titleXL: { fontSize: "24px", fontWeight: "500" },
  titleL: { fontSize: "20px", fontWeight: "500" },
  titleM: { fontSize: "18px", fontWeight: "500" },
  body14Lig: { fontSize: "14px", fontWeight: "300" },
  body12Med: { fontSize: "12px", fontWeight: "500" },
  body12Reg: { fontSize: "12px", fontWeight: "400" },
  body12Lig: { fontSize: "12px", fontWeight: "300" },
  body10Lig: { fontSize: "10px", fontWeight: "300" },
  buttonLabel: { fontSize: "12px", fontWeight: "500" },
};
