import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    articles: [],
    loading: false,
    success: false,
    error: false
}

export const getAllArticles = 
    createAsyncThunk(
        'articles/getAllArticles',
        async ({ category = "", sort = [], page = [] }, thunkAPI) => {
            let params = []
            if(category !== "") params.push(category)
            if(sort.length !== 0) params.push(...sort)
            if(page.length !== 0) params.push(...page)

            try {
                // const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/articles/v1/getArticle?${category !== "" ? `category=${category}` : ""}${filter !== null ? `&sort=${filter.sort}&by=${filter.by}` : ''}`)
                const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/articles/v1/getArticle?${params.join("&")}`)
                return res.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error)
            }
        }
    )

const articlesSlice = createSlice({
    name: 'articles',
    initialState,
    extraReducers: {
        [getAllArticles.pending]: (state) => {
            state.loading = true
        },
        [getAllArticles.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.articles = action.payload
        },
        [getAllArticles.rejected]: (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        }
    }
})

// export const { clearArticles } = articlesSlice.actions
export default articlesSlice.reducer