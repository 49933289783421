import React from 'react'
// MATERIAL UI
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
// ICONS
import CommentIcon from "../icons/CommentIcon";
import MailIcon from "../icons/MailIcon";
// CONSTANTS
import { fontPreset } from '../../constant/fontConstant'
import { DIVINE_WA_CHAT_LINK } from '../../constant/utils/contentBody';
// COMPONENTS
import GetInTouchCard from '../Home/GetInTouchCard'

const ArticleListEmpty = () => {
    const theme = useTheme()
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

    const onClickEmailUs = (e) => {
        window.open(`mailto:devinepro@gmail.com?subject=Artikel`);
        e.preventDefault();
    };
      
    const onCLickWhatsapp = (e) => {
        window.open(
          `${DIVINE_WA_CHAT_LINK}&text=Halo Saya Ingin Menanyakan Seputar Artikel di Divine`
        );
        e.preventDefault();
    };

    return (
        <Box className='empty__article'>
            <Typography
                variant="body1"
                sx={downMediumScreen ? fontPreset.body18Reg : fontPreset.body20Reg}
            >
                Artikel saat ini tidak ditemukan. Mohon cek kembali penulisan kamu atau kontak kami.
            </Typography>

            <Box className='contact__menu'
                sx={{ display: "flex", justifyContent: "space-around", marginTop: downMediumScreen ? "40px" : "60px" }}
            >
                <GetInTouchCard
                    avatar={
                        <MailIcon
                            height={downMediumScreen ? "60" : "80"}
                            width={downMediumScreen ? "60" : "80"}
                        />
                    }
                    onClickButton={(e) => onClickEmailUs(e)}
                    buttonTitle="Email Us"
                    description="Apakah kamu memiliki hal yang ingin ditanyakan seputar Divine?"
                />

                <GetInTouchCard
                    avatar={
                        <CommentIcon
                            height={downMediumScreen ? "60" : "80"}
                            width={downMediumScreen ? "60" : "80"}
                        />
                    }
                    onClickButton={(e) => onCLickWhatsapp(e)}
                    buttonTitle="Talk Via Whatsapp"
                    description="Apakah kamu memiliki kebutuhan yang lebih spesifik atau personal?"
                />
            </Box>
        </Box>
    )
}

export default ArticleListEmpty