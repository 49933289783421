import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// MATERIAL UI
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, RadioGroup, Typography, Box, } from "@mui/material";
// MATERIAL ICONS
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// COMPONENTS
import RadioButtonCustom from "../common/RadioButtonCustom";
// CONSTANTS
import { paletteColorConstant } from "../../constant/colorConstant";
import { fontPreset } from "../../constant/fontConstant";
import { boxShadowStyle } from "../../constant/shadowConstant";
import { scrollToContent } from "../../constant/stylePreset";

const style = {
  card: {
    display: "flex",
    flexDirection: "column",
    width: "10vw",
    textAlign: "left",
    padding: "20px 12px",
    position: "relative",
    borderRadius: "12px",
    // backgroundColor: 'pink',
    "&:hover": { ...boxShadowStyle.generalShadow },
  },
};

const singlePackageOption = [
  { val: "all", label: "Semua" },
  { val: "equipment", label: "Peralatan" },
  { val: "manpower", label: "Tambahan Kru" },
];

const priceOptions = [
  { id: "LOW_TO_HIGH", value: "Low To High", filter: "by=price&sort=asc", label: "Rendah ke Tinggi" },
  { id: "HIGH_TO_LOW", value: "High To Low", filter: "by=price&sort=desc", label: "Tinggi ke Rendah" },
]

export const FilterCard = () => {
  const searchURL = useLocation().search;

  const [productFilter, setProductFilter] = useState("");
  const [expanded, setExpanded] = useState("panel1");
  const [radioVal, setRadioVal] = useState("all");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (searchURL.includes("category=")) {
      setTimeout(() => {
        scrollToContent("section__two");
      }, 2);

      searchURL
        .slice(1)
        .split("&")
        .forEach((val) => {
          if (val.includes("category=")) {
            setRadioVal(val.split("=")[1]);
          }
        });
    }
  }, []);

  return (
    <Box className="filter__card" sx={{ ...style.card }}>
      <label style={{ ...fontPreset.body14Reg, marginBottom: "12px" }}>
        Kategori
      </label>

      <FormControl>
        <RadioGroup
          onChange={(e) => { setRadioVal(e.target.value) }}
          value={radioVal}
        >
          <FormControlLabel
            value={"package"}
            control={<RadioButtonCustom size="small" />}
            key={`package`}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                color: radioVal === "package" && paletteColorConstant.primaryBlue150,
                transition: "color 100ms ease-in",
                fontWeight: fontPreset.body14Lig,
              },
              margin: "0"
            }}
            label={"Paket"}
          />
        </RadioGroup>
      </FormControl>

      <Accordion
        // defaultExpanded="true"
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          backgroundColor: "transparent",
          border: "none",
          boxShadow: 0,
          "&:before": { display: "none" },
        }}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ padding: 0 }}
          disableRipple
          disableTouchRipple
        >
          <Typography
            variant="body1"
            sx={{ ...fontPreset.body14Lig }}
          >
            {productFilter === "" ? "Singles" : productFilter}
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ padding: "0px" }}>
          <FormControl>
            <RadioGroup
              value={radioVal}
              onChange={(e) => { setRadioVal(e.target.value) }}
            >
              {singlePackageOption.map((option, index) => (
                <FormControlLabel
                  value={option.val}
                  control={<RadioButtonCustom size="small" />}
                  key={`${option.val}-${index}`}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: radioVal === option.val && paletteColorConstant.primaryBlue150,
                      transition: "color 100ms ease-in",
                      fontWeight: fontPreset.body14Lig,
                    },
                    margin: "0"
                  }}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export const MobileFilterCard = () => {
  const [radioVal, setRadioVal] = useState("all");
  const [expandSingleItems, setExpandSingleItems] = useState(true);

  return (
    <Box className="mobile__filter__item">
      <Typography 
        align="left"
        sx={{ ...fontPreset.body14Reg }}
      >
        Kategori
      </Typography>

      <RadioGroup
        onChange={(e) => { setRadioVal(e.target.value) }}
        value={radioVal}
        sx={{ marginTop: "4px" }}
      >
        <FormControlLabel
          value={"packages"}
          label={"Packages"}
          control={<RadioButtonCustom size="small" />}
          sx={{
            "& .MuiFormControlLabel-label": {
              ...fontPreset.body14Lig,
              wordWrap: "break-word",
              textAlign: "left",
              color: radioVal === "packages" && paletteColorConstant.primaryBlue150,
            },
            margin: 0,
          }}
        />
      </RadioGroup>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}
        onClick={() => { setExpandSingleItems((expanded) => !expanded) }}
      >
        <Typography 
          align="left"
          sx={{ ...fontPreset.body14Reg }}
        >
            Singles
        </Typography>

        <KeyboardArrowUpIcon sx={{ transform: !expandSingleItems && 'rotate(180deg)', transition: "transform .1s ease" }} />
      </Box>

      { expandSingleItems &&
          <RadioGroup
            onChange={(e) => { setRadioVal(e.target.value) }}
            value={radioVal}
            sx={{ marginTop: "4px" }}
          >
            { singlePackageOption.map((option) => (
                <FormControlLabel
                  value={option.val}
                  label={option.label}
                  control={<RadioButtonCustom size="small" />}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...fontPreset.body14Lig,
                      wordWrap: "break-word",
                      textAlign: "left",
                      color: radioVal === option.val && paletteColorConstant.primaryBlue150,
                    },
                    margin: 0,
                  }}
                />
              ))
            }
          </RadioGroup>
      }
    </Box>
  );
}

export const MobileSortCard = ({ type = "" }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [radioVal, setRadioVal] = useState("LOW_TO_HIGH");
  const handleOnchange = (newRadioVal) => {
    setRadioVal(newRadioVal);

    const search = priceOptions.find((option) => option.id === newRadioVal)
    navigate(`/product-services?${search.filter ?? ""}${type !== "" ? `&${type}` : ""}`)
  };

  useEffect(() => {
    const url = location.search.slice(1).split("&")
    
    url.forEach((searchLink) => {
      if(searchLink.includes("sort=asc")) {
        setRadioVal("LOW_TO_HIGH")
      }
      if(searchLink.includes("sort=desc")) {
        setRadioVal("HIGH_TO_LOW")
      }
    })
  }, [location])

  return (
    <Box>
      <Typography 
        align="left"
        sx={{ ...fontPreset.body14Reg }}
      >
        Harga
      </Typography>

      <FormControl>
        <RadioGroup
          value={radioVal}
          onChange={(e) => { handleOnchange(e.target.value) }}
        >
          { priceOptions.map((option, index) => (
              <FormControlLabel
                key={`label-${index + 1}`}
                value={option.id}
                control={<RadioButtonCustom size="small" />}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    ...fontPreset.body14Lig,
                    color: radioVal === option.id && paletteColorConstant.primaryBlue150,
                    transition: "color 100ms ease-in",
                  },
                  margin: "0"
                }}
                label={option.label}
              />
            )) }
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export const SortCard = ({ type = "" }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [radioVal, setRadioVal] = useState("LOW_TO_HIGH");
  const handleOnchange = (newRadioVal) => {
    setRadioVal(newRadioVal);

    const search = priceOptions.find((option) => option.id === newRadioVal)
    navigate(`/product-services?${search.filter ?? ""}${type !== "" ? `&${type}` : ""}`)
  };

  useEffect(() => {
    const url = location.search.slice(1).split("&")
    
    url.forEach((searchLink) => {
      if(searchLink.includes("sort=asc")) {
        setRadioVal("LOW_TO_HIGH")
      }
      if(searchLink.includes("sort=desc")) {
        setRadioVal("HIGH_TO_LOW")
      }
    })
  }, [location])

  return (
    <Box className="sort__card" sx={style.card}>
      <label style={{ ...fontPreset.body14Reg, marginBottom: "12px" }}>
        Harga
      </label>
      <FormControl>
        <RadioGroup
          value={radioVal}
          onChange={(e) => { handleOnchange(e.target.value) }}
        >
          { priceOptions.map((option, index) => (
              <FormControlLabel
                key={`label-${index + 1}`}
                value={option.id}
                control={<RadioButtonCustom size="small" />}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    ...fontPreset.body14Lig,
                    color: radioVal === option.id && paletteColorConstant.primaryBlue150,
                    transition: "color 100ms ease-in",
                  },
                  margin: "0"
                }}
                label={option.label}
              />
            )) }
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export const DateCard = ({ type = "" }) => {
  const navigate = useNavigate()
  const location = useLocation()
  
  // const options = ["Latest", "Earlier"]
  const options = [
    { value: "Earlier", filter: "by=createdAt&sort=asc", label: "Terlama" },
    { value: "Latest", filter: "by=createdAt&sort=desc", label: "Terbaru" },
  ]


  const [radioVal, setRadioVal] = useState("Latest");
  const handleOnchange = (newRadioVal) => {
    setRadioVal(newRadioVal);

    const search = options.find((option) => option.value === newRadioVal)
    navigate(`/articles?${search.filter ?? ""}${type !== "" ? `&${type}` : ""}`)
  };

  useEffect(() => {
    const url = location.search.slice(1).split("&")
    
    url.forEach((searchLink) => {
      if(searchLink.includes("sort=asc")) {
        setRadioVal("Earlier")
      }
      if(searchLink.includes("sort=desc")) {
        setRadioVal("Latest")
      }
    })
  }, [location])

  return (
    <Box className="sort__card" sx={style.card}>
      <label style={{ ...fontPreset.body14Reg, marginBottom: "12px" }}>
        Tanggal
      </label>
      <FormControl>
        <RadioGroup
          defaultValue="Latest"
          value={radioVal}
          onChange={(e) => { handleOnchange(e.target.value) }}
        >
          { options.map((option, index) => (
              <FormControlLabel
                value={option.value}
                key={`option-${index + 1}`}
                control={<RadioButtonCustom size="small" />}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    ...fontPreset.body14Lig,
                    color:
                      radioVal === option.value && paletteColorConstant.primaryBlue150,
                    transition: "color 100ms ease-in",
                  },
                  margin: "0"
                }}
                label={option.label}
              />
            ))
          }
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
