import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// MATERIAL UI
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
// COMPONENTS
import ArticleDetailButton from './ArticleDetailButton'
// CONSTANTS
import { boxShadowStyle } from '../../constant/shadowConstant'
import { fontPreset, mobileFontPreset } from '../../constant/fontConstant'
import { paletteColorConstant } from '../../constant/colorConstant'
import { stylePreset } from '../../constant/stylePreset'
import { articleDetailDummy } from '../../constant/dummy'
import { monthList } from '../../constant/dateConstant'

const ArticleCardHorizontal = (props) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  const { data, dummy = false } = props

  const printDate = (date) => {
    const input = new Date(date)
    const month = monthList[input.getMonth()]
    return `${month}, ${input.getDate()} ${input.getFullYear()}`
  }

  const printDesc = (desc) => {
    return desc + '...'
  }

  return (
    <article>
        <Box className='article__card__horizontal' 
            sx={{ 
                display: 'flex',
                borderRadius: '20px',
                boxShadow: downMediumScreen ? boxShadowStyle.generalShadow.boxShadow : "none",
                '&:hover': { ...boxShadowStyle.generalShadow }
            }}
        >
            <Box className='article__image'
                sx={{ borderRadius: '20px 20px 0 0', width: downMediumScreen ? '40%' : '50%' }}
            >
                <LazyLoadImage 
                    alt={`person-c-level`}
                    src={data?.photo ?? "/images/AboutUs/section1.png"}
                    width={'100%'}
                    height={'100%'}
                    style={{ 
                        borderRadius: '20px 0 0 20px',
                        objectFit: "cover",
                        objectPosition: "50% 50%"
                    }}
                />
            </Box>

            <Box className='article__desc'
                sx={{ 
                    ...stylePreset.columnFlex,
                    width: downMediumScreen ? '60%' : '50%',
                    padding:  downMediumScreen ? "12px" : '28px 38px',
                    borderRadius: '0 0 20px 20px',
                    rowGap: downMediumScreen ? "12px" : '20px',
                }}
            >
                <Box className='article__title__date'>
                    <Typography
                        component={'h6'}
                        sx={ downMediumScreen ? { ...mobileFontPreset.titleM, lineHeight: "21.09px" } : { ...fontPreset.titleM, lineHeight: '28.13px' } }
                        style={{ color: paletteColorConstant.black1, }}
                    >
                        { data?.title ?? articleDetailDummy.title }
                    </Typography>
                    <Typography
                        sx={{ color: paletteColorConstant.black2, marginTop: '4px' }}
                        style={ downMediumScreen ? mobileFontPreset.body10Lig : fontPreset.body12Lig }
                    >
                        { printDate(data?.createdAt ?? articleDetailDummy.createdAt) }
                    </Typography>
                </Box>

                <Box className='article__desc' 
                    sx={{ display: downMediumScreen ? "none" : "block" }}
                >
                    <Typography
                        sx={{ ...fontPreset.body18Lig, color: paletteColorConstant.black2, lineHeight: '21.09px' }}
                    >
                        { printDesc(data?.cardBody ?? articleDetailDummy.content.substring(0, 200)) }
                    </Typography>
                </Box>

                <Box className='article__action'>
                    <ArticleDetailButton 
                        // articleId={data?.id ?? "dummy"}
                        articleId={ data?.title.split(" ").join("-") ?? articleDetailDummy.title.split(" ").join("-") }
                    />
                </Box>
            </Box>
        </Box>
    </article>
  )
}

export default ArticleCardHorizontal