import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// IMAGE
import defaultimages from "../../images/202008241208141.png";
// MATERIAL UI
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
// CONSTANT
import { fontPreset, mobileFontPreset } from "../../constant/fontConstant";
import { paletteColorConstant } from "../../constant/colorConstant";
import { boxShadowStyle } from "../../constant/shadowConstant";
import { priceFormat } from "../../constant/textFieldConstant";

const style = {
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    // backgroundColor: "#FAFAFA",
    "&:hover": { ...boxShadowStyle.generalShadow },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "2px",
    width: "100%",
    borderRadius: "0 0 12px 12px",
  },
  desc: {
    display: "flex",
    flexDirection: "column",
    rowGap: "6px",
  },
};

export const OurPriceListCard = ({ itemData }) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  const { id, title, type, productDetail, productServicePhoto, price } = itemData

  const printProductBestFor = (detailItems) => {
    const items = []
    detailItems.forEach((item) => items.push(item?.productTypes?.type ?? ""))

    return items.join(", ")
  }

  return (
    <Box className="ourpricelist__card"
      sx={{ ...style.card, boxShadow: downMediumScreen ? boxShadowStyle.generalShadow.boxShadow : "none", height: "100%" }}
    >
      <LazyLoadImage
        src={productServicePhoto[0]?.url ?? defaultimages}
        height={downMediumScreen ? "184px" : "275px"}
        width={"100%"}
        style={{ objectFit: "contain", borderRadius: "12px 12px 0 0" }}
        alt={"price-list"}
      />

      <div className="ourpricelist__card__content" 
        style={{ ...style.content, padding: downMediumScreen ? "12px 12px 20px 12px" : "20px" }}
      >
        <div 
          style={{ 
            maxHeight: "50px",
            height: "42px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              display: "-webkit-box",
              textAlign: "left",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              color: paletteColorConstant.black1,
              lineHeight: downMediumScreen ? "14.06px" : "21px"
            }}
            style={downMediumScreen ? mobileFontPreset.body12Reg : fontPreset.body18Reg}
          >
            {title ?? "-"}
          </Typography>
        </div>

        <Typography
          sx={{ textAlign: "left", color: paletteColorConstant.black2, marginTop: downMediumScreen ? "8px" : "12px" }}
          style={downMediumScreen ? fontPreset.body12Reg : fontPreset.body18Reg}
        >
          IDR {price ? priceFormat(price) : "-"}
        </Typography>

        <div className="ourpricelist__card__content__desc" 
          style={{ ...style.desc, marginTop: downMediumScreen ? "8px" : "12px" }}
        >
          <Typography
            variant="subtitle"
            sx={{ textAlign: "left", color: paletteColorConstant.black2 }}
            style={downMediumScreen ? mobileFontPreset.body10Lig : fontPreset.body12Lig}
          >
            {type ?? "null"}
          </Typography>
          <Typography
            variant="subtitle"
            sx={{ textAlign: "left", color: paletteColorConstant.black2 }}
            style={downMediumScreen ? mobileFontPreset.body10Lig : fontPreset.body12Lig}
            noWrap
          >
            { printProductBestFor(productDetail) }
          </Typography>
        </div>

        <Box sx={{ marginTop: downMediumScreen ? "20px" : "8px" }}>
          <Button
            size="large"
            component={Link}
            to={`/product/${title.split(" ").join("-")}`}
            target={'_blank'}
            // rel={"noopener"}
          >
            Selengkapnya
          </Button>
        </Box>
      </div>
    </Box>
  );
};
