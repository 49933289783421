import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    heroBannerItem: null,
    loading: false,
    error: false
}

export const getHeroBanner = 
    createAsyncThunk(
        'heroBanner/getHeroBanner',
        async (_, thunkAPI) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/herobanner/`)
                return res.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error)
            }
        }
    )

const heroBannerSlice = createSlice({
    name: 'heroBanner',
    initialState,
    extraReducers: {
        [getHeroBanner.pending]: (state) => {
            state.loading = true
        },
        [getHeroBanner.fulfilled]: (state, action) => {
            state.loading = false
            state.heroBannerItem = action.payload
        },
        [getHeroBanner.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

// export const { clearCart } = heroBannerSlice.actions
export default heroBannerSlice.reducer