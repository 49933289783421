import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
// MUI
import { Box, Grid, Pagination, styled, Tabs, Tab, Typography, useMediaQuery, useTheme, Skeleton, Container } from '@mui/material'
// ICONS
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
// COMPONENTS
import { MobileSortCard, SortCard } from "./FilterCard"
import { OurPriceListCard } from "./OurPriceListCard"
import { getAllProducts } from '../../features/product_services/productSlice'
// CONSTANTS
import { boxShadowStyle } from '../../constant/shadowConstant'
import { fontPreset, fontStyleConstant } from '../../constant/fontConstant'
import { paletteColorConstant } from '../../constant/colorConstant'
import { scrollToContent } from '../../constant/stylePreset'

const style = {
    sectiontwo__container: {
        display: "flex",
        columnGap: "72px",
    },
    sectionTwo_filterContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: "40px",
    },
    sectiontwo__productContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    filterSection: {
        minHeight: "48px",
        display: "flex",
        alignItems: "center"
    },
    filterLabel: {
        ...fontPreset.body20Lig,
        color: paletteColorConstant.gray1,
    },
    filter__title: { 
        display: "flex",
        columnGap: "18px",
        color: paletteColorConstant.gray1,
        justifyContent: "space-between",
        "&:hover": { cursor: "pointer" }
    },
    emptyResult: {
        padding: "16px 0"
    }
}

const categories = [
    "Multicam",
    "Streaming",
    "Green Screen",
    "Sound System",
    "Photobooth",
    "Show Management",
    "Others",
];

const TABS_FILTER_MOBILE_HEIGHT_AND_MARGIN = "88px"

const loadingItems = [1,2,3,4]

let filterURL = []
let pageURL = []
let categoryURL = ""

const ProductList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const theme = useTheme()
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

    const [value, setValue] = useState("Multicam");
    const [page, setPage] = useState(1);
    const [openFilter, setOpenFilter] = useState(false)

    const productState = useSelector((state) => state.products)
    const { products } = productState

    const printUpdateURL = (resetPage = false) => {
        let url = []
        
        if(resetPage) { url = [categoryURL, ...filterURL] }
        else url = [categoryURL, ...filterURL, ...pageURL]
        
        return `/product-services?${url.join("&")}`
    }

    const handleOnchange = (event, newValue) => { 
        setValue(newValue)
        categoryURL = `type=${newValue}`
        navigate(printUpdateURL(true))
    };

    const handleOnchangePage = (event, newValue) => { 
        setPage(newValue)
        
        pageURL = []
        pageURL.push(`page=${newValue}`)
        
        navigate(printUpdateURL())
    };
    
    const CustomTab = styled(Tabs)(({ theme }) => ({
        width: "100%",
        "& .MuiTabs-flexContainer": {
          justifyContent: "space-between",
          columnGap: "20px"
        },
        "& .Mui-selected": downMediumScreen ? fontPreset.body16Reg : fontPreset.body20Reg,
    }));

    useEffect(() => {
        if(productState.loading === false && location.search === "") dispatch(getAllProducts({ category: "type=Multicam" }))
        if(location.search !== "") {
            filterURL = []
            pageURL = []
            categoryURL = ""

            // console.log(location.search)
            const url = location.search.slice(1).split("&")
            scrollToContent("sectiontwo__productContainer", { duration: 400, smooth: false, offset: -82 })

            url.forEach((searchLink) => {
                if(searchLink.includes("sort") || searchLink.includes("by")) {
                    filterURL.push(searchLink)
                }
                if(searchLink.includes("page")) {
                    pageURL.push(searchLink)
                    setPage(parseInt(searchLink.split("=")[1]))
                }
                if(searchLink.includes("type")) {
                    categoryURL = searchLink
                }
            })

            dispatch(getAllProducts({ sort: filterURL, page: pageURL, category: categoryURL }))
        }
    
        return () => {}
    }, [location])
    

    return (
        <Box className="root"
            sx={{ position: "relative" }}
        >
            { downMediumScreen &&
                <Container sx={{ position: "absolute", zIndex: "10", paddingRight: downMediumScreen ? "0" : "" }}>
                    <Box className='tab__filter__row'
                        sx={{ 
                            display: "flex",
                            columnGap: "22px",
                            alignItems: "flex-start",
                            width: "100%",
                        }}
                    >
                        <Box className="mobile__filter__box">
                            <Box sx={{ 
                                    ...boxShadowStyle.generalShadow,
                                    borderRadius: "12px",
                                    padding: "12px",
                                    backgroundColor: "white",
                                    // width: "116.5px"
                                    width: "132.5px"
                                }}
                            >
                                <Box className="filter__title"
                                    sx={style.filter__title}
                                    onClick={() => { setOpenFilter(!openFilter) }}
                                >
                                    <Typography sx={{ ...fontPreset.body16Lig }}>
                                        Filter
                                    </Typography>

                                    <KeyboardArrowUpIcon sx={{ transform: !openFilter && 'rotate(180deg)', transition: "transform .1s ease" }} />
                                </Box>

                                { openFilter &&
                                    <Box sx={{ display: "flex", justifyContent: "flex-start", marginTop: "18px" }}>
                                        <MobileSortCard type={categoryURL} />
                                    </Box>
                                }
                            </Box>
                        </Box>

                        <CustomTab
                            value={value}
                            onChange={handleOnchange}
                            variant="scrollable"
                            scrollButtons={false}
                        >
                            {categories.map((val) => (
                                <Tab
                                    key={`key-${val}`}
                                    sx={{ padding: 0, textTransform: "none" }}
                                    style={downMediumScreen ? fontPreset.body16Reg : fontPreset.body20Lig}
                                    value={val}
                                    label={val}
                                />
                            ))}
                        </CustomTab>
                    </Box>
                </Container>
            }
            
            <Container sx={{ marginTop: TABS_FILTER_MOBILE_HEIGHT_AND_MARGIN }}>
                <div className="sectiontwo__container"
                    style={{ ...style.sectiontwo__container}}
                >
                    { !downMediumScreen &&
                        <div className="sectiontwo__filtercontainer"
                            style={style.sectionTwo_filterContainer}
                        >
                            <div style={style.filterSection}>
                                <label style={style.filterLabel}>
                                    Filter
                                </label>
                            </div>
                            <SortCard type={categoryURL} />
                        </div>
                    }

                    <Box className="sectiontwo__productContainer"
                        sx={{ 
                            ...style.sectiontwo__productContainer,
                            rowGap: downMediumScreen ? "40px" : "60px",
                            position: "relative"
                        }}
                    >
                        { !downMediumScreen &&
                            <Box className="sectiontwo__tab__wrapper"
                                sx={{ 
                                    display: "flex",
                                    columnGap: "22px",
                                    alignItems: "flex-start",
                                    position: downMediumScreen ? "absolute" : "block",
                                    width: "100%",
                                }}
                            >
                                <CustomTab
                                    value={value}
                                    onChange={handleOnchange}
                                    variant="scrollable"
                                    scrollButtons={false}
                                >
                                    {categories.map((val) => (
                                        <Tab
                                            key={`key-${val}`}
                                            sx={{ padding: 0, textTransform: "none" }}
                                            style={downMediumScreen ? fontPreset.body16Reg : fontPreset.body20Lig}
                                            value={val}
                                            label={val}
                                        />
                                    ))}
                                </CustomTab>
                            </Box>
                        }

                        <Box className="product__content">
                            {   productState.loading ?
                                    <Grid container spacing={downMediumScreen ? "20px" : "60px"}>
                                        {loadingItems.map((loading) => (
                                            <Grid item xs={6} md={4} key={`price-list-${loading}`}>
                                                <Skeleton 
                                                    variant="rectangular"
                                                    animation="wave"
                                                    style={{ borderRadius: "12px" }}
                                                    height={downMediumScreen ? 400 : 478}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>  :
                                productState.success ?
                                    products.data.length > 0 ? 
                                        (
                                            <>
                                                <Grid container spacing={downMediumScreen ? "20px" : "60px"}>
                                                    {products.data.map((product, index) => (
                                                        <Grid item xs={6} md={4} key={`price-list-${index + 1}`}>
                                                            <OurPriceListCard itemData={product} />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </>
                                        ) : 
                                        (
                                            <Box className="data__empty" sx={style.emptyResult}>
                                                <Typography 
                                                    variant="h6"
                                                    sx={fontStyleConstant.body3}
                                                >
                                                    Data Pada Kategori Ini Tidak Ditemukan.
                                                </Typography>
                                            </Box>
                                        ) :
                                productState.error ?
                                    <Box>Error...</Box> :
                                // LAINNYA
                                    null
                            }

                            <div className="data__pagination"
                                style={{ 
                                    display: "flex",
                                    justifyContent: downMediumScreen ? "center" : "flex-end",
                                    marginTop: downMediumScreen ? "20px" : "60px"
                                }}
                            >
                                <Pagination
                                    count={products?.pagination?.lastPage ?? 0}
                                    sx={{
                                            "& .Mui-selected": {
                                                minWidth: "0",
                                                borderBottom: `1px solid ${paletteColorConstant.primaryBlue150}`,
                                                backgroundColor: "transparant",
                                                borderRadius: "0px",
                                                color: paletteColorConstant.primaryBlue150,
                                            },
                                            "& .MuiPaginationItem-root": {
                                            "&.Mui-selected": { background: "transparent" },
                                        },
                                    }}
                                    shape="rounded"
                                    page={page}
                                    variant="text"
                                    onChange={handleOnchangePage}
                                />
                            </div>
                        </Box>
                    </Box>
                </div>
            </Container>
        </Box>
    )
}

export default ProductList