import { useContext, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// MATERIAL UI
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import RadioGroup from '@mui/material/RadioGroup'
import Skeleton from '@mui/material/Skeleton'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
// ICONS
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// CONSTANTS
import { ConsultationContext } from '../../hooks/consultationContext'
import { fontPreset } from "../../constant/fontConstant";
import { CustomTextField, SelectInputCustom, menuSelectStyle } from '../../constant/textFieldConstant'
import { paletteColor, paletteColorConstant } from '../../constant/colorConstant'
// COMPONENTS
import RadioButtonCustom from '../common/RadioButtonCustom'
import PhoneNumberTextField from '../common/PhoneNumberTextField'

const style = {
    rootContainer: { 
        display: "flex",
        // columnGap: {
        //     xs: "20px",
        //     sm: "60px",
        //     md: "90px",
        //     lg: "124px"
        // },
        columnGap: "clamp(1.25rem, -1.499rem + 10.2767vw, 7.75rem)",
        flexDirection: {xs: "column", sm: "row"},
        alignItems: {xs: "center", sm: "flex-start"},
        rowGap: "40px"
    },
    imageContainer: { 
        // maxWidth: {
        //     xs: "200px",
        //     sm: "300px",
        //     md: "420px",
        //     lg: "588px"
        // },
        maxWidth: "clamp(12.5rem, 2.2441rem + 38.3399vw, 36.75rem)",
        flexBasis: "1/2"
    },
    formField: {
        display: "flex",
        flexDirection: "column",
        rowGap: "40px",
        flex: "1 1 0%",
        flexBasis: "1/2",
        width: "100%",
        padding: {xs: "0 8px", sm: "0 0" },
        color: paletteColor.primaryGray
    },
    labelInput: { 
        ...fontPreset.body16Reg,
        marginBottom: "8px"
    },
    formSection: {
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
    },
    buttonSection: {
        display: "flex",
        justifyContent: "flex-end",
        columnGap: "20px"
    },
    placeholderOption: { 
        color: paletteColorConstant.gray2,
        ...fontPreset.body12Reg,
        marginTop: "2px"
    }
}

const contactOptions = ["WhatsApp", "Email"]

const companyTypeSelect = [
    { menu: "Perusahaan", value: "Perusahaan", id: "perusahaan" },
    {
      menu: "Organisasi Non-profit / Komunitas",
      value: "Organisasi Non-profit / Komunitas",
      id: "komunitas",
    },
    {
      menu: "Sekolah / Universitas",
      value: "Sekolah / Universitas",
      id: "sekolah",
    },
    { menu: "Individual", value: "Individual", id: "individual" },
]

const ClientDataStep = () => {
    // const theme = useTheme();
    // const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    // const upSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const consultationState = useContext(ConsultationContext)

    const [contact, setContact] = useState("");

    const radioVal = "WhatsApp"
    const display = "none"
    const companyType = "placeholder"
    // const companyType = "Perusahaan"

    return (
        <Box sx={style.rootContainer}>
            {/* IMAGE */}
            <Box sx={style.imageContainer}>
                <LazyLoadImage 
                    src={"/images/consultation/clipboard-illus.png"}
                    alt='clipboard image'
                    width={"100%"}
                    style={{ aspectRatio: "1 / 1" }}
                    placeholder={<Skeleton sx={{ aspectRatio: "1 / 1", height: "100%", width: "clamp(12.5rem, 2.2441rem + 38.3399vw, 36.75rem)" }} />}
                />
            </Box>

            {/* FORMS */}
            <Box sx={style.formField}>
                <div className="nama_lengkap_field">
                    <Typography
                        sx={style.labelInput}
                    >
                        Nama Lengkap Kamu
                    </Typography>
                    <CustomTextField
                        placeholder="Contoh: Aditya"
                        variant="outlined"
                        size="medium"
                        // value={name}
                        // onChange={(e) => setName(e.target.value)}
                        fullWidth
                    />
                </div>

                <div className="email_kamu_field">
                    <FormControl>
                        <RadioGroup
                            defaultValue="Email Kamu"
                            row
                            value={radioVal}
                            sx={{ columnGap: "40px", marginBottom: "2px" }}
                            // onChange={(e) => { 
                            //     setContact("");
                            //     setRadioVal(e.target.value);
                            // }}
                        >
                            { 
                                contactOptions.map((val, index) => (
                                    <FormControlLabel
                                        key={`contact-options-${index + 1}}`}
                                        value={val}
                                        control={<RadioButtonCustom size="small" />}
                                        label={val}
                                        sx={{
                                            "& .MuiFormControlLabel-label": {
                                                ...fontPreset.body16Reg,
                                                color: radioVal === val ? paletteColor.blue100 : paletteColor.white25,
                                                transition: "color 100ms ease-in",
                                            },
                                            margin: "0 12px 0 0"
                                        }}
                                    />
                                )) 
                            }
                        </RadioGroup>
                    </FormControl>

                    { radioVal === "Email Kamu" ? 
                        <CustomTextField
                            placeholder={"Contoh: divine@email.com"}
                            variant="outlined"
                            size="small"
                            // value={contact}
                            // onChange={(e) => {
                            //     setContact(e.target.value);
                            // }}
                            fullWidth
                        /> : 
                        // NOMOR TELP (whatsapp)
                        <PhoneNumberTextField
                            placeholder={"Contoh: 081234567890"}
                            variant="outlined"
                            size="small"
                            value={contact}
                            onChange={setContact}
                            disableLabel
                            fullWidth
                        />
                    }
                    
                    {display === "error2" || display === "error3" ? (
                        <Typography
                            sx={{ color: "red", ...fontPreset.body14Lig, display: display }}
                        >
                            Mohon isi sesuai format dalam contoh
                        </Typography>
                    ) : ( 
                        ""
                    )}
                </div>

                <div className="jenis_instansi_field">
                    <Typography
                        sx={style.labelInput}
                    >
                        Jenis Instansi
                    </Typography>
                    <Select
                        labelId="select-jenis-instansi"
                        id="select-jenis-instansi"
                        size="small"
                        fullWidth
                        input={<SelectInputCustom />}
                        IconComponent={ArrowBackIosNewIcon}
                        value={companyType}
                        // onChange={(e) => {
                        //     setCompanyType(e.target.value);
                        //     setCompanyName("");
                        // }}
                        renderValue={(selected) => {
                            if (selected === "placeholder") {
                                return (
                                    <Typography sx={style.placeholderOption}>
                                        Pilih Jenis Instansi
                                    </Typography>
                                );
                            } else {
                                return selected;
                            }
                        }}
                        MenuProps={{ PaperProps: { style: menuSelectStyle } }}
                    >
                        <MenuItem disabled value={"placeholder"}>
                            Pilih Jenis Instansi
                        </MenuItem>
                        {companyTypeSelect.map((val) => (
                            <MenuItem key={val.value} value={val.value}>
                                {val.menu}
                            </MenuItem>
                        ))}
                    </Select>
                </div>

                <div className="nama_instansi_field">
                    <Typography
                        sx={style.labelInput}
                    >
                        Nama Instansi
                    </Typography>
                    <CustomTextField
                        placeholder={
                            companyType === "Individual" ? 
                                "Kosongkan jika pilihan instansi adalah Individual" : 
                            // ORGANISASI / PERUSAHAAN / SEKOLAH
                                "Contoh: Pegadaian"
                        }
                        variant="outlined"
                        size="small"
                        // value={companyName}
                        // onChange={(e) => setCompanyName(e.target.value)}
                        disabled={ companyType === "Individual" || companyType === "placeholder" }
                        fullWidth
                    />
                </div>

                <div className="action__button"
                    style={style.buttonSection}
                >
                    <Button
                        variant="contained"
                        sx={{ marginTop: "0px" }}
                        onClick={() => { consultationState.nextStep() }} 
                        // disabled={checkDisabledButton()}
                        size="large"
                    >
                        Selanjutnya
                    </Button>
                </div>
            </Box>
        </Box>
    )
}

export default ClientDataStep