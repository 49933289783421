import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export default function ScrollToTopAfterChangePage() {
    const { pathname, search } = useLocation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            // behavior: 'smooth'
        })
    }, [pathname, search])

    return null;
}