import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
// Animate On Scroll
import Aos from "aos";
// Import AOS css
import 'aos/dist/aos.css'
// MATERIAL UI
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import useScrollTrigger from "@mui/material/useScrollTrigger";
// ICONS
import LeftSliderIcon from "../icons/arrows/LeftSliderIcon";
// CONSTANT
import { paletteColor } from "../../constant/colorConstant";

const Layout = () => {

  useEffect(() => {
    Aos.init({
      once: true,
      duration: "450"
    })
  }, [])
  

  const ScrollTop = (props) => {
    const { children, window, handleClick } = props;
  
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    })
  
    return (
      <Fade in={trigger}>
        <div
          onClick={handleClick}
          role="presentation"
          style={{ position: "fixed", bottom: '2rem', right: 16, zIndex: 100 }}
        >
          {children}
        </div>
      </Fade>
    );
  };

  const handleClickScroll = () => { window.scrollTo({ top: 0, behavior: "smooth" }) }

  // const onCLickWhatsapp = () => {
  //   window.open(
  //     `${DIVINE_WA_CHAT_LINK}&text=Halo Saya Ingin Menanyakan Tentang Event Lebih Lanjut, Berikut Kebutuhan Event Saya :`
  //   );
  // };

  return (
    <>
      <Header />

      <main>
        <Outlet />
      </main>

      <Footer />

      {/* FLOATING ACTION BUTTON WHATSAPP */}
      {/* <div className="whatsapp-button" 
        style={{ position: "fixed", bottom: 16, right: 16, zIndex: 1000 }}
      >
        <Fab
          aria-label="whatsapp button"
          sx={{ 
            backgroundColor: 'rgba(34, 165, 21, 1)',
            '&:hover': { backgroundColor: 'rgba(34, 165, 21, 0.8)' },
            width: '52px',
            height: '52px',
          }}
          onClick={onCLickWhatsapp}
        >
          <WhatsAppIcon sx={{ color: paletteColorConstant.white2, fontSize: '32px' }} />
        </Fab>  
      </div> */}

      <ScrollTop handleClick={() => handleClickScroll(window)}>
        <Box sx={{ transform: "rotate(90deg)", "&:hover": { cursor: "pointer" } }}>
          <LeftSliderIcon
            width="42"
            height="42"
            color={paletteColor.primaryBlue}
          />
        </Box>
      </ScrollTop>
    </>
  );
};

export default Layout;
