import React, { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageGallery from "react-image-gallery";
import "./style/CaseStudyDetails.css";
// MATERIAL UI
import { Box, Button, Dialog, IconButton, Typography, useMediaQuery } from "@mui/material";
// MATERIAL ICONS
import CloseIcon from "@mui/icons-material/Close";
// CONSTANT
import { fontPreset } from "../../constant/fontConstant";
import { paletteColorConstant } from "../../constant/colorConstant";
import { UpTransition } from "../../constant/stylePreset";
import { useTheme } from "@emotion/react";

const equipmentList = [
  "Show Director",
  "Stage Manager",
  "Music Director",
  "VJ Resolume",
  "Multicam System 3 Camera",
  "Live Streaming System",
  "Documentation",
  "Stage Lighting",
  "Sound System 5000 Watt",
  "Multicam System 2 PTZ Camera",
  "Laptop Relay",
  "Laptop Operator",
];

const style = {
  root: {
    display: "flex",
    justifyContent: "center",
    padding: "72px 0 0 0",
    position: "relative",
  },
  detailCaseStudy: {
    display: "flex",
    columnGap: "60px",
    rowGap: "40px"
  },
  column: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    rowGap: "40px",
  },
};

const CaseStudyDetails = ({ 
  images = [],
  title,
  quotes = null,
  about,
  provides = equipmentList,
  logo = null
}) => {
  const theme = useTheme();
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  
  const [open, setOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  
  const handleClickOpen = () => { setOpen(true) };
  const handleClose = () => { setOpen(false) };

  useEffect(() => {
    let imageList = []
    images.forEach((val) => { 
      imageList.push({ 
        original: val,
        thumbnail: val,
        thumbnailHeight: downMediumScreen ? "62px" : "92px",
      })
    });
    setImageList(imageList)
  }, [])
  

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{ marginTop: "20px", color: paletteColorConstant.white1 }}
        onClick={handleClickOpen}
      >
        Selengkapnya
      </Button>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={UpTransition}
      >
        <Box
          className="root-details-dialog"
          sx={{ ...style.root, padding: downMediumScreen ? "72px 0 90px 0" : style.root.padding }}
        >
          {/* CLOSE BUTTON */}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: downMediumScreen ? "20px" : "72px",
              top: downMediumScreen ? "30px" : "40px",
              color: paletteColorConstant.black1,
            }}
          >
            <CloseIcon sx={{ fontSize: "24px" }} />
          </IconButton>

          <Box className="details-dialog-wrapper"
            sx={{ width: downMediumScreen ? "100%" : "80%" }}
          >
            <Box className="title"
              sx={{ 
                marginBottom: "40px",
                height: downMediumScreen ? "" : "108px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography
                sx={{ 
                  ...fontPreset.titleXl,
                  fontSize: downMediumScreen ? fontPreset.titleXlMobile : fontPreset.titleXl,
                  color: downMediumScreen ? paletteColorConstant.black1 : paletteColorConstant.black2,
                }}
                align="center"
              >
                { title }
              </Typography>
            </Box>

            <Box className="detail__case__study"
              sx={{ ...style.detailCaseStudy, flexDirection: downMediumScreen ? "column" : "row" }}
            >
              <Box className="left__column"
                sx={{
                  width: downMediumScreen ? "100%  " : "45%",
                  padding: downMediumScreen ? "0 20px" : "0px",
                }}
              >
                <ImageGallery
                  additionalClass="case_study_gallery"
                  items={imageList}
                  showPlayButton={false}
                  autoPlay={false}
                  infinite={false}
                  lazyLoad={true}
                  showFullscreenButton={false}
                  showBullets={true}
                  showNav={false}
                />
              </Box>

              <Box className="right__column"
                sx={{ 
                  ...style.column,
                  padding: downMediumScreen ? "0 20px" : "0 0 60px 0",
                  width: downMediumScreen ? "100%" : "50%",
                }}
              >
                { logo !== null && quotes !== null && quotes !== "-" &&
                  <Box className="logo__and__comment">
                    { logo !== null &&
                      <Box
                        sx={{ marginY: "12px", marginBottom: downMediumScreen ? "40px" : "" }}
                      >
                        <LazyLoadImage
                          src="/images/section_four/case_study/logo-atas.png"
                          alt="kemenkeu-logo"
                          width="185"
                          height="47"
                        />
                      </Box>
                    }

                    { quotes !== null && quotes !== "-" &&
                      <Typography
                        sx={{ ...fontPreset.body24Lig, 
                          fontSize: downMediumScreen ? fontPreset.body18Lig : fontPreset.body24Lig,
                          padding: downMediumScreen ? "0 5%" : "12px 0 0 0",
                          color: paletteColorConstant.gray1,
                          lineHeight: "28.13px" }}
                        align="center"
                      >
                        <q>
                          { quotes }
                        </q>
                      </Typography>
                    }
                  </Box>
                }

                <Box className="about__project">
                  <Typography
                    component="h3"
                    sx={{ 
                      ...fontPreset.titleS, 
                      fontSize: downMediumScreen ? fontPreset.titleM : fontPreset.titleS, 
                      color: paletteColorConstant.black1,
                      marginBottom: "12px",
                    }}
                    fontWeight={"bold"}
                  >
                    About Project
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      ...fontPreset.body16Lig,
                      fontSize: downMediumScreen
                        ? fontPreset.body14Lig
                        : fontPreset.body16Lig,
                      color: paletteColorConstant.black2,
                      lineHeight: "18.75px",
                    }}
                  >
                    { about }
                  </Typography>
                </Box>

                <Box className="what__we__provide">
                  <Typography
                    component="h3"
                    sx={{
                      ...fontPreset.titleS,
                      fontSize: downMediumScreen ? fontPreset.titleM : fontPreset.titleS,
                      color: paletteColorConstant.black1,
                      margin: "0 0 12px"
                    }}
                    fontWeight={"bold"}
                  >
                    What We Provide
                  </Typography>

                  <Box sx={{ display: "flex" }}>
                    {/* <Box sx={{ width: "50%" }}>
                      <ul style={{ padding: "0 25px", margin: "12px 0 0 0" }}>
                        { provides.slice(0, 7).map((val) => (
                          <li
                            key={val}
                            style={{
                              ...fontPreset.body16Lig,
                              fontSize: downMediumScreen
                                ? fontPreset.body14Lig
                                : fontPreset.body16Lig,
                              color: paletteColorConstant.black2,
                            }}
                          >
                            {val}
                          </li>
                          ))
                        }
                      </ul>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <ul style={{ padding: "0 25px", margin: "12px 0 0 0" }}>
                        { provides.length > 9 && 
                          provides.slice(8, equipmentList.length - 1).map((val) => (
                            <li
                              key={val}
                              style={{
                                ...fontPreset.body16Lig,
                                color: paletteColorConstant.black2,
                              }}
                            >
                              {val}
                            </li>
                          )) }
                      </ul>
                    </Box> */}

                    <Box sx={{ width: "100%" }}>
                      <ul 
                        style={{ 
                          margin: "0",
                          padding: "0 0 0 18px",
                          columns: downMediumScreen ? "1" : "2",
                          columnGap: "56px",
                          listStylePosition: "outside",
                        }}
                      >
                        { provides.map((provide, index) => (
                          <li
                            key={`${provide}-${index + 1}`}
                            style={ 
                              downMediumScreen ? 
                                { ...fontPreset.body14Lig, color: paletteColorConstant.black2, marginBottom: "4px" } : 
                                {  ...fontPreset.body16Lig, color: paletteColorConstant.black2, marginBottom: "4px" }
                            }
                          >
                            {provide}
                          </li>
                          ))
                        }
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default CaseStudyDetails;
