import React from "react";
import { Link } from "react-router-dom";
// MATERIAL UI
import { Button, useMediaQuery, useTheme } from "@mui/material";

const ArticleDetailButton = ({ buttonFullWidth = false, articleId = "dummy" }) => {
  const theme = useTheme();
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Button
        component={Link}
        // to={`/article/${articleDetailDummy.title.split(" ").join("-")}`}
        to={`/article/${articleId}`}
        target="_blank"
        rel={"noopener"}
        fullWidth={buttonFullWidth}
        size={ downMediumScreen ? "medium" : "large" }
      >
        Baca Selengkapnya
      </Button>
    </>
  );
};

export default ArticleDetailButton;
