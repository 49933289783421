import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    profileCompany: null,
    loading: false,
    error: false
}

export const getProfileCompany = 
    createAsyncThunk(
        'profileCompany/getProfileCompany',
        async (_, thunkAPI) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/companyProfile/v1/`)
                return res.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error)
            }
        }
    )

const companyProfileSlice = createSlice({
    name: 'profileCompany',
    initialState,
    extraReducers: {
        [getProfileCompany.pending]: (state) => {
            state.loading = true
            state.profileCompany = null
        },
        [getProfileCompany.fulfilled]: (state, action) => {
            state.loading = false
            state.profileCompany = action.payload
        },
        [getProfileCompany.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.profileCompany = null
        }
    }
})

// export const { clearCart } = companyProfileSlice.actions
export default companyProfileSlice.reducer