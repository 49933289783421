import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    article: [],
    loading: false,
    success: false,
    error: false
}

export const getHighlightedArticle = 
    createAsyncThunk(
        'highlight/getHighlightedArticle',
        async (_, thunkAPI) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/articles/v1/getArticle?highlighted=true`)
                return res.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error)
            }
        }
    )

const highlightSlice = createSlice({
    name: 'highlight',
    initialState,
    extraReducers: {
        [getHighlightedArticle.pending]: (state) => {
            state.loading = true
        },
        [getHighlightedArticle.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.article = action.payload
        },
        [getHighlightedArticle.rejected]: (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        }
    }
})

// export const { clearArticles } = highlightSlice.actions
export default highlightSlice.reducer