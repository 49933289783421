export const exampleColor = {
  color1: "#304d63",
  color2: "#B2E7E8",
  color3: "#8FB9AA",
  color4: "#F2D096",
  color5: "#ED8975",
  color6: "#D9D9D9",
};

export const paletteColorConstant = {
  primaryBlue: "#42A3E3",
  primaryBlue150: "#144681",
  primaryAqua1: "#004459",
  primaryAqua2: "#80A1AC",
  primaryAqua3: "#00222C",
  secondaryBlue1: "#0A2341",
  secondaryBlue2: "#4F74A1",
  black1: "#2C2C2C",
  black2: "#474747",
  black3: "#161616",
  gray1: "#959595",
  gray2: "#CACACA",
  white1: "#FFFFFF",
  white2: "#F5F5F5",
  red: "#F5F5F5",
};

export const paletteColor = {
  primaryBlue: "#15539C",
  primaryGray: "#474747",
  black150: "#1E1E1E",
  black100: "#474747",
  black75: "#828282",
  white50: "#B5B5B5",
  white25: "#E8E8E8",
  white0: "#FFFFFF",
  blue150: "#0B2E58",
  blue100: "#15539C",
  blue75: "#4F74A1",
  blue50: "#B1C7E3",
}
