import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link as RouteLink } from "react-router-dom"
// MATERIAL UI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
// ICONS
import YoutubeIcon from "../icons/YoutubeIcon";
import InstagramIcon from "../icons/InstagramIcon";
import LinkedInIcon from "../icons/LinkedInIcon";
// CONSTANT
import { paletteColor } from "../../constant/colorConstant";
import { fontPreset } from "../../constant/fontConstant";
import { ALAMAT_DIVINE, DIVINE_IG_LINK, DIVINE_LINKEDIN_LINK, DIVINE_YOUTUBE_LINK, LOKASI_MAPS_DIVINE, NOMOR_TELEPON_DIVINE } from "../../constant/utils/contentBody";

const style = {
  root: {
    backgroundColor: paletteColor.primaryGray,
  },
  contentText: {
    ...fontPreset.body14Lig,
    color: "white",
    lineHeight: "normal"
  },
  contentTextMobile: {
    ...fontPreset.body10Reg,
    color: "white",
    fontWeight: "300",
    lineHeight: "normal"
  },
  contentTextTitle: {
    ...fontPreset.body16Reg,
    color: "white",
  },
  contentTextTitleMobile: {
    ...fontPreset.body12Reg,
    color: "white",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    rowGap: "14px",
  },
  linkColumn: { 
    display: "flex",
    flexDirection: "column",
    rowGap: "8px"
  },
  link: {
    fontSize: "12px",
    color: "white",
    cursor: "pointer",
    fontWeight: "300",
    "&:hover": {
      fontWeight: "600",
    }
  },
  linkMobile: {
    fontSize: "10px",
    color: "white",
    cursor: "pointer",
    fontWeight: "300",
    "&:hover": {
      fontWeight: "600",
    }
  },
};

const Footer = () => {
  const theme = useTheme();
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <footer>
      <div style={{ ...style.root, padding: "44px 0 132px" }}>
        <Container
          className="footer__container"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: downMediumScreen ? "flex-start" : "center",
            alignItems: downMediumScreen ? "center" : "flex-start",
            rowGap: "30px",
            height: "100%",
          }}
        >
          <LazyLoadImage
            src={"/divine-logo.png"}
            alt={"Divine Pro Logo"}
            height={downMediumScreen ? "85px" : "100px"}
            width={downMediumScreen ? "85px" : "100px"}
          />

          <Box className="footer__content__container"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: downMediumScreen ? "column" : "row",
              width: "100%",
              rowGap: "40px",
            }}
          >
            <Box className="footer__content__location"
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: downMediumScreen ? "12px" : "14px",
                width: downMediumScreen ? "100%" : "311px",
              }}
            >
              <Typography
                sx={
                  downMediumScreen
                    ? style.contentTextTitleMobile
                    : style.contentTextTitle
                }
              >
                Lokasi
              </Typography>
              <Link
                variant="inherit"
                underline="none"
                sx={downMediumScreen ? style.linkMobile : style.link}
                href={LOKASI_MAPS_DIVINE}
                target={"_blank"}
                rel={"noopener"}
              >
                { ALAMAT_DIVINE }
              </Link>
              <Typography
                sx={downMediumScreen ? style.contentTextMobile : style.contentText}
              >
                Hubungi: { NOMOR_TELEPON_DIVINE }
              </Typography>
            </Box>

            <Box className="footer__content__ourServices"
              sx={{
                display: downMediumScreen ? "none" : "flex",
                flexDirection: "column",
                rowGap: downMediumScreen ? "12px" : "14px",
                width: "137px",
              }}
            >
              <Typography sx={style.contentTextTitle}>
                Our Services
              </Typography>
              
              <Box sx={style.linkColumn}>
                <Link
                  variant="inherit"
                  underline="none"
                  sx={style.link}
                  href="/product-services"
                >
                  Sewa Peralatan
                </Link>
                <Link
                  variant="inherit"
                  underline="none"
                  sx={style.link}
                  href="/product-services?section=ourPriceList'"
                >
                  Sewa Kru
                </Link>
              </Box>
            </Box>

            <Box className="footer__content__aboutUs"
              sx={{
                display: downMediumScreen ? "none" : "flex",
                flexDirection: "column",
                rowGap: downMediumScreen ? "12px" : "14px",
                width: "80px",
              }}
            >
              <Typography sx={style.contentTextTitle}>About Us</Typography>

              <Box sx={style.linkColumn}>
                <Link
                  variant="inherit"
                  underline="none"
                  sx={style.link}
                  href="/about-us"
                >
                  Tim Kami
                </Link>
                <Link
                  variant="inherit"
                  underline="none"
                  sx={style.link}
                  href="/about-us"
                >
                  Kontak
                </Link>
              </Box>
            </Box>

            <Box className="footer__content__relatedSite"
              sx={{
                display: downMediumScreen ? "none" : "flex",
                flexDirection: "column",
                rowGap: downMediumScreen ? "12px" : "14px",
              }}
            >
              <Typography sx={style.contentTextTitle}>
                Related Site
              </Typography>

              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  columnGap: "15px",
                }}
              >
                <Link
                  href={DIVINE_YOUTUBE_LINK}
                  underline="none"
                  target="_blank"
                  rel={"noopener"}
                  sx={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <YoutubeIcon width={"20"} height={"14"} />
                </Link>

                <Link
                  href={DIVINE_IG_LINK}
                  underline="none"
                  target="_blank"
                  rel={"noopener"}
                  sx={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InstagramIcon height={"16"} width={"16"} />
                </Link>

                <Link
                  href={DIVINE_LINKEDIN_LINK}
                  underline="none"
                  target="_blank"
                  rel={"noopener"}
                  sx={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LinkedInIcon height={"16"} width={"16"} />
                </Link>
              </Box>
            </Box>

            <Box className="footer__content__middle__mobile"
              sx={{
                display: downMediumScreen ? "flex" : "none",
                justifyContent: "space-between",
              }}
            >
              <Box
                className="footer__content__ourServices"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "14px",
                }}
              >
                <Typography
                  className="footer__ourServices__title"
                  sx={style.contentTextTitleMobile}
                >
                  Our Services
                </Typography>
                <Box
                  className="footer__ourServices__link"
                  sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}
                >
                  <Link
                    variant="inherit"
                    underline="none"
                    sx={style.linkMobile}
                    href="/product-services"
                  >
                    Rent Event Equipment
                  </Link>
                  <Link
                    variant="inherit"
                    // component="button"
                    underline="none"
                    sx={style.linkMobile}
                    href="/product-services?section=ourPriceList'"
                  >
                    Hire Man Power
                  </Link>
                </Box>
              </Box>

              <Box
                className="footer__content__aboutUs"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "14px",
                  width: "58px",
                }}
              >
                <Typography
                  className="footer__aboutUs__title"
                  sx={style.contentTextTitleMobile}
                >
                  About Us
                </Typography>

                <Box
                  className="footer__aboutUs__link"
                  sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}
                >
                  <Link
                    variant="inherit"
                    underline="none"
                    sx={style.linkMobile}
                    href="/about-us"
                  >
                    Our Team
                  </Link>
                  <Link
                    variant="inherit"
                    underline="none"
                    sx={style.linkMobile}
                    href="/about-us"
                  >
                    Contact
                  </Link>
                </Box>
              </Box>

              <Box
                className="footer__content__relatedSite"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "14px",
                  width: "96px",
                }}
              >
                <Typography sx={style.contentTextTitleMobile}>
                  Related Site
                </Typography>

                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    columnGap: "15px",
                  }}
                >
                  <Link
                    href={DIVINE_YOUTUBE_LINK}
                    underline="none"
                    target="_blank"
                    rel={"noopener"}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <YoutubeIcon width={"20"} height={"14"} />
                  </Link>

                  <Link
                    href={DIVINE_IG_LINK}
                    underline="none"
                    target="_blank"
                    rel={"noopener"}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <InstagramIcon height={"16"} width={"16"} />
                  </Link>

                  <Link
                    href={DIVINE_LINKEDIN_LINK}
                    underline="none"
                    target="_blank"
                    rel={"noopener"}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <LinkedInIcon height={"16"} width={"16"} />
                  </Link>
                </Box>
              </Box>
            </Box>

            <Box className="footer__content__consultationForm"
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: downMediumScreen ? "12px" : "14px",
                width: "100%",
                maxWidth: downMediumScreen ? "100%" : "220px",
              }}
            >
              <Typography
                sx={
                  downMediumScreen
                    ? style.contentTextTitleMobile
                    : style.contentTextTitle
                }
              >
                Consultation Form
              </Typography>
              
              <Box>
                <Typography
                  sx={{ marginBottom: "20px" }}
                  style={downMediumScreen ? style.contentTextMobile : style.contentText}
                >
                  Beritahu kami setiap detail kebutuhan Anda, dan tim kami akan menyesuaikan dengan budget Anda.
                </Typography>

                <Button
                  color="primary"
                  variant={"contained"}
                  size={"medium"}
                  LinkComponent={RouteLink}
                  to={"/consultation"}
                >
                  Konsultasi Gratis
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
