import { LazyLoadImage } from 'react-lazy-load-image-component';
// MATERIAL UI
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
// CONSTANTS
import { fontPreset } from "../../constant/fontConstant";
import { paletteColor } from "../../constant/colorConstant";

const style = {
  card: {
    display: "flex",
    borderRadius: "20px",
    background: paletteColor.black150
    // "&:hover": { ...boxShadowStyle.generalShadow },
    // boxShadow: downMediumScreen ? boxShadowStyle.generalShadow.boxShadow : "none"
  },
  cardMedia: {
    maxWidth: "211px",
    borderRadius: "20px 0 0 20px",
  },
  cardDesc: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "0 20px 20px 0"
  },
};

const HorizontalCard = ({
  imageSrc = "",
  title,
  body,
  buttonTitle,
  buttonAction,
}) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box sx={style.card}>
      <Box sx={{ ...style.cardMedia, width: downMediumScreen ? "25%" : "35%" }}>
        <LazyLoadImage
          src={imageSrc}
          alt={`${title}-illus`}
          width={"100%"}
          height={"100%"}
          style={{ borderRadius: "20px 0 0 20px", objectFit: "cover", objectPosition: downMediumScreen ? "50% 0%" : "0% 0%" }}
          effect="blur"
        />
      </Box>

      <Box 
        sx={{ 
          ...style.cardDesc,
          padding: downMediumScreen ? "20px 15px 20px 20px" : "20px 20px 20px 30px",
          width: downMediumScreen ? "75%" : "65%",
          rowGap: downMediumScreen ? "12px" : "20px",
        }}
      >
        <Box>
          <Typography
            variant="body1"
            style={ downMediumScreen ? { ...fontPreset.body16Med } : { ...fontPreset.body20Med }  }
            sx={{ lineHeight: "normal" }}
          >
            {title}
          </Typography>

          <Typography
            variant="body1"
            sx={{ marginTop: downMediumScreen ? "8px" : "12px" }}
            style={downMediumScreen ? { ...fontPreset.body14Lig, lineHeight: "normal" } : { ...fontPreset.body16Lig, lineHeight: "16.41px" }}
          >
            {body}
          </Typography>
        </Box>

        <Button
          variant="contained"
          onClick={buttonAction}
          size="large"
        >
          {buttonTitle}
        </Button>
      </Box>
    </Box>
  );
};

export default HorizontalCard;
