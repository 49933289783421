import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
// ACTIONS
import { getAllArticles } from "../../features/article/articleSlice"
// MATERIAL UI
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
// COMPONENTS
import ArticleCard from "./ArticleCard"
import { fontPreset } from "../../constant/fontConstant"

const ARTICLE_DUMMY_ITEMS = [0,1,2,3,4,5]

const ArticleGridView = (data) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const articleList = useSelector((state) => state.articles)
    const { loading, success, articles, error } = articleList

    const handleRefetchData = () => {
        dispatch(getAllArticles({}))
    }

    const handleOtherArticles = () => {
        navigate("/articles")
    }

    useEffect(() => {
        dispatch(getAllArticles({}))
      
        return () => {}
    }, [])

    return (
        <div style={{ marginTop: "24px" }}>
            {   
                loading ?
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Grid container spacing={"16px"}>
                            { ARTICLE_DUMMY_ITEMS.map((item) => (
                                <Grid item xs={6} md={4} key={`article-loading-${item}`}>
                                    <Skeleton variant="rounded" height={296} />
                                </Grid>
                            )) }
                        </Grid>

                        <Skeleton
                            variant="rounded"
                            height={33}
                            width={158}
                            sx={{ marginTop: "40px" }}
                        />
                    </div> :
                error ? 
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "200px" }}>
                        <Typography
                            sx={fontPreset.body14Reg}
                        >
                            Oops, Something went wrong.
                        </Typography>

                        <Button
                            sx={{ marginTop: "20px" }}
                            variant="contained"
                            onClick={handleRefetchData}
                        >
                            Re-fetch data
                        </Button>
                    </div> :
                success ?
                    articles.data.length !== 0 ? 
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Grid container spacing={"16px"}>
                                { articles.data.map((article, index) => (
                                    <Grid item xs={6} md={4} key={`article-home-${index}`}>
                                        <ArticleCard
                                            imageTitle={`article-${index + 1}`}
                                            data={article}
                                        />
                                    </Grid>
                                )) }
                            </Grid>

                            <Button
                                sx={{ marginTop: "40px" }}
                                onClick={handleOtherArticles}
                            >
                                Lihat Artikel Lainnya
                            </Button>
                        </div> :
                    // KOSONG
                        <div>Data Kosong</div> :
                // NON LOADING
                    <div>NON</div>
            }
        </div>
    )
}

export default ArticleGridView