import React from 'react'
import NumberFormat from 'react-number-format'

export const PhoneNumberFormatCustom = React.forwardRef(function PhoneNumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        allowLeadingZeros
        allowNegative={false}
        thousandSeparator={false}
      />
    );
});