import ReactGA from 'react-ga4'

const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    // if (/windows phone/i.test(userAgent)) {
    //     return "Windows Phone";
    // }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "other"
}

export const captureEventGAWithAction = ({ category, action, params = null }) => {

    if(JSON.parse(localStorage.getItem('user'))?.id !== 0) {
        if(params !== null) {
            ReactGA.event({ 
                category,
                action,
                ...params,
                platform: getMobileOperatingSystem()
            })
        } else {
            ReactGA.event({ 
                category,
                action,
                platform: getMobileOperatingSystem()
            })
        }
    }
}

export const capturePageview = ({ page }) => {
    if(JSON.parse(localStorage.getItem('user'))?.id !== 0) {
        ReactGA.send({ hitType: "pageview", page })
    }
}