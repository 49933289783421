import React, { useState } from "react";
import validator from "validator";
// MATERIAL UI
import {
  Box,
  Button,
  Container,
  Typography,
  IconButton,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// ICONS
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from "@mui/icons-material/Call";
// CONSTANT
import {
  CustomTextField,
  CustomDialog,
} from "../../constant/textFieldConstant";
import { stylePreset } from "../../constant/stylePreset";
import { fontPreset, mobileFontPreset } from "../../constant/fontConstant";
import { paletteColorConstant } from "../../constant/colorConstant";

import PhoneNumberTextField from "../common/PhoneNumberTextField";
import { ALAMAT_DIVINE, NOMOR_TELEPON_DIVINE } from "../../constant/utils/contentBody";

const GetInTouchForm = () => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [question, setQuestion] = useState("");
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState("none");
  const checkDisabledButton = () => {
    let disable = true;
    if (name === "" || email === "" || question === "") {
      return disable;
    }
    disable = false;

    return disable;
  };
  const handleSubmit = (e) => {
    // e.preventDefault();
    if (validator.isEmail(email)) {
      setOpen(true);
    } else {
      setEmail("");
      setDisplay("");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetAllValue = () => {
    setDisplay("none");
    setEmail("");
    setContact("");
    setQuestion("");
    setName("");
  };

  const mapsSection = () => (
    <Box className="maps__section"
      sx={{
        width: downMediumScreen ? "100%" : "50%",
        color: paletteColorConstant.black2,
        ...stylePreset.columnFlex,
        rowGap: "20px",
        alignItems: "center"
      }}
    >
      <Box sx={{ height: downMediumScreen ? "244px" : "300px", width: "100%", backgroundColor: "gray" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.817220267204!2d106.7129078!3d-6.287740299999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ff808ed12625%3A0x70e36cccb9e237c4!2sDivine%20Production!5e0!3m2!1sid!2sid!4v1660661343421!5m2!1sid!2sid"
          title="Divne Pro Location On Google Maps"
          width={downMediumScreen ? "100%" : "550px"}
          height={ "100%"}
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Box>

      <Box sx={{ display: "flex", columnGap: "20px", width: downMediumScreen ? "75%" : "100%" }}>
        <Box>
          <LocationOnIcon />
        </Box>
        <Box sx={downMediumScreen ? fontPreset.body14Reg : fontPreset.body18Reg }>
          { ALAMAT_DIVINE }
        </Box>
      </Box>

      <Box sx={{ display: "flex", columnGap: "20px", width: downMediumScreen ? "75%" : "100%" }}>
        <Box>
          <CallIcon />
        </Box>
        <Box sx={downMediumScreen ? fontPreset.body14Reg : fontPreset.body18Reg }>
          { NOMOR_TELEPON_DIVINE }
        </Box>
      </Box>
    </Box>
  )

  return (
    <>
      <Container>
        <Typography
          component={"h3"}
          sx={{ color: paletteColorConstant.black2 }}
          style={downMediumScreen ? mobileFontPreset.titleXL : fontPreset.titleXl}
          align={downMediumScreen ? "center" : "left"}
        >
          Hubungi Kami
        </Typography>

        <Box className="form__and__maps"
          sx={{ 
            display: "flex",
            columnGap: "142px",
            marginTop: downMediumScreen ? "40px" : "60px",
            flexDirection: downMediumScreen ? "column" : "row"
          }}
        >
          <Box className="form__section"
            sx={{ width: downMediumScreen ? "100%" : "50%", ...stylePreset.columnFlex, rowGap: "20px" }}
          >
            <Box className="nama_lengkap_field" sx={stylePreset.formSectionStyle}>
              <Typography
                sx={{ ...fontPreset.body14Reg, color: paletteColorConstant.black1 }}
              >
                Nama Lengkap Kamu
              </Typography>
              <CustomTextField
                placeholder="Contoh: Aditya"
                variant="outlined"
                size="medium"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Box>

            <Box className="email_field" sx={stylePreset.formSectionStyle}>
              <Typography
                sx={{ ...fontPreset.body14Reg, color: paletteColorConstant.black1 }}
              >
                Email Kamu
              </Typography>
              <CustomTextField
                placeholder="Contoh: divine@email.com"
                variant="outlined"
                size="medium"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
              <Typography
                sx={{ color: "red", ...fontPreset.body14Lig, display: display }}
              >
                Mohon masukkan email sesuai format dalam contoh
              </Typography>
            </Box>

            <Box className="contact_field" sx={stylePreset.formSectionStyle}>
              <Typography
                sx={{ ...fontPreset.body14Reg, color: paletteColorConstant.black1 }}
              >
                Nomor Handphone/WhatsApp (Optional)
              </Typography>
              <PhoneNumberTextField
                placeholder={"Contoh: 081234567890"}
                variant="outlined"
                size="small"
                value={contact}
                onChange={setContact}
                disableLabel
                fullWidth
              />
            </Box>

            <Box className="pertanyaan_field" sx={stylePreset.formSectionStyle}>
              <Typography
                sx={{ ...fontPreset.body14Reg, color: paletteColorConstant.black1 }}
              >
                Tulis Pesan atau Pertanyaanmu di sini
              </Typography>
              <CustomTextField
                placeholder="Contoh: Apakah saya bisa mendapatkan harga khusus?"
                variant="outlined"
                size="small"
                multiline
                rows={5}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                fullWidth
              />
            </Box>

            <Box className="action_field" sx={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                size="large"
                disabled={checkDisabledButton()}
                onClick={() => handleSubmit()}
              >
                Kirim
              </Button>
            </Box>
          </Box>

          { !downMediumScreen && mapsSection() }
        </Box>
      </Container>

      <Box className="maps__mobile" 
        sx={{ display: downMediumScreen ? "block" : "none", marginTop: "60px" }}
      >
          { mapsSection() }
      </Box>

      <CustomDialog
        open={open}
        fullWidth
        maxWidth={"xmd"}
        PaperProps={{
          style: { borderRadius: "12px" },
        }}
      >
        <DialogContent>
          <Box className="success__section"
            sx={{
              height: "600px",
              position: "relative",
              background:
                "url(./images/home/consultation_dialog/success_step.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
            }}
          >
            <IconButton
              aria-label="close"
              size="large"
              onClick={() => {
                handleClose();
                resetAllValue();
              }}
              sx={{ position: "absolute", top: "40px", right: "60px" }}
            >
              <CloseIcon sx={{ color: paletteColorConstant.white1 }} />
            </IconButton>

            <Box className="success__text">
              <Typography
                component={"h3"}
                sx={{
                  ...fontPreset.titleXl,
                  color: paletteColorConstant.white1,
                  lineHeight: "49.22px",
                  position: "absolute",
                  left: "80px",
                  top: "80px",
                }}
              >
                Terima kasih, <br />
                kami akan segera <br />
                menghubungi kamu
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </CustomDialog>
    </>
  );
};

export default GetInTouchForm;
