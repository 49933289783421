import { useState } from "react";
// MATERIAL UI
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
// COMPONENTS
import GetInTouchCard from "../GetInTouchCard";
import ConsultationDialog from "../ConsultationDialog";
// ICONS
import MailIllus from "../../../images/illustration/GetInTouchHome/MailIllus";
import WhatsappUs from "../../../images/illustration/GetInTouchHome/WhatsappUs";
import DiscussWithUsIllus from "../../../images/illustration/GetInTouchHome/DiscussWithUsIllus";
// CONSTANTS
import { DIVINE_WA_CHAT_LINK } from "../../../constant/utils/contentBody";
import { SALING_MENGHUBUNGI_TITLE } from "../../../constant/utils/contentBody";
import { paletteColorConstant } from "../../../constant/colorConstant";
import { fontPreset } from "../../../constant/fontConstant";

const style = {
    contactItem: {
        display: "flex",
        justifyContent: "center",
        columnGap: "140px",
    },
}

const ContactUsSection = () => {
    const theme = useTheme()
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

    const [openConsultationDialog, setOpenConsultationDialog] = useState(false);
    const handleOpenConsultationDialog = () => {
        setOpenConsultationDialog(true);
    };
    const handleCloseConsultationDialog = () => {
        setOpenConsultationDialog(false);
    };

    const onClickEmailUs = (e) => {
        e.preventDefault();
        window.open(`mailto:devinepro@gmail.com?subject=Kebutuhan Event Saya`);
    };
      
    const onCLickWhatsapp = (e) => {
        e.preventDefault();
        window.open(
          `${DIVINE_WA_CHAT_LINK}&text=Halo Saya Ingin Menanyakan Tentang Event Lebih Lanjut, Berikut Kebutuhan Event Saya :`
        );
    };
    
    return (
        <div>
            <Typography
                variant="h3"
                style={{ color: paletteColorConstant.black2, textAlign: "center" }}
                sx={
                downMediumScreen
                    ? { ...fontPreset.body20Med }
                    : { ...fontPreset.titleL }
                }
            >
                { SALING_MENGHUBUNGI_TITLE }
            </Typography>

            <div
                style={{
                    ...style.contactItem,
                    flexDirection: downMediumScreen ? "column" : "row",
                    alignItems: downMediumScreen ? "center" : "flex-start",
                    marginTop: downMediumScreen ? "40px" : "60px",
                    rowGap: "40px",
                }}
            >
                <GetInTouchCard
                    avatar={<MailIllus />}
                    onClickButton={onClickEmailUs}
                    buttonTitle="Email Kami"
                    description="Apakah kamu memiliki hal yang ingin ditanyakan seputar Divine?"
                />
                <GetInTouchCard
                    avatar={<WhatsappUs />}
                    onClickButton={onCLickWhatsapp}
                    buttonTitle="Whatsapp Kami"
                    description="Apakah kamu memiliki kebutuhan yang lebih spesifik atau personal?"
                />
                <GetInTouchCard
                    avatar={<DiscussWithUsIllus />}
                    buttonTitle="Diskusi Dengan Kami"
                    onClickButton={handleOpenConsultationDialog}
                    description="Ingin berdiskusi mengenai rencana event Anda?"
                />

                <ConsultationDialog
                    open={openConsultationDialog}
                    handleClose={handleCloseConsultationDialog}
                />
            </div>
        </div>
    )
}

export default ContactUsSection