import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Mousewheel, Navigation } from "swiper";
// MATERIAL ICONS
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// COMPONENTS
import ProjectCard from "./ProjectCard";
//MATERIAL UI
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
//CONSTANT
import { paletteColorConstant } from "../../constant/colorConstant";
import { caseStudy2 } from "../../constant/dummy";

const style = {
  nextPrevSwiper: {
    fontSize: "28px",
    padding: 0,
    transition: "color .25s ease",
    color: "#CACACA",
  },
  enableButton: {
    backgroundColor: "white",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "9px",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    opacity: 0,
    transition: "opacity .2s ease",
    "&:hover": {
      backgroundColor: paletteColorConstant.primaryBlue150,
      "& .icon__slider__pagination": {
        color: "#FFF",
      },
    },
  },
  disableButton: {
    backgroundColor: "#f9f9f9",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "9px",
    cursor: "auto",
  },
  prevButton: {
    position: "absolute",
    backgroundColor: "#004459",
    top: "42.5%",
    left: "12.5%",
    "&:hover": {
      color: "#FFFFFF",
    },
    zIndex: 10,
  },
  nextButton: {
    position: "absolute",
    top: "42.5%",
    right: "12.5%",
    zIndex: 10,
  },
};

const StyledBox = styled(Box)``;

const ProjectSlider = () => {
  const swiperRef = React.useRef(null);
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  return (
    <Box 
      sx={{ position: "relative", '&:hover': { '& .slider__pagination__button': { opacity: 1 } } }}
    >
      <Swiper
        ref={swiperRef}
        modules={[Navigation, Mousewheel]}
        navigation={{ prevEl, nextEl }}
        mousewheel={{ 
          forceToAxis: true,
          sensitivity: 0.1
        }}
        slidesPerView={"auto"}
        spaceBetween={26}
        // onSwiper={(swiper) => console.log(swiper)}
        style={{
          padding: "14px 12.5% 14px 12.5%",
          height: "100%",
          width: "100%",
          overflowX: "clip",
        }}
      >
        {caseStudy2.map((val, index) => (
          <SwiperSlide
            key={`article-${index}`}
            style={{ padding: "0", width: '468px', height: '319px' }}
          >
            <ProjectCard
              title={val.title}
              desc={val.desc}
              photoSrc={val.photo}
              details={val.details}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <StyledBox
        ref={(node) => setPrevEl(node)}
        sx={{ ...style.prevButton, ...style.enableButton }}
        className='slider__pagination__button'
      >
        <ArrowBackIosNewIcon 
          className="icon__slider__pagination"
          sx={style.nextPrevSwiper}
        />
      </StyledBox>
      <StyledBox
        ref={(node) => setNextEl(node)}
        sx={{ ...style.enableButton, ...style.nextButton }}
        className='slider__pagination__button'
      >
        <ArrowForwardIosIcon 
          className="icon__slider__pagination"
          sx={style.nextPrevSwiper}
        />
      </StyledBox>
    </Box>
  );
};

export default ProjectSlider;