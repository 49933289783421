import axios from 'axios';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    product: null,
    loading: false,
    success: false,
    error: false
}

export const getProduct = 
    createAsyncThunk(
        'product/getProduct',
        async ({ productId }, thunkAPI) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/productservice/${productId}`)
                return res.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error)
            }
        }
    )

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        resetProductDetail: (state) => {
            state.product = null
            state.loading = false
            state.success = false
            state.error = false
        },
    },
    extraReducers: {
        [getProduct.pending]: (state) => {
            state.loading = true
            state.product = null
            state.success = false
            state.error = false
        },
        [getProduct.fulfilled]: (state, action) => {
            state.product = action.payload
            state.success = true
            state.loading = false
            state.error = false
        },
        [getProduct.rejected]: (state, action) => {
            state.error = action.payload
            state.product = null
            state.loading = false
            state.success = false
        }
    }
})

export const { resetProductDetail } = productSlice.actions
export default productSlice.reducer