import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    testimoniItems: [],
    loading: false,
    success: false,
    error: false
}

export const getAllTestimoni = 
    createAsyncThunk(
        'testimoni/getAllTestimoni',
        async (_, thunkAPI) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/testimoni/v1/getTestimoni`)
                return res.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error)
            }
        }
    )

const testimoniSlice = createSlice({
    name: 'testimoni',
    initialState,
    extraReducers: {
        [getAllTestimoni.pending]: (state) => {
            state.loading = true
        },
        [getAllTestimoni.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.testimoniItems = action.payload
        },
        [getAllTestimoni.rejected]: (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        }
    }
})

// export const { clearCart } = testimoniSlice.actions
export default testimoniSlice.reducer