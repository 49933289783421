import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
// MUI
import { Box, FormControlLabel, Pagination, RadioGroup, Tab, Typography, useMediaQuery, useTheme } from '@mui/material'
// ICONS
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
// import SearchIcon from '@mui/icons-material/Search'
// COMPONENTS
import RadioButtonCustom from '../common/RadioButtonCustom'
import ArticleCardHorizontal from './ArticleCardHorizontal'
import { DateCard } from '../ProductAndServices/FilterCard'
import ArticleLoading from './ArticleLoading'
import ArticleListEmpty from './ArticleListEmpty'
// CONSTANTS
import { paletteColorConstant } from '../../constant/colorConstant'
import { fontPreset } from '../../constant/fontConstant'
import { CustomTab } from '../../constant/elementConstant'
import { scrollToContent, stylePreset } from '../../constant/stylePreset'
import { boxShadowStyle } from '../../constant/shadowConstant'
// import { CustomTextFieldTransparant } from '../../constant/textFieldConstant'
// ACTIONS
import { getAllArticles } from '../../features/article/articleSlice'

const style = {
    section_two: {
        display: "flex",
        flexDirection: "column",
        rowGap: "60px",
    },
    filterLabel: {
        fontWeight: fontPreset.body20Lig,
        color: "#959595",
        margin: "1rem 1rem 0 1rem",
    },
}

// const categories = ["Tips", "Equipment"]
const categories = [
    { params: "tips and equipment", value: "Tips", label: "Tips" },
    { params: "equipment", value: "Equipment", label: "Peralatan" },
]
const categoriesParams = [
    { params: "tips and equipment", value: "Tips", label: "Tips" },
    { params: "equipment", value: "Equipment", label: "Peralatan" },
]

// const filterList = ["Terlama", "Terbaru"]
const filterList = [
    { value: "Earlier", filter: "by=createdAt&sort=asc", label: "Terlama" },
    { value: "Latest", filter: "by=createdAt&sort=desc", label: "Terbaru" },
]

let filterURL = []
let pageURL = []
let categoryURL = ""

const ArticleList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const theme = useTheme()
    const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

    const [page, setPage] = useState(1)
    const [openFilter, setOpenFilter] = useState(false)
    const [value, setValue] = useState("Tips")
  
    const [filterVal, setFilterVal] = useState("Latest")

    const articleList = useSelector((state) => state.articles)
    const { loading, success, articles, error } = articleList

    useEffect(() => {
        if(articleList.loading === false && location.search === "") dispatch(getAllArticles({}))
        if(location.search !== "") {
            filterURL = []
            pageURL = []
            categoryURL = ""

            // console.log(location.search)
            const url = location.search.slice(1).split("&")
            scrollToContent("other__articles__section", { duration: 400, smooth: false, offset: -82 })

            url.forEach((searchLink) => {
                if(searchLink.includes("sort") || searchLink.includes("by")) {
                    filterURL.push(searchLink)
                }
                if(searchLink.includes("page")) {
                    pageURL.push(searchLink)
                    setPage(parseInt(searchLink.split("=")[1]))
                }
                if(searchLink.includes("category")) {
                    categoryURL = searchLink
                }
            })

            dispatch(getAllArticles({ sort: filterURL, page: pageURL, category: categoryURL }))
        }
    }, [location])

    const printUpdateURL = (resetPage = false) => {
        let url = []
        
        if(resetPage) { url = [categoryURL, ...filterURL] }
        else url = [categoryURL, ...filterURL, ...pageURL]
        
        return `/articles?${url.join("&")}`
    }

    const handleOnchange = (event, newValue) => { 
        setValue(newValue)

        const search = categoriesParams.find((category) => category.value === newValue)
        categoryURL = `category=${search.params}`
        navigate(printUpdateURL(true))
    };

    const handleOnchangePage = (event, newValue) => { 
        setPage(newValue)
        
        pageURL = []
        pageURL.push(`page=${newValue}`)
        
        navigate(printUpdateURL())
    };

    return (
        <Box className="other__articles__section" 
            sx={{ ...style.section_two, rowGap: downMediumScreen && "20px" }}
        >
        <Box>
            <Typography
                component={"h1"}
                sx={{ ...fontPreset.titleL, color: paletteColorConstant.black2 }}
                align={"center"}
            >
                Artikel Lainnya
            </Typography>
        </Box>

        <div className="section__articles"
            style={{ display: "flex", justifyContent: "center" }}
        >
            <Box className="search__articles__box"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: downMediumScreen ? "40px" : "60px",
                    width: "100%",
                    marginTop: downMediumScreen ? "40px" : "60px"
                }}
            >
                {/* <Box className="articles__search__desktop"
                    sx={{ display: "flex" }}
                >
                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ width: "970px", display: "flex" }}>
                        <CustomTextFieldTransparant
                            placeholder="Cari"
                            variant="outlined"
                            size="medium"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => { }}
                                    >
                                        <SearchIcon height={18} width={18} />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                    </Box>
                </Box> */}

                <Box className="articles__section"
                    sx={{ display: "flex", justifyContent: "center", columnGap: "24px" }}
                >
                    <Box className="filter__section"
                        sx={{ display: downMediumScreen ? "none" : "flex", flexDirection: "column", rowGap: "20px" }}
                    >
                        <label style={style.filterLabel}>
                            Filter
                        </label>

                        { !downMediumScreen && <DateCard type={categoryURL} /> }
                    </Box>

                    <Box className="articles__root"
                        sx={{ display: "flex", flexDirection: "column", width: downMediumScreen ? "100%" : "945px", position: "relative" }}
                    >
                        <Box className="filter__box__mobile"
                            sx={{ 
                                display: "flex",
                                justifyContent: "space-between",
                                columnGap: "22px",
                                position: downMediumScreen && "absolute",
                                left: 0,
                                zIndex: downMediumScreen && 100
                            }}
                        >
                            { downMediumScreen &&
                                <Box className="filter__box__mobile__content"
                                    sx={{ ...boxShadowStyle.generalShadow, borderRadius: "12px", padding: "12px", backgroundColor: "white" }}
                                >
                                    <Box className="filter__box__title"
                                        sx={{ display: "flex", columnGap: "18px", color: paletteColorConstant.gray1, "&:hover": { cursor: "pointer" } }}
                                        onClick={() => { setOpenFilter(!openFilter) }}
                                    >
                                        <Typography sx={{ ...fontPreset.body16Lig }}>
                                            Filter
                                        </Typography>

                                        <KeyboardArrowUpIcon sx={{ transform: !openFilter && 'rotate(180deg)', transition: "transform .1s ease" }} />
                                    </Box>

                                    { openFilter &&
                                        <>
                                            <Box sx={{ display: "flex", justifyContent: "flex-start", marginTop: "18px" }}>
                                                Tanggal
                                            </Box>
                                            
                                            <RadioGroup
                                                value={filterVal}
                                                onChange={(e) => {
                                                    const newRadioVal = e.target.value
                                                    setFilterVal(newRadioVal)

                                                    const search = filterList.find((option) => option.value === newRadioVal)
                                                    navigate(`/articles?${search.filter ?? ""}${categoryURL !== "" ? `&${categoryURL}` : ""}`)
                                                }}
                                                sx={{ marginTop: "4px" }}
                                            >
                                                { filterList.map((filterItem, index) => (
                                                    <FormControlLabel
                                                        key={`option-${index + 1}`}
                                                        value={filterItem.value}
                                                        label={filterItem.label}
                                                        control={<RadioButtonCustom size="small" />}
                                                        sx={{
                                                            "& .MuiFormControlLabel-label": {
                                                                ...fontPreset.body14Lig,
                                                                color: filterVal === filterItem.label && paletteColorConstant.primaryBlue150,
                                                                transition: "color 100ms ease-in",
                                                            },
                                                            margin: "0"
                                                        }}
                                                    />
                                                ))
                                                }
                                            </RadioGroup>
                                        </>
                                    }
                                </Box>
                            }

                            <Box className="category__tab__articles">
                                <CustomTab 
                                    value={value}
                                    onChange={handleOnchange}
                                    variant="scrollable"
                                    scrollButtons={false}
                                >
                                    {categories.map((category) => (
                                        <Tab
                                            key={`key-${category.label}`}
                                            sx={{ 
                                                padding: "0",
                                                minWidth: "0px",
                                                width: "content-fit",
                                                textTransform: "none",
                                            }}
                                            style={ downMediumScreen ? fontPreset.body18Lig : fontPreset.body20Lig }
                                            value={category.value}
                                            label={category.label}
                                        />
                                    ))}
                                </CustomTab>
                            </Box>
                        </Box>
                    
                        <Box className="articles__list"
                            sx={{ marginTop: downMediumScreen ? "80px" : "40px", ...stylePreset.columnFlex, rowGap: downMediumScreen ? "32px" : "60px" }}
                        >
                            {   loading ? 
                                    <ArticleLoading /> :
                                success ?
                                    articles.data.length === 0 ?
                                        // KOSONG
                                            <ArticleListEmpty /> :
                                        // TIDAK KOSONG 
                                            articles.data.map((article, index) => (
                                                <ArticleCardHorizontal 
                                                    key={`articles-${index + 1}`}
                                                    data={article}
                                                />
                                            )) :
                                error ?
                                    <Box>Error...</Box> :
                                null
                            }

                            <div className="article__pagination"
                                style={{ display: "flex", justifyContent: downMediumScreen ? "center" : "flex-end" }}
                            >
                            <Pagination
                                count={articles?.pagination?.lastPage ?? 0}
                                sx={{
                                    "& .Mui-selected": {
                                        minWidth: "0",
                                        borderBottom: `1px solid ${paletteColorConstant.primaryBlue150}`,
                                        backgroundColor: "transparent",
                                        borderRadius: "0px",
                                        color: paletteColorConstant.primaryBlue150,
                                    },
                                    "& .MuiPaginationItem-root": {
                                        "&.Mui-selected": {
                                        background: "transparent",
                                        },
                                    },
                                }}
                                shape="rounded"
                                page={page}
                                onChange={handleOnchangePage}
                                variant="text"
                            />
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
        </Box>
    )
}

export default ArticleList