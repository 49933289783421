import axios from 'axios';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    consultation: null,
    loading: false,
    error: false,
    success: false,
    start: true
}

export const postConsultation = createAsyncThunk('testimoni/postConsultation', async (postData, thunkAPI) => {

    const data = {
        "name" : postData.name,
        "contactChanel" : postData.contactChanel,
        "contact" : postData.contact,
        "agencyType" : postData.agencyType,
        "agencyName" : postData.agencyName,
        "eventType" : postData.eventType,
        "numberOfParticipants" : postData.numberOfParticipants,
        "description" : postData.description,
        "eventDate" : postData.eventDate,
        "eventLocation" : postData.eventLocation,
        "budgetEstimation" : parseInt(postData.budgetEstimation)
    }

    const config = {
        headers: { "Content-Type": "application/json" }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_PRODUCTION}/api/consultationForm/v1/createConsultatioForm`, data, config)
        return res.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

const consultationSlice = createSlice({
    name: 'consultation',
    initialState,
    reducers: {
        resetConsultation: (state) => {
            state.consultation = null
            state.error = false
            state.loading = false
            state.success = false
            state.start = true
        },
        startToFalse: (state) => {
            state.start = false
        },
    },
    extraReducers: {
        [postConsultation.pending]: (state) => {
            state.loading = true
            state.consultation = null
            state.error = false
            state.success = false
            state.start = false
        },
        [postConsultation.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.consultation = action.payload
            state.start = false
        },
        [postConsultation.rejected]: (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
            state.start = false
        }
    }
})

export const { resetConsultation, startToFalse } = consultationSlice.actions
export default consultationSlice.reducer