import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../screen/style/home.css";
// MATERIAL UI
import { Box, Typography } from '@mui/material'
// CONSTANT
import { fontStyleConstant } from '../../constant/fontConstant'
// COMPONENTS
import CaseStudyDetails from '../Home/CaseStudyDetails'

const ProjectCard = ({ 
    title = "Natal Bersama Keluarga Kemenkeu",
    desc = "Pelaksanaannya sangat baik, detail, dan rapih. Divine Pro sangat saya rekomendasikan!",
    photoSrc,
    details
 }) => {
  return (
    <Box className="img-wrapper">
        <LazyLoadImage
            className="section-four-image zoom"
            src={photoSrc}
            alt={"portofolio-img-2"}
        />
        <Box className="content fade">
            <Typography
                variant="h6"
                style={{ ...fontStyleConstant.body3SemiBold, lineHeight: "24.2px", color: "white" }}
                align={"center"}
            >
                { title }
            </Typography>
            <Typography
                variant="body1"
                sx={{ marginTop: "12px", lineHeight: "19.36px" }}
                align={"center"}
            >
                { desc }
            </Typography>

            <CaseStudyDetails
                images={details.photoList}
                title={title}
                quotes={details.quotes}
                about={details.about}
                provides={details.provides}
            />
        </Box>
    </Box>
  )
}

export default ProjectCard