import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Helmet } from "react-helmet-async"
// MATERIAL UI
import { Box, Container, Typography, useTheme, useMediaQuery } from "@mui/material"
// COMPONENTS
import ArticleCardHorizontal from "../components/Articles/ArticleCardHorizontal"
import ArticleList from "../components/Articles/ArticleList"
import ArticleCardSkeleton from "../components/Articles/ArticleCardSkeleton"
// CONSTANT
import { stylePreset } from "../constant/stylePreset"
import { fontPreset, mobileFontPreset } from "../constant/fontConstant"
import { paletteColorConstant } from "../constant/colorConstant"
// SLICE
import { getHighlightedArticle } from "../features/article/articleHighlightSlice"

const style = {
  root: {
    padding: "100px 0",
  },
  container: {
    ...stylePreset.columnFlex,
    rowGap: "100px",
  },
  section_one: {
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "40px",
  },
}

const Articles = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const highlightedArticle = useSelector((state) => state.highlightedArticle)

  useEffect(() => {
    dispatch(getHighlightedArticle())
  
    return () => {}
  }, [])

  return (
    <>
      <Helmet>
          <title>Artikel | Divine Pro</title>
          <meta name="description" content="Kumpulan Artikel Divine Pro" />

          <meta property="og:title" content="Artikel | Divine Pro" />
          <meta property="og:description" content="Kumpulan Artikel Divine Pro" />
          <meta property="og:site_name" content="Divine Pro" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content="/divine-logo.png" />
      </Helmet>

      <Box className="articles__root"
        sx={{ ...style.root, padding: downMediumScreen ? "60px 0" : "100px 0", marginTop: "92px" }}
      >
        <Container sx={style.container}>
          <Box className="highlight__and__most__read"
            sx={style.section_one}
          >
            <Box className="highlight__section" 
              sx={{ display: "flex", flexDirection: downMediumScreen ? "column" : "row", rowGap: "20px" }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  component={"h1"}
                  sx={{ color: paletteColorConstant.black2 }}
                  style={downMediumScreen ? mobileFontPreset.titleXL : fontPreset.titleL }
                  align={downMediumScreen ? "center" : "left"}
                >
                  Highlight
                </Typography>
              </Box>

              <Box
                className="highlight__card"
                sx={{ flex: 5, ...stylePreset.columnFlex, rowGap: "60px" }}
              >
                { highlightedArticle.loading ?
                      <ArticleCardSkeleton /> :
                  highlightedArticle.error ?
                      <Box>Error....</Box> :
                  highlightedArticle.success ?
                      highlightedArticle.success?.data?.length !== 0 ?
                        <ArticleCardHorizontal data={highlightedArticle.article.data[0]} /> :
                      // RESULT 0 
                        <Box>Artikel Tidak Ditemukan....</Box> :
                  null
                }
              </Box>
            </Box>

            <Box className="most__read__section"
              sx={{ display: "flex", flexDirection: downMediumScreen ? "column" : "row", rowGap: "20px" }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  component={"h1"}
                  sx={{ color: paletteColorConstant.black2 }}
                  style={downMediumScreen ? mobileFontPreset.titleXL : fontPreset.titleL }
                  align={downMediumScreen ? "center" : "left"}
                >
                  Most Read
                </Typography>
              </Box>

              <Box className="most__read__card"
                sx={{ flex: 5, ...stylePreset.columnFlex, rowGap: "60px" }}
              >
                <ArticleCardHorizontal dummy={true} />
              </Box>
            </Box>
          </Box>
          
          <ArticleList />
        </Container>
      </Box>
    </>
  );
};

export default Articles;
