import { LazyLoadImage } from 'react-lazy-load-image-component';
// MATERIAL UI
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
// CONSTANT
import { fontPreset } from "../../constant/fontConstant";
import { boxShadowStyle } from "../../constant/shadowConstant";
import { articleDetailDummy } from "../../constant/dummy";
import { monthList } from "../../constant/dateConstant";
// COMPONENTS
import ArticleDetailButton from "../Articles/ArticleDetailButton";
import { paletteColor } from '../../constant/colorConstant';

const style = {
  card: {
    borderRadius: "12px",
    // width: "275px",
    maxWidth: "275px",
    "&:hover": { ...boxShadowStyle.generalShadow },
  },
  content: {
    padding: "0.75rem",
  },
};

const ArticleCard = ({ imageTitle, data }) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const printDate = (date) => {
    const input = new Date(date)
    const month = monthList[input.getMonth()]
    return `${month}, ${input.getDate()} ${input.getFullYear()}`
  }

  return (
    <article className="article__card">
      <Box sx={{ ...style.card, boxShadow: downMediumScreen ? boxShadowStyle.generalShadow.boxShadow : "none" }}>
        <Box className="header__card" height={"43%"}>
          <LazyLoadImage
            src={data.photo ?? articleDetailDummy.cover}
            alt={imageTitle}
            width={"100%"}
            height={"138px"}
            style={{ borderRadius: "12px 12px 0 0", objectFit: "cover" }}
          />
        </Box>

        <Box sx={style.content}>
          <Box className="content__card" sx={{ margin: downMediumScreen ? "0 0 0 0" : "20px 20px 0 20px" }}>
            <Box sx={{ height: downMediumScreen ? "100%" : "72px" }}>
              <Typography
                component="h6"
                sx={{
                  marginBottom: "4px",
                  lineHeight: downMediumScreen ? "normal" : "23.44px",
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                  color: paletteColor.primaryGray
                }}
                style={downMediumScreen ? fontPreset.body14Med : fontPreset.titleS}
              >
                { data.title ?? articleDetailDummy.title }
              </Typography>
            </Box>
            
            <Typography
              component="p"
              sx={downMediumScreen ? fontPreset.body8Lig : fontPreset.body12Lig}
              style={{ color: paletteColor.black75 }}
            >
              { printDate(data?.createdAt ?? articleDetailDummy.createdAt) }
            </Typography>
          </Box>

          <Box className="action__card" style={{ marginTop: downMediumScreen ? "14px" : "20px" }}>
            <ArticleDetailButton 
              articleId={data?.title.split(" ").join("-") ?? articleDetailDummy.title.split(" ").join("-") ?? "dummy"}
              buttonFullWidth
            />
          </Box>
        </Box>
      </Box>
    </article>
  );
};

export default ArticleCard;
