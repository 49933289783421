import React, { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "../screen/style/PortfolioSwiper.css";
// import required modules
import { Autoplay, Mousewheel, Pagination } from "swiper";
// MATERIAL UI
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
// CONSTANT
import { paletteColor, paletteColorConstant } from "../constant/colorConstant";
import { fontPreset, fontStyleConstant, mobileFontPreset } from "../constant/fontConstant";
import { boxShadowStyle } from "../constant/shadowConstant";
import { stylePreset } from "../constant/stylePreset";
import { caseStudy, caseStudy2 } from "../constant/dummy";
// COMPONENTS
import PortFolioItem from "../components/Portfolio/PortFolioItem";
import ImageAndDesc from "../components/Portfolio/ImageAndDesc";
import ProjectSlider from "../components/Portfolio/ProjectSlider";
import ClientGridList from "../components/Portfolio/ClientGridList";
import DocumentIconFill from "../components/icons/DocumentIconFill";
import FilesIconFill from "../components/icons/FilesIconFill";
import ConsultationDialogButton from "../components/Home/ConsultationDialogButton";
import CaseStudyDetails from "../components/Home/CaseStudyDetails";
import { Helmet } from "react-helmet-async";

const style = {
  sectionOne: {
    background: `linear-gradient(180deg, ${paletteColor.black150} 11.98%, ${paletteColor.primaryGray} 89.06%)`,
    display: "flex",
    flexDirection: "column",
  },
  prevButton: {
    position: "absolute",
    top: "33%",
    left: "calc((100% - 1152px) / 2)",
    "&:hover": {
      color: "#FFFFFF",
    },
    zIndex: 10,
  },
  nextButton: {
    position: "absolute",
    top: "33%",
    right: "calc((100% - 1152px) / 2)",
    zIndex: 10,
  },
  enableButton: {
    backgroundColor: "white",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "9px",
    cursor: "pointer",
    opacity: 0,
    transition: "opacity .2s ease",
    ...boxShadowStyle.generalShadow,
    "&:hover": {
      backgroundColor: paletteColorConstant.primaryBlue150,
      "& .icon__slider__pagination": {
        color: "#FFF",
      },
    },
  },
  contentSection: {
    display: "flex",
    flexDirection: "column",
  },
  sectionThreeTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sectionThreeRowTwo: {
    display: "flex",
    justifyContent: "center",
    columnGap: "62px",
    rowGap: "20px"
  }
};

const Portfolio = () => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [heroPagination, setHeroPagination] = useState(null);

  const swiperRef = React.useRef(null);

  return (
    <>
      <Helmet>
          <title>Portfolio Kami</title>
          <meta name="description" content="Portfolio Event dari Divine Pro" />

          <meta property="og:title" content="Portfolio Kami" />
          <meta property="og:description" content="Portfolio Event dari Divine Pro" />
          <meta property="og:site_name" content="Divine Pro" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content="/divine-logo.png" />
      </Helmet>

      <Box className="portfolio__root">
        <Box className="section__one" 
          sx={{ ...style.sectionOne, 
            padding: downMediumScreen ? "40px 0" : "60px 0",
            rowGap: downMediumScreen ? "40px" : "60px",
            height: "80vh",
            maxHeight: "683px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <Box>
            <Container className="title__section">
              <Typography
                component="h1"
                sx={downMediumScreen ? mobileFontPreset.titleXL : fontStyleConstant.header1}
                style={{ color: paletteColorConstant.white2 }}
                align="center"
              >
                Telah Bekerja Sama dengan
              </Typography>
            </Container>

            <Box className="content__section"
              sx={{ 
                position: "relative",
                "&:hover": { "& .slider__pagination__button": { opacity: 1 } },
                marginTop: "60px"
              }}
            >
              <Swiper
                ref={swiperRef}
                modules={[Pagination, Autoplay, Mousewheel]}
                pagination={{ el: heroPagination, clickable: true }}
                mousewheel={{ forceToAxis: true, sensitivity: 0.85 }}
                slidesPerView={1}
                slidesPerGroup={1}
                style={{ width: "100%", height: "100%", marginLeft: "auto", marginRight: "auto" }}
                autoplay={{ delay: 3000 }}
              >
                <SwiperSlide className={"slide__portfolio"}>
                  <ClientGridList clientList={[22,2,3,4,5,6,7,8,9,10]} />
                </SwiperSlide>
                <SwiperSlide className={"slide__portfolio"}>
                  <ClientGridList clientList={[11,12,13,14,15,16,17,18,19,20]} />
                </SwiperSlide>
                <SwiperSlide className={"slide__portfolio"}>
                  <ClientGridList clientList={[21,1,23,24,25,26,27,28,29,30]} />
                </SwiperSlide>
              </Swiper>
            </Box>
          </Box>
        </Box>

        <Box className="section__two" 
          sx={{ padding: downMediumScreen ? "40px 0" : "80px 0", overflowX: "hidden" }}
        >
          <Container>
            <Typography
              className="title__section"
              component="h1"
              align="center"
              sx={downMediumScreen ? fontPreset.body24Med : fontPreset.titleL}
              style={{ color: paletteColorConstant.black2 }}
            >
              Project Terbaru Kami
            </Typography>

            <Box className="content__section"
              sx={{ ...style.contentSection,
                rowGap: downMediumScreen ? "60px" : "140px",
                marginTop: downMediumScreen ? "20px" : "60px"
              }}
            >
              { caseStudy.map((val, index) => (
                  <PortFolioItem
                    key={`portfolio-${index + 1}`}
                    title={val.title}
                    body={val.desc}
                    imgSrc={val.photo}
                    dataAos={index % 2 === 0 ? "fade-right" : "fade-left"}
                    reverse={index % 2 !== 0}
                    details={val.details}
                  />
                ))
              }
              
            </Box>
          </Container>
        </Box>

        <Box className="section__three"
          sx={{ backgroundColor: paletteColorConstant.black1, padding: "60px 0" }}
        >
          <Container
            sx={{ ...stylePreset.columnFlex, rowGap: downMediumScreen ? "60px" : "120px" }}
          >
            <Box className="section__three__title"
              width={"100%"}
              sx={style.sectionThreeTitle}
            >
              <Typography
                variant="h1"
                sx={downMediumScreen ? mobileFontPreset.titleXL : fontPreset.titleXM}
                style={{ color: paletteColorConstant.white2 }}
              >
                Bagaimana kami membantu kamu?
              </Typography>
            </Box>

            <Box className="row__one">
              <Box className="row__one__content"
                sx={{ 
                  display: "flex",
                  flexDirection: downMediumScreen ? "column" : "row",
                  justifyContent: "space-between",
                  rowGap: "40px"
                }}
              >
                <ImageAndDesc
                  body={
                    <>
                      <strong>PERTAMA</strong>, ceritakan event Anda kepada kami
                    </>
                  }
                  icons={
                    <DocumentIconFill
                      width={downMediumScreen ? 80 : 100}
                      height={downMediumScreen ? 80 : 100}
                    />
                  }
                />

                <ImageAndDesc
                  body={
                    <>
                      <strong>KEDUA</strong>, kami berikan yang terbaik untuk kebutuhan Anda
                    </>
                  }
                  icons={
                    <FilesIconFill 
                      width={downMediumScreen ? 80 : 100}
                      height={downMediumScreen ? 80 : 100}
                    />
                  }
                />
              </Box>
            </Box>

            <Box className="row__two"
              sx={{ ...style.sectionThreeRowTwo, flexDirection: downMediumScreen ? "column" : "row", padding: "0 20px" }}
            >
              <Box sx={{ maxWidth: "840px" }}>
                <Typography
                  variant="body1"
                  sx={downMediumScreen ? mobileFontPreset.titleM : fontPreset.titleL}
                  style={{ color: paletteColorConstant.white2 }}
                  align={downMediumScreen ? "center" : "left"}
                >
                  Akhirnya, kami akan membantu Anda untuk membuat semuanya berjalan dengan baik
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ConsultationDialogButton
                  buttonTitle="Mari Diskusikan"
                  sxProps={{ color: paletteColorConstant.white1 }}
                />
              </Box>
            </Box>
          </Container>
        </Box>

        <Box className="section__four" 
          sx={{ padding: downMediumScreen ? "60px 0 0 0" : "80px 0" }}
        >
          <Container className="section__four__title"
            sx={{ marginBottom: downMediumScreen ? "40px" : "46px" }}
          >
            <Typography
              variant="h3"
              sx={downMediumScreen ? fontPreset.body24Med : fontPreset.titleL}
              style={{ color: paletteColorConstant.black2 }}
              align="center"
            >
              Projek Lainnya
            </Typography>
          </Container>

          <Box className="card__slider__desktop" 
            sx={{ margin: "0 0 0 auto", display: downMediumScreen ? "none" : "block" }}
          >
            <ProjectSlider />
          </Box>

          <Box className="portfolio__mobile"
            sx={{ display: downMediumScreen ? "block" : "none" }}
          >
            <Grid container className="case__study__home">
              {caseStudy2.map((val, index) => (
                <Grid item key={`case-study-${index + 1}`} xs={6}>
                  <div
                    className="img-wrapper"
                    style={{ height: downMediumScreen ? "200px" : "100%" }}
                  >
                    <LazyLoadImage
                      className="section-four-image zoom"
                      src={val.photo}
                      alt={`portofolio-img-${index + 2}`}
                    />
                    <div
                      className={`content fade`}
                      style={{ padding: downMediumScreen ? "0 20px" : "0 96px" }}
                    >
                      <Typography
                        variant="h6"
                        style={
                          downMediumScreen
                            ? { ...mobileFontPreset.titleM, lineHeight: "21.09px" }
                            : { ...fontStyleConstant.body3SemiBold, lineHeight: "24.2px" }
                        }
                        sx={{ color: "white" }}
                        align={"center"}
                      >
                        {val.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ display: downMediumScreen ? "none" : "block", marginTop: "12px", lineHeight: "19.36px" }}
                        align={"center"}
                      >
                        {val.desc}
                      </Typography>

                      <CaseStudyDetails
                        title={val.title}
                        about={val.details.about}
                        images={val.details.photoList}
                        quotes={val.details.quotes}
                        provides={val.details.provides}
                      />
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Portfolio;
