import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// MATERIAL UI
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
// CONSTANTS
import { fontStyleConstant, mobileFontPreset } from "../../../constant/fontConstant";
import { paletteColorConstant } from "../../../constant/colorConstant";
import { caseStudy } from "../../../constant/dummy";
// COMPONENTS
import CaseStudyDetails from "../../../components/Home/CaseStudyDetails";


const PortfolioSection = () => {
    const theme = useTheme();
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const downSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const downSmallScreen2 = useMediaQuery(theme.breakpoints.down("sm2"));

    return (
        <Grid container style={{ display: "flex" }}>
            <Grid item xs={12} sm={4} className="portfolio__home">
                <div
                    className="img-wrapper"
                    style={{ maxHeight: downSmallScreen ? "240px" : "100%" }}
                >
                    <LazyLoadImage
                        className="section-four-image zoom"
                        src={"/images/home/section_4/event_img_big.png"}
                        alt={"portofolio-img"}
                        style={{ objectPosition: downMediumScreen && "50% 10%" }}
                    />

                    <div className={`content fade`}
                        style={{ padding: downSmallScreen2 ? "0 20px" : downMediumScreen ? "0 58px" : "0 96px" }}
                    >
                        <Typography
                            variant="h6"
                            style={
                                downMediumScreen
                                ? { ...mobileFontPreset.titleM, lineHeight: "21.09px", color: "white" }
                                : { ...fontStyleConstant.body3SemiBold, lineHeight: "24.2px", color: "white" }
                            }
                        >
                            Portofolio
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{ marginTop: "12px" }}
                            style={
                                downMediumScreen
                                ? { ...mobileFontPreset.body14Lig, lineHeight: "16.41px" }
                                : { lineHeight: "19.36px" }
                            }
                            align={"center"}
                        >
                            Kami memiliki pengalaman dalam berbagai macam event. Kamu bisa melihat event-event yang kami tangani lebih banyak di sini.
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ marginTop: "20px", color: paletteColorConstant.white1 }}
                            to={"/portfolio"}
                            component={Link}
                        >
                            Portofolio Kami
                        </Button>
                    </div>
                </div>
            </Grid>

            <Grid item xs={12} sm={8}>
                <Grid container className="case__study__home">
                    {caseStudy.map((val, index) => (
                        <Grid item key={`case-study-${index + 1}`} xs={6}>
                            <div className="img-wrapper"
                                style={{ height: downSmallScreen ? "200px" : "100%" }}
                            >
                                <LazyLoadImage
                                    className="section-four-image zoom"
                                    src={val.photo}
                                    alt={`portofolio-img-${index + 2}`}
                                />

                                <div className={`content fade`}
                                    style={{ padding: downMediumScreen ? "0 20px" : "0 78px" }}
                                >
                                    <Typography
                                        variant="h6"
                                        style={
                                        downMediumScreen
                                            ? { ...mobileFontPreset.titleM, lineHeight: "21.09px", color: "white" }
                                            : { ...fontStyleConstant.body3SemiBold, lineHeight: "24.2px", color: "white" }
                                        }
                                        align={"center"}
                                    >
                                        {val.title}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ display: downMediumScreen ? "none" : "block", marginTop: "12px", lineHeight: "19.36px" }}
                                        align={"center"}
                                    >
                                        {val.desc}
                                    </Typography>

                                    <CaseStudyDetails
                                        images={val.details.photoList}
                                        title={val.title}
                                        about={val.details.about}
                                        quotes={val.details.quotes}
                                        provides={val.details.provides}
                                    />
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PortfolioSection