import ClientDataStep from "./ClientDataStep"
import ConfirmationDataStep from "./ConfirmationDataStep"
import EventDateStep from "./EventDateStep"
import EventTypeDataStep from "./EventTypeDataStep"

const ConsultationPerStep = ({ step }) => {
    if(step === 0) {
        return (
            <ClientDataStep />
        )
    }

    if(step === 1) {
        return (
            <EventTypeDataStep />
        )
    }

    if(step === 2) {
        return (
            <EventDateStep />
        )
    }

    if(step === 3) {
        return (
            <ConfirmationDataStep />
        )
    }

    return (
        <div>Something went wrong.</div>
    )
}

export default ConsultationPerStep