// GENERAL
// NO TELPON, ALAMAT, LOKASI
export const NOMOR_TELEPON_DIVINE = "0813-8899-8430"
export const ALAMAT_DIVINE = "Jl. Mertilang Raya JE8 No. 23, Pd. Pucung, Kec. Pd. Aren, Kota Tangerang Selatan, Banten 15229"
export const LOKASI_MAPS_DIVINE = "https://goo.gl/maps/qKjojcYD126CtnLA6"

// SOCIAL MEDIA DIVINE
// EMBED YOUTUBE LINK (di Halaman About Us)
export const EMBED_YOUTUBE_LINK = "https://www.youtube.com/embed/GS1aT8P0ks4"
// YOUTUBE CHANNEL DIVINE
export const DIVINE_YOUTUBE_LINK = "https://www.youtube.com/channel/UCJifhY6_7wdhO8M_n1D4OWw"
export const DIVINE_IG_LINK = "https://www.instagram.com/divinepro.id/"
export const DIVINE_LINKEDIN_LINK = "https://id.linkedin.com/company/divine-production"

// LINK WA
export const DIVINE_WA_CHAT_LINK = "https://api.whatsapp.com/send?phone=+6281288998430"

// ======= HALAMAN HOME ========
// SECTION 1 (BANNER DAN HEADER)
export const TITLE_BANNER_LINE_1 = "Buat Acara Anda"
export const TITLE_BANNER_LINE_2 = "Menjadi Momen yang"
export const TITLE_BANNER_LINE_3 = "Tak Terlupakan!"
export const BODY_BANNER = "Ceritakan bagaimana Anda ingin menggelar suatu acara, dan tim kami akan memberikan saran terbaik agar acara Anda menjadi momen tak terlupakan bagi setiap audiens yang hadir."

// SECTION 3
// (LAYANAN KAMI)
export const TITLE_KIRI = "Layanan Kami"
export const BODY_KIRI = "Kami dapat membantu Anda untuk merencanakan event tanpa menyita waktu dengan paket peralatan dan tim yang disesuaikan dengan kebutuhan Anda."
export const TITLE_TOMBOL_KIRI = "Cek Penawaran Kami"

// PERALATAN EVENT & TAMBAHAN KRU
export const LAYANAN_LIST = [
    {
        title: "Peralatan Event",
        body: "Kami menyediakan berbagai alat kebutuhan untuk acara Anda. Cari alat yang Anda butuhkan di sini.",
        gambar: "/images/home/section_3/equipment.png",
        title_tombol: "Sewa Peralatan",
        // NAVIGASI (SETELAH KLIK TOMBOL AKAN DIARAHKAN KE URL TERTENTU)
        navigasi: "/product-services?category=equipment",
    },
    {
        title: "Tambahan Kru",
        body: "Anda membutuhkan kru yang profesional? Tidak perlu khawatir, kami selalu siap membantu.",
        gambar: "/images/home/section_3/manpower.png",
        title_tombol: "Sewa Kru",
        // NAVIGASI (SETELAH KLIK TOMBOL AKAN DIARAHKAN KE URL TERTENTU)
        navigasi: "/product-services?category=manpower",
    },
]

// SECTION 5
// TENTANG KAMI
export const TENTANG_KAMI_TITLE = "Tentang Kami"
export const TENTANG_KAMI_BODY = "Tim kami komunikatif, kreatif, inovatif, dan terpercaya untuk menangani beragam jenis event."
export const TENTANG_KAMI_TITLE_TOMBOL = "Lihat Selengkapnya"

// SECTION 7
// MARI SALING MENGHUBUNGI
export const SALING_MENGHUBUNGI_TITLE = "Mari Saling Menghubungi"
// 3 OPSI SALING MENGHUBUNGI
export const SALING_MENGHUBUNGI_LIST = [
    {
        title_tombol: "Email Kami",
        deskripsi: "Apakah kamu memiliki hal yang ingin ditanyakan seputar Divine?",
    },
    {
        title_tombol: "Hubungi Whatsapp Kami",
        deskripsi: "Apakah kamu memiliki kebutuhan yang lebih spesifik atau personal?",
    },
    {
        title_tombol: "Diskui Event Anda",
        deskripsi: "Ingin berdiskusi mengenai rencana event Anda?",
    },
]

// ======= HALAMAN ABOUT US ========
// PERJALANAN DIVINE PRO
export const PERJALANAN_DIVINE_PRO = [
    {
        tahun: "2018",
        titleTahun: "Kami Dimulai dari..",
        body: <>Setelah lulus dari Universitas Multimedia Nusantara pada awal Desember 2018, Devin Yiulianto selaku founder mulai merintis perjalanan panjang kami dengan mendirikan perusahaan Divine Production. Kala itu, Headquarter Divine Production masih berlokasi di suatu ruko di pinggiran kota Karawaci, Tangerang.</>
    },
    {
        tahun: "2019",
        titleTahun: "Perjuangan Kami..",
        body: <>“Membuat startup itu mudah, namun bertahannya yang sulit,” situasi inilah yang terjadi pada perusahaan kami di tahun ini. 
        <br /><br />
        Sulitnya mendapat client baru sekaligus mempertahankan client lama, merupakan tantangan terbesar kami. <q>Berdarah?</q> Tentu saja. Namun, terus memperluas relasi dan mengasah soft skill dengan mengikuti perkembangan teknologi, menjadi pilihan kami untuk terus bertahan demi menjalankan perusahaan ini.</>
    },
    {
        tahun: "2020-2021",
        titleTahun: "Ketika Pandemi Datang..",
        body: <>Masuknya COVID-19 di Indonesia berdampak pada seluruh sektor perusahaan, terutama di sektor ekonomi kreatif. Seluruh perusahaan berlomba-lomba mencari ide bagaimana agar suatu acara tetap bisa berjalan, di mana akhirnya mulai merebak istilah event <q>full online</q> maupun <q>hybrid</q>. 
        <br /><br />
        Divine Production yang sejak setahun sebelumnya terus mengikuti perkembangan teknologi, kini menjadi salah satu yang terdepan dalam menyediakan jasa event online maupun hybrid. Alhasil, kami mulai semakin dikenal oleh banyak perusahaan.</>
    },
    {
        tahun: "Sekarang",
        titleTahun: "Kami di Sini..",
        body: <>Divine Production terus tumbuh dengan meningkatkan kualitas pelayanannya. Kami berkomitmen untuk terus mengikuti perkembangan teknologi, dengan menyediakan alat-alat dokumentasi yang ter-up to date. Dimotori oleh para pemuda yang berjiwa kreatif, eksploratif dan inovatif, kami kini telah menjadi partner bersama lebih dari 300 klien.</>
    },
]

// VISI MISI
export const VISI_PARAGRAF_ATAS = 'Menjadi penyedia jasa dan peralatan audio visual dengan kualitas dan harga terbaik di Indonesia.'
export const VISI_PARAGRAF_BAWAH = 'Affordable audio visual equipment and services with the best quality in Indonesia.'

export const MISI_PARAGRAF_ATAS = 'Menjadi partner terbaik dalam bidang dokumentasi dan broadcasting yang dapat dipercaya, responsif serta selalu mengikuti perkembangan teknologi.'
export const MISI_PARAGRAF_BAWAH = 'Be the best documentation and broadcasting partner that are trusted, responsive, and updated.'

// LEADERSHIP TEAM
export const LEADERSHIP_TEAM = [
    { 
        nama: "Steven Hartoyo",
        level: "Chief Technician Officer",
        quotes: "“Do the best and God do the rest”",
        linkedIn: "https://www.linkedin.com/in/steven-hartoyo/",
        foto: "/images/AboutUs/divine_person/person-1.png"
    },
    { 
        nama: "Devin Yiulianto",
        level: "Chief Executive Officer",
        quotes: "“Work hard play hard”",
        linkedIn: "https://www.linkedin.com/in/devin-yiulianto-6931a9171/",
        foto: "/images/AboutUs/divine_person/person-2.png"
    },
    { 
        nama: "Rafael Ryandika",
        level: "Chief Marketing Officer",
        quotes: "“Money is not everything, but everything needs money”",
        linkedIn: "https://www.linkedin.com/in/rafael-ryandika-335b7712b/",
        foto: "/images/AboutUs/divine_person/person-3.png"
    },
]