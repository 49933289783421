import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    data: null,
    loading: false,
    success: false,
    error: false
}

export const getArticleById = 
    createAsyncThunk(
        'detailArticle/getArticleById',
        async ({ id }, thunkAPI) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/articles/v1/getArticle/${id}`)
                return res.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error)
            }
        }
    )

const detailArticleSlice = createSlice({
    name: 'detailArticle',
    initialState,
    extraReducers: {
        [getArticleById.pending]: (state) => {
            state.loading = true
        },
        [getArticleById.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.data = action.payload
        },
        [getArticleById.rejected]: (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        }
    }
})

// export const { clearArticles } = detailArticleSlice.actions
export default detailArticleSlice.reducer