import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'
// MATERIAL UI
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
// CONSTANTS
import { paletteColor } from '../../constant/colorConstant'

const style = {
    rootContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: {xs: "40px", md: "72px"},
    },
    imageContainer: {
        maxWidth: "clamp(12.5rem, 2.2969rem + 38.1423vw, 36.625rem)",
        flexBasis: "1/2",
    },
    skeleton: { 
        aspectRatio: "200 / 160",
        height: "100%",
        width: "clamp(12.5rem, 2.2969rem + 38.1423vw, 36.625rem)",
    }
}

const ThankYouStep = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    const upSmallScreen = useMediaQuery(theme.breakpoints.up("sm"))

    const handleClickBackToHome = () => {
        navigate("/")
    }

    return (
        <Box sx={style.rootContainer}>
            {/* IMAGE */}
            <Box sx={style.imageContainer}>
                <LazyLoadImage 
                    src={"/images/consultation/thank-you-illus.png"}
                    alt='thank you'
                    width={"100%"}
                    style={{ aspectRatio: "200 / 160" }}
                    placeholder={<Skeleton sx={style.skeleton} />}
                />
            </Box>

            {/* TEXT */}
            <Box marginTop={"40px"}>
                <Typography
                    component="h1"
                    variant="body2"
                    sx={{ lineHeight: "normal", fontSize: "clamp(1.25rem, 0.9328rem + 1.1858vw, 2rem)" }}
                    style={{ fontWeight: "500" }}
                    align="center"
                    color={paletteColor.black100}
                >
                    Terima kasih, kami akan segera menghubungi kamu!
                </Typography>
            </Box>

            {/* BUTTON */}
            <Box marginTop={upSmallScreen ? "40px" : "20px"}>
                <Button
                    variant="contained"
                    onClick={handleClickBackToHome}
                    size="large"
                >
                    Kembali ke Home
                </Button>
            </Box>
        </Box>
    )
}

export default ThankYouStep