import React, { useEffect, useState } from "react";
// MATERIAL UI
import { Button } from "@mui/material";
// COMPONENTS
import ConsultationDialog from "./ConsultationDialog";

const ConsultationDialogButton = ({
  sxProps = {},
  buttonTitle = "Konsultasikan Sekarang",
  buttonSize = "large",
  buttonVariant = "contained",
}) => {
  const [openConsultationDialog, setOpenConsultationDialog] = useState(false);
  const [start, setStart] = useState(true);

  const handleOpenConsultationDialog = () => {
    setOpenConsultationDialog(true);
  };
  const handleCloseConsultationDialog = (done) => {
    setOpenConsultationDialog(false);
    // console.log(done);
    if (done === true) {
      setStart(false);
      // console.log("masuk if");
    }
  };

  useEffect(() => {
    setStart(true);
  }, [start]);

  return (
    <>
      <Button
        color="primary"
        variant={buttonVariant}
        size={buttonSize}
        sx={sxProps}
        onClick={handleOpenConsultationDialog}
      >
        {buttonTitle}
      </Button>
      {start === true ? (
        <ConsultationDialog
          open={openConsultationDialog}
          handleClose={handleCloseConsultationDialog}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ConsultationDialogButton;
