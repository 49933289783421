import ReactGA from "react-ga4";

export default function InitializeGoogleAnalytics() {
    const googleAnalyticsOptions = {
        gaOptions : {
            send_page_view : false,
            cookie_flags: 'SameSite=None;Secure'
        }
    };

    console.log("Initialize Google Analytics");

    ReactGA.initialize(process.env.REACT_APP_GA_KEY, googleAnalyticsOptions);

    return null;
}