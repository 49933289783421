import { useContext } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// MATERIAL UI
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
// import useMediaQuery from '@mui/material/useMediaQuery'
// import { useTheme } from '@mui/material'
// ICONS
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// CONSTANTS
import { ConsultationContext } from '../../hooks/consultationContext'
import { fontPreset } from "../../constant/fontConstant";
import { CustomTextField, SelectInputCustom, menuSelectStyle } from '../../constant/textFieldConstant'
import { paletteColorConstant } from '../../constant/colorConstant'

const style = {
    rootContainer: { 
        display: "flex",
        columnGap: "clamp(1.25rem, -1.499rem + 10.2767vw, 7.75rem)",
        flexDirection: {xs: "column", sm: "row"},
        alignItems: {xs: "center", sm: "flex-start"},
        rowGap: "40px"
    },
    imageContainer: {
        maxWidth: "clamp(12.5rem, 2.2441rem + 38.3399vw, 36.75rem)",
        flexBasis: "1/2"
    },
    formField: {
        display: "flex",
        flexDirection: "column",
        rowGap: "40px",
        flex: "1 1 0%",
        flexBasis: "1/2",
        width: "100%",
        padding: {xs: "0 8px", sm: "0 0" }
    },
    formSection: {
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
    },
    buttonSection: {
        display: "flex",
        justifyContent: "flex-end",
        columnGap: "20px"
    },
    labelInput: { 
        ...fontPreset.body16Reg,
        marginBottom: "8px"
    },
    errorInputLabel: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "4px",
    },
    placeholderOption: { 
        color: paletteColorConstant.gray2,
        ...fontPreset.body12Reg,
        marginTop: "2px"
    }
}

const menuSelect = [
    { menu: "Full Online", value: "Full Online" },
    { menu: "Full Offline", value: "Full Offline" },
    { menu: "Hybrid", value: "Hybrid" },
];

const totalAttendanceSelect = [
    { menu: "1 - 50 Peserta", value: "1 - 50 Peserta" },
    { menu: "51 - 100 Peserta", value: "51 - 100 Peserta" },
    { menu: "101 - 500 Peserta", value: "101 - 500 Peserta" },
    { menu: "501 - 1000 Peserta", value: "501 - 1000 Peserta" },
    { menu: "> 1000 Peserta", value: "> 1000 Peserta" },
]

const EventTypeDataStep = () => {
    // const theme = useTheme();
    // const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

    const consultationState = useContext(ConsultationContext)

    const CHARACTER_LIMIT = 280
    const eventType = "placeholder"
    const totalAttendance = "placeholder"
    const display = "none"
    const eventDesc = ""

    return (
        <Box sx={style.rootContainer}>
            {/* IMAGE */}
            <Box sx={style.imageContainer}>
                <LazyLoadImage 
                    src={"/images/consultation/puzzle-illus.png"}
                    alt='puzzle image'
                    width={"100%"}
                    style={{ aspectRatio: "1 / 1" }}
                    placeholder={<Skeleton sx={{ aspectRatio: "1 / 1", height: "100%", width: "clamp(12.5rem, 2.2441rem + 38.3399vw, 36.75rem)" }} />}
                />
            </Box>

            {/* FORMS */}
            <Box sx={style.formField}>
                <div className="jenis_event_field">
                    <Typography sx={style.labelInput}>
                        Jenis Event
                    </Typography>
                    <Select
                        labelId="select-jenis-event"
                        id="select-jenis-event"
                        size="small"
                        fullWidth
                        input={<SelectInputCustom />}
                        IconComponent={ArrowBackIosNewIcon}
                        value={eventType}
                        // onChange={(e) => setEventType(e.target.value)}
                        renderValue={(selected) => {
                            if (selected === "placeholder") {
                                return (
                                    <Typography sx={style.placeholderOption}>
                                        Pilih Jenis Event
                                    </Typography>
                                );
                            } else {
                                return selected;
                            }
                        }}
                        MenuProps={{ PaperProps: { style: menuSelectStyle } }}
                    >
                        <MenuItem disabled value={"placeholder"}>
                            Pilih Jenis Event
                        </MenuItem>

                        {menuSelect.map((val) => (
                            <MenuItem key={val.value} value={val.value}>
                                {val.menu}
                            </MenuItem>
                        ))}
                    </Select>
                </div>

                <div className="total_jumlah_peserta_field">
                    <Typography sx={style.labelInput}>
                        Estimasi Jumlah Peserta
                    </Typography>
                    <Select
                        labelId="select-total-jumlah-peserta"
                        id="select-total-jumlah-peserta"
                        size="small"
                        fullWidth
                        input={<SelectInputCustom />}
                        IconComponent={ArrowBackIosNewIcon}
                        value={totalAttendance}
                        // onChange={(e) => setTotalAttendance(e.target.value)}
                        renderValue={(selected) => {
                            if (selected === "placeholder") {
                                return (
                                    <Typography sx={style.placeholderOption}>
                                        Pilih Estimasi Jumlah Peseta
                                    </Typography>
                                );
                            } else {
                                return selected;
                            }
                        }}
                        MenuProps={{ PaperProps: { style: menuSelectStyle } }}
                    >
                        <MenuItem disabled value={"placeholder"}>
                            Pilih Estimasi Jumlah Peseta
                        </MenuItem>
                        {totalAttendanceSelect.map((val) => (
                            <MenuItem key={val.value} value={val.value}>
                                {val.menu}
                            </MenuItem>
                        ))}
                    </Select>
                </div>

                <div className="deskripsi_singkat_field">
                    <Typography sx={style.labelInput}>
                        Deskripsi Singkat Eventmu (Minimal 20 Karakter)
                    </Typography>

                    <CustomTextField
                        placeholder="Contoh: Perusahaan kami ingin membuat event Townhall meeting untuk 1000 orang secara online dengan 10 orang di studio. Perkiraan sementara kami, kami akan membutuhkan greenscreen, lighting, dan juga beberapa kru untuk membantu kami."
                        variant="outlined"
                        size="small"
                        multiline
                        rows={5}
                        value={eventDesc}
                        // onChange={(e) => setEventDesc(e.target.value)}
                        inputProps={{ maxLength: CHARACTER_LIMIT }}
                        fullWidth
                    />

                    <Box sx={style.errorInputLabel}>
                        {display === "error4" && (
                            <Typography
                                sx={{ flex: "2", color: "red", ...fontPreset.body14Lig, display: display }}
                            >
                                Mohon isi deskripsi singkat dengan minimal 20
                                karakter
                            </Typography>
                        )}

                        <Box>
                            <Typography
                                variant="caption"
                                fontSize={10}
                                fontWeight={"bold"}
                            >
                                {`${eventDesc.length}`}
                            </Typography>
                            <Typography
                                variant="caption"
                                fontSize={10}
                                color={"text.secondary"}
                            >
                                {`/${CHARACTER_LIMIT}`}
                            </Typography>
                        </Box>
                    </Box>
                </div>

                <div className="action__button"
                    style={style.buttonSection}
                >
                    <Button
                        sx={{ marginTop: "-10px" }}
                        onClick={() => { consultationState.prevStep() }}
                        size="large"
                    >
                        Sebelumnya
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ marginTop: "-10px" }}
                        onClick={() => { consultationState.nextStep() }}
                        // disabled={checkDisabledButtonTwo()}
                        size="large"
                    >
                        Selanjutnya
                    </Button>
                </div>
            </Box>
        </Box>
    )
}

export default EventTypeDataStep