import { Link } from "react-router-dom";
// MATERIAL UI
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
// COMPONENTS
import ArticleSlider from "../../../components/Home/ArticleSlider";
import ArticleGridView from "../../../components/Home/ArticleGridView";
// CONSTANTS
import { paletteColorConstant } from "../../../constant/colorConstant";
import { fontPreset } from "../../../constant/fontConstant";

const ArticleSection = () => {
    const theme = useTheme()
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

    return (
        <>
            <Container maxWidth="lg">
                <div style={{ display: "flex", justifyContent: downMediumScreen ? "center" : "space-between" }}>
                    <Typography
                        variant="h6"
                        sx={{ color: paletteColorConstant.black2 }}
                        style={downMediumScreen ? fontPreset.body20Med : fontPreset.body32Med}
                    >
                        Artikel { downMediumScreen ? "" : "Terbaru" } Kami
                    </Typography>

                    {   
                        !downMediumScreen && 
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Button
                                    color="primary"
                                    size={downMediumScreen ? "medium" : "large"}
                                    component={Link}
                                    to={"/articles"}
                                >
                                    Lihat Artikel Lainnya
                                </Button>
                            </div>
                    }
                </div>
            </Container>

            {   
                downMediumScreen ?
                    <Container maxWidth="lg">
                        <ArticleGridView />
                    </Container> :
                // LARGE SCREEN
                    <div className="card__slider"
                        style={{ margin: "28px 0 0 auto" }}
                    >
                        <ArticleSlider />
                    </div>
            }
        </>
    )
}

export default ArticleSection