import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation";
import '../../screen/style/TestiSwiper.css'
// import required modules
import { Mousewheel, Navigation, Pagination } from "swiper"
// MUI
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
// ICONS
import LeftSliderIcon from '../icons/arrows/LeftSliderIcon';
import RightSliderIcon from '../icons/arrows/RightSliderIcon';
import LeftSliderIconFill from '../icons/arrows/LeftSliderIconFill';
import RightSliderIconFill from '../icons/arrows/RightSliderIconFill';
// CONSTANT
import { fontPreset } from '../../constant/fontConstant'
import { paletteColor, paletteColorConstant } from '../../constant/colorConstant'
import { getAllTestimoni } from '../../features/testimoni/testimoniSlice'

const Testimoni = () => {
  const dispatch = useDispatch()

  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const downLargeScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const [pagination, setPagination] = useState(null)
  const [activeSlide, setActiveSlide] = useState(0)

  const swiperRef = useRef(null);
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  const testimoniSlice = useSelector((state) => state.testimoni)
  const { loading, success, testimoniItems, error } = testimoniSlice

  const article = [0]

  const style = {
    leftColumnContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    swiperStyle: {
        position: 'relative',
        background: `linear-gradient(180deg, ${paletteColor.primaryGray} 0%, #333 100%)`,
        padding: downMediumScreen ? '20px 20px 20px' : '24px 24px 40px',
        borderRadius: '8px',
        color: "white",
    },
    nextPrevSwiper: {
        fontSize: "28px",
        padding: 0,
        transition: "color .25s ease",
        color: "#CACACA",
    },
    prevButton: {
        position: "absolute",
        top: "calc(33.333% + 14px)",
        left: "26px",
        "&:hover": {
          cursor: "pointer"
        },
        zIndex: 10,
    },
    nextButton: {
        position: "absolute",
        top: "calc(33.333% + 14px)",
        right: "26px",
        "&:hover": {
            cursor: "pointer"
        },
        zIndex: 10,
    },
    enableButton: {
        backgroundColor: "white",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "9px",
        boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
        opacity: 0,
        transition: "opacity .2s ease",
        "&:hover": {
          backgroundColor: paletteColorConstant.primaryBlue150,
          "& .icon__slider__pagination": {
            color: "#FFF",
          },
        },
    },
    disableButton: {
        backgroundColor: "#f9f9f9",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "9px",
        cursor: "auto",
    },
  }

  useEffect(() => {
    dispatch(getAllTestimoni())
  }, [])
  

  return (
    <>
        { loading ?
            <Box className='testimoni__loading'
                sx={{ ...style.swiperStyle, maxWidth: "100%" }}
            >
                <Box sx={{ ...style.leftColumnContent, rowGap: downMediumScreen ? '12px' : '20px' }}>
                    <Skeleton 
                        variant="rectangular"
                        animation="wave"
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={50}
                    />
                </Box>
            </Box> :
          error ?
            <Box className='testimoni__error'
                sx={{ ...style.swiperStyle, maxWidth: "100%" }}
            >
                <Box sx={{ ...style.leftColumnContent, rowGap: downMediumScreen ? '12px' : '20px' }}>

                    <Typography
                        sx={{ ...fontPreset.body16Lig, color: paletteColorConstant.black1 }}
                        align="center"
                    >
                        Oops, Sepertinya terjadi kesalahan. Coba Lagi.
                    </Typography>

                    <Box 
                        sx={{ 
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Button 
                            variant='contained'
                            onClick={() => { dispatch(getAllTestimoni()) }}
                        >
                            Coba Lagi
                        </Button>
                    </Box>
                </Box>
            </Box> :
          success ?
            <Box sx={{ width: "100%" }}>
                <Swiper
                    ref={swiperRef}
                    modules={[Pagination, Navigation, Mousewheel]}
                    navigation={{ prevEl: prevEl, nextEl: nextEl }}
                    pagination={{ el: pagination, clickable: true }}
                    mousewheel={{ forceToAxis: true, sensitivity: 0.45 }}
                    style={style.swiperStyle}
                    spaceBetween={140}
                    onSlideChange={(swiper) => { setActiveSlide(swiper.activeIndex) }}
                >
                    {/* ARROW LEFT */}
                    <Box className="slider__pagination__button"
                        ref={(node) => setPrevEl(node)}
                        sx={{ ...style.prevButton, display: article.length > 4 ? "none" : "flex" }}
                    >
                        {   activeSlide === 0 ? 
                                <LeftSliderIcon
                                    width={downMediumScreen ? '24px' : '32'}
                                    height={downMediumScreen ? '24px' : '32'}
                                /> :
                            // NON FIRST
                                <LeftSliderIconFill
                                    width={downMediumScreen ? '24px' : '32'}
                                    height={downMediumScreen ? '24px' : '32'}
                                />
                        }
                    </Box>
                    
                    { testimoniItems.data.map((val, index) => (
                        <SwiperSlide key={`testi-${index}`}>
                            <Box 
                                sx={{ 
                                    ...style.leftColumnContent,
                                    rowGap: downMediumScreen ? '12px' : '20px',
                                    maxWidth: "380px",
                                    marginX: "auto",
                                    paddingX: downMediumScreen ? "52px" : downLargeScreen ? "32px"  : "0px"
                                }}
                            >
                                <Typography 
                                    variant='body1'
                                    align='center'
                                    style={ downMediumScreen ? fontPreset.body16Med : fontPreset.titleS }
                                >
                                    { val.by }
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='center'
                                    style={ downMediumScreen ? fontPreset.body14Lig : fontPreset.body16Lig }
                                >
                                    { val.body }
                                </Typography>
                            </Box>
                        </SwiperSlide>
                    )) }

                    {/* ARROW RIGHT */}
                    <Box className="slider__pagination__button"
                        ref={(node) => setNextEl(node)}
                        sx={{ ...style.nextButton, display: article.length > 4 ? "none" : "flex" }}
                    >
                        {   activeSlide === testimoniItems.data?.length - 1 ? 
                                <RightSliderIcon 
                                    width={downMediumScreen ? '24px' : '32'}
                                    height={downMediumScreen ? '24px' : '32'}
                                /> :
                            // NON LAST
                                <RightSliderIconFill
                                    width={downMediumScreen ? '24px' : '32'}
                                    height={downMediumScreen ? '24px' : '32'}
                                />
                        }
                    </Box>

                    <div className='testi__pagination'
                        ref={(node) => setPagination(node)}
                        style={{ marginTop: downMediumScreen ? "20px" : "32px" }}
                    />
                </Swiper> 
            </Box> :
          // NULL
            <Box className='null__swiper'
                sx={{ ...style.swiperStyle, width: "100vw" }}
            >
                <Box sx={{ ...style.leftColumnContent, rowGap: downMediumScreen ? '12px' : '20px' }}>
                    <Skeleton 
                        variant="rectangular"
                        animation="wave"
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={50}
                    />
                </Box>
            </Box>
        }
    </>
  )
}

export default Testimoni