import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// MATERIAL UI
import { createTheme, ThemeProvider } from "@mui/material";
import { createBreakpoints } from "@mui/system";
// SCREEN
import Home from "./screen/Home";
import AboutUs from "./screen/AboutUs";
import Portfolio from "./screen/Portfolio";
import Articles from "./screen/Articles";
import ProductServices from "./screen/ProductServices";
import NotFound from "./screen/NotFound";
import ArticleDetails from "./screen/ArticleDetails";
import ProductDetail from "./screen/ProductDetail";
import ConsultationPage from "./screen/ConsultationPage";
// COMPONENTS
import Layout from "./components/Layout/Layout";
import ScrollToTopAfterChangePage from "./components/utils/ScrollToTopAfterChangePage";
import InitializeGoogleAnalytics from "./components/InitializeGoogleAnalytics";
import RecordPageVisit from "./components/RecordPageVisit";
// CONSTANT
import { paletteColor, paletteColorConstant } from "./constant/colorConstant";
import { fontPreset, mobileFontPreset } from "./constant/fontConstant";

function App() {
  const breakpoints = createBreakpoints({})
  
  const theme = createTheme({
    palette: {
      primary: { main: paletteColor.primaryBlue },
      primaryLight: { main: "#80A1AC" },
      primaryDark: { main: "#00222C" },
      whatsAppColor: { main: "#22A515" },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        sm2: 720,
        md: 900,
        xmd: 1030,
        lg: 1200,
        lg2: 1350,
        xl: 1536,
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "contained" },
            style: {
              color: "white",
              borderRadius: "4px",
              boxShadow: "none",
              ...fontPreset.buttonLabel,
              ":hover": {
                color: "white",
                backgroundColor: paletteColor.blue150,
                boxShadow: "none",
              },
              ":active": {
                backgroundColor: paletteColor.blue150,
                boxShadow: "none",
              },
              ":disabled": { backgroundColor: paletteColor.blue50, color: "white" },
              [breakpoints.down('md')]: { 
                ...mobileFontPreset.buttonLabel,
                padding: "6px 8px"
              },
              // ":selected": { font: fontPreset.body20Reg },
            },
          },
          {
            props: { variant: "text" },
            style: {
              borderRadius: "4px",
              padding: "8px 22px",
              ...fontPreset.buttonLabel,
              ":hover": {
                color: paletteColor.blue150,
                backgroundColor: "transparent",
              },
              ":active": {
                color: paletteColor.blue150,
              },
              [breakpoints.down('md')]: { 
                ...mobileFontPreset.buttonLabel,
                padding: "6px 8px"
              },
            },
          },
          {
            props: { variant: "outlined" },
            style: {
              color: paletteColor.primaryBlue,
              outline: `1px solid ${paletteColor.primaryBlue}`,
              ":hover": {
                color: paletteColor.primaryBlue,
                outline: `1px solid ${paletteColor.primaryBlue}`,
              },
              "&.Mui-disabled": {
                color: paletteColor.blue50,
                outline: `1px solid ${paletteColor.blue50}`,
              },
              [breakpoints.down('md')]: { 
                ...mobileFontPreset.buttonLabel,
                padding: "6px 8px"
              },
            },
          },
          {
            props: { variant: "menuMobile" },
            style: {
              fontWeight: "500",
              borderRadius: "2px",
              color: "white",
              ":hover": {
                color: "white",
                backgroundColor: "transparent",
              },
              ":active": {
                color: "white",
              },
            },
          },
          {
            props: { variant: "notFoundPage" },
            style: {
              color: paletteColorConstant.primaryBlue150,
              borderRadius: "2px",
              ...fontPreset.buttonLabel,
              ":hover": {
                backgroundColor: "white"
              },
              ":active": {
                backgroundColor: "white"
              },
              ":disabled": { backgroundColor: "white", color: "white" },
              [breakpoints.down('md')]: { 
                ...mobileFontPreset.buttonLabel
              },
              // ":selected": { font: fontPreset.body20Reg },
            },
          },
        ],
      },
    },
  });

  return (
    <>
      <InitializeGoogleAnalytics />
      <Router>
        <RecordPageVisit />
        <ScrollToTopAfterChangePage />
        <ThemeProvider theme={theme}>
            <Routes>
              <Route
                path="/"
                element={<Layout />}
              >
                <Route path="" exact element={<Home />} />
                <Route path="home" exact element={<Home />} />
                <Route path="about-us" exact element={<AboutUs />} />
                <Route path="portfolio" exact element={<Portfolio />} />
                <Route path="articles" exact element={<Articles />} />
                <Route path="article/:slug" exact element={<ArticleDetails />} />
                <Route
                  path="product-services"
                  exact
                  element={<ProductServices />}
                />
                <Route path="product/:productId" exact element={<ProductDetail />} />
                <Route path="*" exact element={<NotFound />} />
              </Route>

              <Route path="/consultation" exact element={<ConsultationPage />} />
            </Routes>
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
