import { configureStore } from '@reduxjs/toolkit'
import testimoniReducer from './features/testimoni/testimoniSlice'
import consultationReducer from './features/consultation/consultationSlice'
import heroBannerReducer from './features/asset/heroBannerSlice'
import companyProfileReducer from './features/asset/companyProfileSlice'
import cityReducer from './features/consultation/citySlice'
import articlesReducer from './features/article/articleSlice'
import articleDetailReducer from './features/article/detailArticleSlice'
import highlightReducer from './features/article/articleHighlightSlice'
import productReducer from './features/product_services/productSlice'
import productDetailReducer from './features/product_services/productDetailSlice'

export const store = configureStore({
    reducer: {
        heroBanner: heroBannerReducer,
        testimoni: testimoniReducer,
        consultationForm: consultationReducer,
        profileCompany: companyProfileReducer,
        cityList: cityReducer,
        articles: articlesReducer,
        articleDetail: articleDetailReducer,
        highlightedArticle: highlightReducer,
        products: productReducer,
        productDetail: productDetailReducer
    },
})