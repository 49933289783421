import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// MATERIAL UI
import { Container, Grid, useMediaQuery, useTheme } from '@mui/material'

const ClientGridList = ({ clientList = [1,2,3,4,5,6,7,8,9,10] }) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container>
        <Grid container 
            rowSpacing={ downMediumScreen ? '22px' : '80px' } 
            columnSpacing={ downMediumScreen ? '22px' : '80px' }
        >
            { clientList.map((val, index) => (
                <Grid item xs={2.4}
                    key={`swiper-${index + 1}`}
                    className={'slide__portfolio'}
                >
                    <LazyLoadImage 
                        alt={`client-${val}`}
                        src={`/images/clients/client-${val}.png`}
                        width={downMediumScreen ? '60px' : '160px'}
                        height={downMediumScreen ? '30.15px' :'80px'}
                        effect="opacity"
                    />
                </Grid>
            )) }            
        </Grid>
    </Container>
  )
}

export default ClientGridList