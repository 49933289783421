import React from "react";
// MATERIAL UI
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
// COMPONENTS
import { WhatWeDoCard } from "./WhatWeDoCard";

const WhatWeDoSlide = ({ data }) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Container>
      <Box className="what_we_do_slide_wrapper"
        sx={{ display: "flex", justifyContent: "center", columnGap: downMediumScreen ? "12px" : "120px" }}
      >
        {data.map((val, index) => (
          <WhatWeDoCard key={`card-${index}`} props={val} />
        ))}
      </Box>
    </Container>
  );
};

export default WhatWeDoSlide;
