import { styled, Tabs, useMediaQuery } from "@mui/material";
// CONSTANT
import { fontPreset } from "./fontConstant";

export const CustomTab = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-flexContainer": {
      rowGap: "5px",
      columnGap: useMediaQuery(theme.breakpoints.down("md")) ? "12px" : "24px",
      fontSize: useMediaQuery(theme.breakpoints.down("md")) ? "16px" : "20px"
    },
    "& .Mui-selected": useMediaQuery(theme.breakpoints.down("md")) ? fontPreset.body16Reg : fontPreset.body20Reg,
}));