import {
  Dialog,
  InputBase,
  StepConnector,
  stepConnectorClasses,
  TextField,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { paletteColor, paletteColorConstant } from "./colorConstant";
import Check from "@mui/icons-material/Check";
import { boxShadowStyle } from "../constant/shadowConstant";

// TEXT FIELD STYLING
// DIPAKAI: CONSULTATION DIALOG
export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "8px",
    borderRadius: "1rem",
    "&::placeholder": {
      fontSize: "12px",
      color: paletteColor.white50
    },
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: paletteColor.white25,
    "& fieldset": {
      border: `1px solid ${paletteColor.white25}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${paletteColor.blue100}`,
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${paletteColor.blue100}`,
    },
    "&.Mui-disabled fieldset": {
      borderColor: paletteColor.white25,
    },
  },
  "& .MuiInputBase-root": {
    padding: 0,
  },
});

export const CustomTextFieldTransparant = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding:{ xs: "12px", md: "20px" },
    borderRadius: "12px",
    fontSize: { xs: 18, md: 20 },
    "&::placeholder": { fontSize: { xs: "18px", md: "20px" } },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    backgroundColor: "transparant",
    width: "100%",
    ...boxShadowStyle.generalShadow,
    "& fieldset": { border: "none" },
    "& .MuiInputAdornment-root": {
      paddingRight: "20px",
      color: paletteColorConstant.primaryBlue150
    },
    "&:hover fieldset": {
      border: `2px solid ${paletteColorConstant.primaryBlue150}`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${paletteColorConstant.primaryBlue150}`,
    },
    "&.Mui-disabled fieldset": {
      borderColor: paletteColor.white25,
    },
  },
  "& .MuiInputBase-root": {
    padding: 0,
  },
});

export const CustomAutoCompleteTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    borderRadius: "1rem",
    "&::placeholder": {
      fontSize: "12px",
      color: paletteColor.white50
    },
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: `${paletteColorConstant.white2}`,
    "& fieldset": {
      border: `1px solid ${paletteColorConstant.white2}`,
    },
    "&:hover fieldset": {
      border: `2px solid ${paletteColorConstant.primaryBlue150}`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${paletteColorConstant.primaryBlue150}`,
    },
    "&.Mui-disabled fieldset": {
      borderColor: `${paletteColorConstant.white2}`,
    },
  },
  "& .MuiInputBase-root": {
    padding: ".5px 4px .5px 2px",
  },
});

export const CustomTimePickerField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "8px",
    borderRadius: "1rem",
    "&::placeholder": {
      fontSize: "12px",
      color: paletteColor.white50
    },
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: paletteColorConstant.white2,
    "& fieldset": {
      border: `1px solid ${paletteColorConstant.white2}`,
    },
    "&:hover fieldset": {
      border: `2px solid ${paletteColorConstant.primaryBlue150}`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${paletteColorConstant.primaryBlue150}`,
    },
  },
  "& .MuiInputBase-root": {
    padding: "0 18px 0 0",
  },
});

export const SelectInputCustom = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-root": {
    border: `1px solid ${paletteColorConstant.primaryBlue150}`,
  },
  "& .MuiInputBase-input": {
    borderRadius: "4px",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${paletteColor.white25}`,
    fontSize: 16,
    padding: "10px",
    transition: theme.transitions.create(["box-shadow"]),
    "&:focus": {
      border: `1px solid ${paletteColor.blue100}`,
    },
    "&:hover": {
      border: `1px solid ${paletteColor.blue100}`,
    }
  },
  "& .MuiSvgIcon-root": {
    transform: "rotate(-90deg)",
  },
  "& .MuiSelect-iconOpen": {
    transform: "rotate(90deg)",
  },
}));

// SELECT (DROPDOWN)
// DIPAKAI: CONSULTATION DIALOG
export const menuSelectStyle = {
  marginTop: "-2px",
  // border: '2px solid #004459',
  outline: "3px solid #fff",
  borderRadius: "0 0 6px 6px",
  boxShadow: "0px 3px 5px 0px rgba(100,100,100,0.75)",
};

export const Dropdown = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const DropdownSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const DropdownDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// RADIO BUTTON
// DIPAKAI: CONSULTATION DIALOG
export const RBIconCustom = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  backgroundColor: paletteColorConstant.white2,
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(66, 163, 227, .6)",
    outlineOffset: 2,
  },
  // 'input:hover ~ &': {
  //   backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  // },
  // 'input:disabled ~ &': {
  //   boxShadow: 'none',
  //   background:
  //     theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  // },
}));

export const RBIconCheckedCustom = styled(RBIconCustom)({
  backgroundColor: paletteColorConstant.white2,
  transition: "color 100ms ease-in",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    border: `3.5px solid ${paletteColorConstant.white2}`,
    // backgroundColor: paletteColorConstant.primaryBlue150,
    backgroundColor: paletteColorConstant.primaryBlue150,
    borderRadius: "50%",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: paletteColorConstant.white2,
  },
});

// STEPPER & ICON STEPPER
// DIPAKAI: CONSULTATION DIALOG
export const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {},
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 0,
    border: 0,
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CACACAFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
}));

export const CustomStepIcon = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    ownerState.active || ownerState.completed
      ? paletteColorConstant.primaryBlue150
      : paletteColorConstant.gray2,
  color:
    ownerState.active || ownerState.completed
      ? "#fff"
      : paletteColorConstant.white2,
  zIndex: 1,
  width: 28,
  height: 28,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "12px",
  transition: "all 250ms ease",
}));

export const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <CustomStepIcon ownerState={{ completed, active }} className={className}>
      {completed ? (
        <Check sx={{ fontSize: "12px" }} className="check-icon-stepper" />
      ) : (
        props.icon
      )}
    </CustomStepIcon>
  );
};

// NEW STEPPER
export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: paletteColor.primaryBlue,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: paletteColor.primaryBlue,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 4,
    borderRadius: 1,
    transition: "all 250ms ease",
  }
}));

// DIALOG
// DIPAKAI: CONSULTATION DIALOG
export const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    padding: "24px 0",
  },
  "& .MuiDialogContent-root": {
    padding: "0",
  },
  "& .MuiDialogActions-root": {
    padding: "0",
  },
}));

export const priceFormat = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
