import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageGallery from "react-image-gallery";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// MATERIAL UI
import {
  Box,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
// CONSTANT
import { priceFormat } from "../constant/textFieldConstant";
import { fontPreset } from "../constant/fontConstant";
import { paletteColorConstant } from "../constant/colorConstant";
// COMPONENTS
import ProductDetailSkeleton from "../components/ProductAndServices/Loading/ProductDetailSkeleton";
import ConsultationDialogButton from "../components/Home/ConsultationDialogButton";
// ACTIONS
import { getProduct, resetProductDetail } from "../features/product_services/productDetailSlice";

const dummyImages = [
  {
    original: "/images/home/case_study_one.png",
    thumbnail: "/images/home/case_study_one.png",
    originalHeight: "372",
  },
  {
    original: "/images/home/case_study_one.png",
    thumbnail: "/images/home/case_study_one.png",
    originalHeight: "372",
  },
  {
    original: "/images/home/case_study_one.png",
    thumbnail: "/images/home/case_study_one.png",
    originalHeight: "372",
  },
  {
    original: "/images/home/case_study_one.png",
    thumbnail: "/images/home/case_study_one.png",
    originalHeight: "372",
  },
];

const ProductDetail = () => {
    const { productId } = useParams();

    const dispatch = useDispatch();
    const theme = useTheme();
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  
    const productDetailState = useSelector((state) => state.productDetail);
    const { product, loading, success, error } = productDetailState;
  
    const printProductCategory = (categories) => {
      const categoryList = [];
      categories.forEach((category) =>
        categoryList.push(category?.productTypes?.type ?? "")
      );
  
      return categoryList.join(", ");
    };
  
    const handlePrintGallery = ({ images }) => {
      let imageList = [];
  
      if (images !== null) {
        images.forEach((image) => {
          imageList.push({
            original: `${image.url}`,
            thumbnail: `${image.url}`,
            originalHeight: downMediumScreen ? `264px` : `372px`,
            // thumbnailHeight: downMediumScreen ? "62px" : "92px"
          });
        });
      }
  
      return (
        <ImageGallery
          additionalClass="case_study_gallery"
          items={images !== null ? imageList : dummyImages}
          showPlayButton={false}
          autoPlay={false}
          infinite={false}
          lazyLoad={true}
          showFullscreenButton={false}
          showBullets={true}
          showNav={false}
        />
      );
    };
  
    useEffect(() => {
        dispatch(getProduct({ productId: productId }));
  
        return () => {
            dispatch(resetProductDetail());
        };
    }, []);

    return (
        <Box
            className="detail__root"
            sx={{
                display: "flex",
                padding: downMediumScreen ? "40px 20px 20px" : "40px 0 70px",
                position: "relative",
            }}
        >
            {/* <IconButton
                aria-label="close"
                size="large"
                onClick={() => { handleCloseDetail() }}
                sx={{
                    position: "absolute",
                    top: downMediumScreen ? "30px" : "48px",
                    right: downMediumScreen ? "20px" : "119px",
                }}
            >
                <CloseIcon />
            </IconButton> */}

            { loading ? (
                <ProductDetailSkeleton />
            ) : success ? (
                <>
                    <Helmet>
                        <title>{`Sewa Alat ${product?.data?.title}` ?? ""}</title>
                        <meta name="description" content={`${product.data?.description}` ?? ""} />

                        <meta property="og:title" content={`Sewa Alat ${product?.data?.title}` ?? ""} />
                        <meta property="og:description" content={`${product.data?.description}` ?? ""} />
                        <meta property="og:site_name" content="Divine Pro" />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:image" content="/divine-logo.png" />
                    </Helmet>

                    <Container sx={{ marginTop: downMediumScreen ? "40px" : "0", padding: downMediumScreen ? "0px" : "auto" }}>
                        <Box
                            className="title__article__dialog"
                            sx={{
                                height: downMediumScreen ? "" : "108px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                component={"h1"}
                                sx={{
                                    ...fontPreset.titleXl,
                                    fontSize: downMediumScreen 
                                    ? fontPreset.titleXlMobile.fontSize
                                    : fontPreset.titleXl.fontSize,
                                    color: paletteColorConstant.black1,
                                    flexGrow: 1,
                                    lineHeight: downMediumScreen ? "28px" : "49.22px",
                                }}
                                align="center"
                            >
                                {product?.data?.title ?? ""}
                            </Typography>
                        </Box>

                        <Box className="image__article__dialog"
                            sx={{
                                marginTop: "40px",
                                display: "flex",
                                flexDirection: downMediumScreen ? "column" : "",
                                columnGap: downMediumScreen ? "0" : "60px",
                                rowGap: downMediumScreen ? "60px" : "0",
                            }}
                        >
                            <Box className="left__column"
                                sx={{
                                    display: downMediumScreen ? "flex" : "",
                                    width: downMediumScreen ? "100%" : "50%",
                                    justifyContent: "center",
                                    // padding: "0px",
                                }}
                            >
                                {handlePrintGallery({
                                    images: product?.data?.productServicePhoto ?? null,
                                })}
                            </Box>

                            <Box className="right__column"
                                sx={{
                                    display: "flex",
                                    width: downMediumScreen ? "100%" : "50%",
                                    flexDirection: "column",
                                    rowGap: "40px",
                                }}
                            >
                                <Box>
                                    <Typography
                                        component="h3"
                                        sx={{
                                            ...fontPreset.titleS,
                                            fontSize: downMediumScreen
                                            ? fontPreset.titleMMobile.fontSize
                                            : fontPreset.titleS.fontSize,
                                            color: paletteColorConstant.black1,
                                            marginBottom: "12px",
                                        }}
                                        fontWeight={"bold"}
                                    >
                                        Description
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            ...fontPreset.body16Lig,
                                            fontSize: downMediumScreen
                                            ? fontPreset.body14Lig.fontSize
                                            : fontPreset.body16Lig.fontSize,
                                            color: paletteColorConstant.black2,
                                            lineHeight: "18.75px",
                                        }}
                                    >
                                        {product.data?.description ?? "-"}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        component="h3"
                                        sx={{
                                            ...fontPreset.titleS,
                                            fontSize: downMediumScreen
                                            ? fontPreset.titleMMobile.fontSize
                                            : fontPreset.titleS.fontSize,
                                            color: paletteColorConstant.black1,
                                            marginBottom: "12px",
                                        }}
                                        fontWeight={"bold"}
                                    >
                                        Event Category
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            ...fontPreset.body16Lig,
                                            fontSize: downMediumScreen
                                            ? fontPreset.body14Lig.fontSize
                                            : fontPreset.body16Lig.fontSize,
                                            color: paletteColorConstant.black2,
                                            lineHeight: "18.75px",
                                        }}
                                    >
                                        {printProductCategory(product?.data?.productDetail ?? [])}
                                    </Typography>
                                </Box>

                                <Typography
                                    component="h3"
                                    sx={{
                                        ...fontPreset.titleS,
                                        fontSize: downMediumScreen
                                            ? fontPreset.titleMMobile.fontSize
                                            : fontPreset.titleS.fontSize,
                                        color: paletteColorConstant.primaryBlue150,
                                        // marginBottom: "12px",
                                        textAlign: "center",
                                    }}
                                    fontWeight={"bold"}
                                >
                                    Starting from IDR {priceFormat(product?.data?.price ?? 0)}{" "}
                                    /day
                                </Typography>

                                <Box>
                                    <Typography
                                        component="h3"
                                        sx={{
                                            ...fontPreset.titleS,
                                            fontSize: downMediumScreen
                                            ? fontPreset.titleMMobile.fontSize
                                            : fontPreset.titleS.fontSize,
                                            color: paletteColorConstant.black1,
                                            marginBottom: "12px",
                                        }}
                                        fontWeight={"bold"}
                                    >
                                        Recommended for or Compatible with:
                                    </Typography>

                                    <Box className="spesification__section">
                                        <ul
                                            style={{
                                                columns: downMediumScreen ? "1" : "2",
                                                columnGap: "56px",
                                                listStylePosition: "outside",
                                                margin: "0",
                                                padding: "0 0 0 18px",
                                            }}
                                        >
                                            {product.data.specification.map((val, index) => (
                                                <li
                                                    key={`${val}-${index + 1}`}
                                                    style={{
                                                        ...fontPreset.body16Lig,
                                                        fontSize: downMediumScreen
                                                            ? fontPreset.body14Lig.fontSize
                                                            : fontPreset.body16Lig.fontSize,
                                                        color: paletteColorConstant.black2,
                                                        margin: "0 0 4px 0",
                                                    }}
                                                >
                                                    {val}
                                                </li>
                                            ))}
                                        </ul>
                                    </Box>

                                    <Box sx={{ marginTop: "20px" }}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                ...fontPreset.body12Lig,
                                                color: paletteColorConstant.black2,
                                                fontStyle: "italic"
                                            }}
                                        >
                                            {product.data?.note ? `(*) ${product.data?.note}` : ""}
                                        </Typography>
                                    </Box>

                                    <ConsultationDialogButton
                                        buttonTitle="Consult With Us"
                                        sxProps={{
                                            width: "100%",
                                            marginTop: "40px",
                                            color: paletteColorConstant.white1,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </>
            ) : error ? (
                <Container>
                    <Box>Terjadi Kesalahan Pada Server.</Box>
                </Container>
            ) : // LAINNYA
            null }
        </Box>
    )
}

export default ProductDetail