import { LazyLoadImage } from "react-lazy-load-image-component";
// MATERIAL UI
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material";
// COMPONENTS
import Testimoni from "../Testimoni";
// CONSTANT
import { fontPreset } from "../../../constant/fontConstant";

const style = {
    ourClientSection: {
      root: { display: "flex", columnGap: "125px", rowGap: "100px" },
      clientImage: {
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 0%",
      },
    },
    ourClientImageList: { 
        display: "flex",
        flexDirection: "column",
    },
    testimoniSection: {
        display: "flex",
        flexDirection: "column",
    }
};  

const DUMMY_CLIENT_LIST = [8,31,16,17,4,11,3,20,28,12,24,29,21,13,3,22,25,26]

const ClientSection = () => {
    const theme = useTheme()
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

    return (
        <Container>
            <Box className="our__client__wrap"
                sx={{ ...style.ourClientSection.root, flexDirection: downMediumScreen ? "column" : "row" }}
            >
                <Box className="our__clients__section"
                    sx={{ ...style.ourClientImageList, rowGap: downMediumScreen ? "24px" : "40px" }}
                >
                    <Typography
                        component="h2"
                        align="center"
                        sx={downMediumScreen ? { ...fontPreset.body20Med } : { ...fontPreset.titleL }}
                        style={{ lineHeight: "normal" }}
                    >
                        Klien Kami
                    </Typography>

                    <Grid container rowSpacing={"10px"} columnSpacing={"20px"}>
                        {   DUMMY_CLIENT_LIST.map((number, idx) => (
                                <Grid item xs={2} key={`client-img-${idx + 1}`}>
                                    <LazyLoadImage 
                                        src={`/images/clients/client-${number}.png`}
                                        height={30}
                                        alt={`klien-${number}`}
                                        style={{ aspectRatio: "2 / 1" }}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>

                    <Typography
                        component="h2"
                        align="center"
                        style={ downMediumScreen ? fontPreset.body14Med : fontPreset.body20Med }
                        sx={{ lineHeight: "normal" }}
                    >
                        dan 50+ lainnya
                    </Typography>
                </Box>

                <Box sx={{ ...style.testimoniSection, maxWidth: downMediumScreen ? "100%" : "50%", rowGap: downMediumScreen ? "24px" : "36px" }}>
                    <Typography
                        component="h2"
                        align="center"
                        sx={downMediumScreen ? { ...fontPreset.body20Med } : { ...fontPreset.titleL }}
                        style={{ lineHeight: "normal" }}
                    >
                        Kata Mereka
                    </Typography>

                    <Testimoni />
                </Box>
            </Box>
        </Container>
    )
}

export default ClientSection