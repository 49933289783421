import { useNavigate, Link } from 'react-router-dom';
// MATERIAL UI
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
// COMPONENTS
import HorizontalCard from "../../../components/Home/HorizontalCard";
// CONSTANTS
import { fontPreset, mobileFontPreset } from "../../../constant/fontConstant";
import { BODY_KIRI, LAYANAN_LIST, TITLE_KIRI, TITLE_TOMBOL_KIRI } from "../../../constant/utils/contentBody";

const style = {
    root: {
      display: "flex",
      justifyContent: "space-between",
      color: "white"
    },
    leftColumn: {
      display: "flex",
      flexDirection: "column",
      rowGap: "20px",
    },
    rightColumn: {
      display: "flex",
      flexDirection: "column",
      rowGap: "40px",
    },
}

const OurServicesSection = () => {
    const navigate = useNavigate()
    const theme = useTheme();
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

    const onClickServicesButton = (url) => {
        navigate(url);
    }

    return (
        <Container>
            <Box className="our__services__root"
                sx={{
                    ...style.root,
                    flexDirection: downMediumScreen ? "column" : "row",
                    rowGap: downMediumScreen ? "40px" : "0",
                }}
            >
                <Box className="our__services__left"
                    sx={{
                        ...style.leftColumn,
                        justifyContent: downMediumScreen ? "center" : "flex-start",
                        maxWidth: downMediumScreen ? "100%" : "45%",
                    }}
                >
                
                    <Box className="our__services__left__title"
                        sx={{ display: downMediumScreen ? "none" : "flex" }}
                    >
                        <Typography
                            component={"h1"}
                            sx={{ lineHeight: "90px" }}
                            style={fontPreset.titleXM}
                        >
                            { TITLE_KIRI }
                        </Typography>
                    </Box>

                    {/* MOBILE VIEW TEXT */}
                    <Box className="our__services__left__title__mobile"
                        sx={{ display: downMediumScreen ? "flex" : "none" }}
                    >
                        <Typography
                            component={"h1"}
                            sx={{ lineHeight: "normal" }}
                            style={fontPreset.body40Med}
                        >
                            { TITLE_KIRI }
                        </Typography>
                    </Box>

                    <Typography
                        component={"p"}
                        style={downMediumScreen ? mobileFontPreset.body14Lig : fontPreset.body20Lig}
                        sx={{ lineHeight: "normal" }}
                        align={"left"}
                    >
                        { BODY_KIRI }
                    </Typography>

                    <Box>
                        <Button 
                            component={Link}
                            to={"/product-services?section=our-packages"}
                            variant="contained"
                            size="large"
                        >
                            { TITLE_TOMBOL_KIRI }
                        </Button>
                    </Box>
                </Box>

                <Box className="our__services__right"
                    sx={{ ...style.rightColumn, width: downMediumScreen ? "100%" : "50%" }}
                >
                    { 
                        LAYANAN_LIST.map((service, index) => (
                            <HorizontalCard
                                key={`services-${index + 1}`}
                                title={service.title}
                                body={service.body}
                                imageSrc={service.gambar}
                                buttonTitle={service.title_tombol}
                                buttonAction={() => { onClickServicesButton(service.navigasi) }}
                            />
                        )) 
                    }
                </Box>
            </Box>
        </Container>
    )
}

export default OurServicesSection