import React from "react";
import { scroller } from "react-scroll";
import { Fade, Slide } from "@mui/material";

export const stylePreset = {
  formSectionStyle: { display: "flex", flexDirection: "column", rowGap: "4px" },
  columnFlex: { display: "flex", flexDirection: "column" },
  rowFlex: { display: "flex", flexDirection: "row" },
};

export const UpTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FadeTransition = React.forwardRef(function Transition(props, ref) {
  return <Fade in={true} ref={ref} {...props} />;
});

export const scrollToContent = (section, options = { duration: 1000, smooth: true }) => {
  scroller.scrollTo(section, options);
};
