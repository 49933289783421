import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Mousewheel } from "swiper";
// MATERIAL ICONS
import LeftSliderIcon from "../icons/arrows/LeftSliderIcon";
import RightSliderIcon from "../icons/arrows/RightSliderIcon";
import LeftSliderIconFill from "../icons/arrows/LeftSliderIconFill";
import RightSliderIconFill from "../icons/arrows/RightSliderIconFill";
// COMPONENTS
import ArticleCard from "./ArticleCard";
//MATERIAL UI
import { Box, Skeleton, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
//CONSTANT
import { paletteColor, paletteColorConstant } from "../../constant/colorConstant";
// ARTICLES
import { getAllArticles } from "../../features/article/articleSlice";

const loadingItems = [1,2,3]
const style = {
  nextPrevSwiper: {
    fontSize: "28px",
    padding: 0,
    transition: "color .25s ease",
    color: "#CACACA",
  },
  enableButton: {
    backgroundColor: "white",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "9px",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    opacity: 0,
    transition: "opacity .2s ease",
    "&:hover": {
      backgroundColor: paletteColorConstant.primaryBlue150,
      "& .icon__slider__pagination": {
        color: "#FFF",
      },
    },
  },
  disableButton: {
    backgroundColor: "#f9f9f9",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "9px",
    cursor: "auto",
  },
  prevButton: {
    position: "absolute",
    top: "33%",
    left: "calc((100% - 1152px) / 2)",
    "&:hover": {
      cursor: "pointer"
    },
    zIndex: 10,
  },
  nextButton: {
    position: "absolute",
    top: "33%",
    right: "calc((100% - 1152px) / 2)",
    "&:hover": {
      cursor: "pointer"
    },
    zIndex: 10,
  },
};

const StyledBox = styled(Box)``;

const ArticleSlider = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const downLargeScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const articleList = useSelector((state) => state.articles)
  const { loading, success, articles, error } = articleList

  const swiperRef = React.useRef(null);
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  const [activeSlide, setActiveSlide] = useState({ begining: true, end: false })

  useEffect(() => {
    dispatch(getAllArticles({}))
  
    return () => {}
  }, [])

  return (
    <>
      { loading ? 
          <Box sx={{ 
            padding: downMediumScreen ? "24px calc(32px / 2)" : "24px calc((100% - 1152px) / 2)",
            display: "flex",
            columnGap: "24px"
          }}
        >
            {   loadingItems.map((item) => (
                  <Skeleton
                      key={`article__loading__${item}`}
                      variant="rectangular"
                      animation="wave"
                      width={275}
                      height={downMediumScreen ? 136 : 338}
                      sx={{ borderRadius: "20px", padding: "0", margin: "0" }}
                  />
              )) 
            }
          </Box> : 
        success ?
          articles.data.length !== 0 ? 
            <Box
              sx={{
                position: "relative",
                "&:hover": { "& .slider__pagination__button": { opacity: 1 } }
              }}
            >
              <Swiper
                ref={swiperRef}
                modules={[Navigation, Mousewheel]}
                navigation={{ prevEl, nextEl }}
                mousewheel={{ forceToAxis: true, sensitivity: 0.85 }}
                slidesPerView={"auto"}
                spaceBetween={24}
                style={{
                  padding:  
                    downMediumScreen ? "24px calc(32px / 2)" : 
                    downLargeScreen ? "24px" : 
                    "24px calc((100% - 1152px) / 2)",
                  height: "100%",
                  width: "100%",
                  overflowX: "clip",
                }}
                onSlideChange={(swiper) => { 
                  if(swiper.isEnd !== true && swiper.isBeginning !== true) setActiveSlide({ begining: false, end: false })
                }}
                onToEdge={(swiper) => {
                  if(swiper.isBeginning) setActiveSlide({ begining: true, end: false })
                  if(swiper.isEnd) setActiveSlide({ begining: false, end: true })
                }}
              >
                {articles.data.map((item, index) => (
                  <SwiperSlide
                    key={`article-${index + 1}`}
                    style={{ padding: "0", width: "275px" }}
                  >
                    <ArticleCard 
                      imageTitle={`article-${index + 1}`}
                      data={item}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
      
              <StyledBox
                ref={(node) => setPrevEl(node)}
                sx={{ ...style.prevButton, padding: "7px" }}
                className="slider__pagination__button"
              >
                {   activeSlide.begining ? 
                      <LeftSliderIcon
                        width={downMediumScreen ? '24px' : '32'}
                        height={downMediumScreen ? '24px' : '32'}
                        color={paletteColor.blue100}
                      /> :
                    // NON FIRST
                      <LeftSliderIconFill
                        width={downMediumScreen ? '24px' : '32'}
                        height={downMediumScreen ? '24px' : '32'}
                        color={paletteColor.blue100}
                      />
                }
              </StyledBox>

              <StyledBox
                ref={(node) => setNextEl(node)}
                sx={{ ...style.nextButton, padding: "7px" }}
                className="slider__pagination__button"
              >
                {   activeSlide.end ? 
                      <RightSliderIcon
                        width={downMediumScreen ? '24px' : '32'}
                        height={downMediumScreen ? '24px' : '32'}
                        color={paletteColor.blue100}
                      /> :
                    // NON LAST
                      <RightSliderIconFill
                        width={downMediumScreen ? '24px' : '32'}
                        height={downMediumScreen ? '24px' : '32'}
                        color={paletteColor.blue100}
                      />
                }
              </StyledBox>
            </Box> :
          // KOSONG
            <Box>Kosong....</Box> :
        error ? 
          <Box>Error...</Box> :
        null
      }
    </>
  );
};

export default ArticleSlider;
