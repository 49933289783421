import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component";
// MATERIAL UI
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
// CONSTANTS
import { TENTANG_KAMI_BODY, TENTANG_KAMI_TITLE, TENTANG_KAMI_TITLE_TOMBOL } from "../../../constant/utils/contentBody"
import { paletteColorConstant } from "../../../constant/colorConstant"
import { fontPreset } from "../../../constant/fontConstant";

const style = {
    root: { 
        padding: "146px 0 0 0",
        background: "linear-gradient(rgba(248, 248, 248, 1), rgba(232, 232, 232, 1), rgba(195, 195, 195, 1))"
    },
    container: {
        height: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        rowGap: "60px"
    },
    contentText: {
        maxWidth: "356px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
}

const AboutUsSection = () => {
    return (
        <div style={style.root}>
            <Container sx={style.container}>
                <div style={style.contentText}>
                    <Typography
                        variant="h6"
                        style={{ ...fontPreset.titleL, color: paletteColorConstant.black2 }}
                    >
                        { TENTANG_KAMI_TITLE }
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{ ...fontPreset.body16Lig, color: paletteColorConstant.black2, marginTop: "12px", lineHeight: "normal" }}
                        align="center"
                    >
                        { TENTANG_KAMI_BODY }
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ marginTop: "20px" }}
                        to={"/about-us"}
                        component={Link}
                    >
                        { TENTANG_KAMI_TITLE_TOMBOL }
                    </Button>
                </div>

                <LazyLoadImage
                    className="section-five-image"
                    src={"/images/home/section_5/team_photo.png"}
                    alt={"divine-pro-team"}
                />
            </Container>
        </div>
    )
}

export default AboutUsSection