import React from "react";
// MATERIAL UI
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
// CONSTANT
import { paletteColorConstant } from "../../constant/colorConstant";
import { fontPreset } from "../../constant/fontConstant";

const ImageAndDesc = ({ body, icons }) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box 
      sx={{ 
        display: "flex", 
        columnGap: "20px",
        rowGap: "12px",
        alignItems: "center",
        flexDirection: downMediumScreen ? "column" : "row"
      }}
    >
      {icons}

      <Typography
        variant="body1"
        sx={downMediumScreen ? fontPreset.body18Lig : fontPreset.body20Lig}
        style={{ color: paletteColorConstant.white2 }}
      >
        {body}
      </Typography>
    </Box>
  );
};

export default ImageAndDesc;
