import { useState } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// MATERIAL UI
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
// ICONS
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
//  HOOKS
import { ConsultationContext } from '../hooks/consultationContext'
// CONSTANTS
import { fontPreset } from '../constant/fontConstant'
import { paletteColor } from '../constant/colorConstant'
import { ColorlibStepIcon, QontoConnector } from '../constant/textFieldConstant'
import ConsultationPerStep from '../components/Consutation/ConsultationPerStep'
import ThankYouStep from '../components/Consutation/ThankYouStep'

const style = {
    header: {
        display: "flex",
        position: "relative",
        alignItems: {xs: "stretch", sm: "center"},
        flexDirection: {xs: "column", sm: "row"},
        paddingTop: {xs: "20px", sm: "7px"},
    },
    logo: {
        flex: "1 1 0%",
        display: "flex",
        justifyContent: "center"
    },
    backButton: {
        position: {xs: "relative", sm: "absolute"},
        left: {xs: "0", sm: "24px"}
    },
    stepperAndTitleContainer: { 
        padding: {xs: "20px 0 0 0", sm: "80px 0 0 0"},
        color: paletteColor.primaryGray
    },
    stepperAndTitle: { 
        display: "flex",
        flexDirection: "column",
        rowGap: {xs: "20px", sm: "44px"},
    },
    content: {
        marginTop: {xs: "40px", md: "125px"},
        paddingBottom: {xs: "40px", md: "125px"}
    }
}

const steps = [
    'Step 1',
    'Step 2',
    'Step 3',
    'Step 4',
];

const HeaderConsultation = () => {
    const theme = useTheme()
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

    return (
        <Box>
            <Container sx={style.header}>
                <Box sx={style.backButton}>
                    <Button
                        variant="text"
                        size="small"
                        startIcon={<ArrowBackIosNewIcon />}
                        LinkComponent={Link}
                        to="/"
                        sx={{ textTransform: "capitalize" }}
                    >
                        Kembali ke Home
                    </Button>
                </Box>

                <Box sx={style.logo}>
                    <Link to={"/"}>
                        <LazyLoadImage
                            src={"/divine-logo-dark.png"}
                            alt={"Divine Pro Logo"}
                            height={downMediumScreen ? "65px" : "80px"}
                            width={downMediumScreen ? "65px" : "80px"}
                        />
                    </Link>
                </Box>
            </Container>
        </Box>
    )
}

const ConsultationPage = () => {
    const theme = useTheme()
    const upSmallScreen = useMediaQuery(theme.breakpoints.up("sm"))

    const [step, setStep] = useState(0)

    const handleNext = () => { setStep((step) => step + 1) }
    const handlePrev = () => { setStep((step) => step - 1) }

    if(step === 4) {
        return (
            <Box sx={{ background: "linear-gradient(180deg, #FFF 0%, #FFF 0.01%, #E8E8E8 100%)", minHeight: "100vh" }}>
                <HeaderConsultation />
                
                <ConsultationContext.Provider
                    value={{ 
                        nextStep: handleNext,
                        prevStep: handlePrev
                    }}
                >
                    <Container>
                        <ThankYouStep />
                    </Container>
                </ConsultationContext.Provider>
            </Box>
        )
    }

    return (
        <Box>
            {/* HEADER */}
            <HeaderConsultation />

            <ConsultationContext.Provider
                value={{ 
                    nextStep: handleNext,
                    prevStep: handlePrev
                }}
            >
                <Box sx={style.stepperAndTitleContainer}>
                    <Container maxWidth={"sm"}>
                        <Box sx={style.stepperAndTitle}>
                            <Typography
                                variant="body2"
                                sx={upSmallScreen ? fontPreset.body32Med : fontPreset.body20Med}
                                color={paletteColor.white50}
                                align="center"
                            >
                                Formulir Konsultasi
                            </Typography>

                            <Stepper
                                activeStep={step}
                                alternativeLabel
                                connector={<QontoConnector />}
                            >
                                {steps.map((label, i) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon} />
                                    </Step>
                                ))}
                            </Stepper>

                            <Typography
                                variant="body2"
                                sx={upSmallScreen ? fontPreset.body20Med : fontPreset.body14Med}
                                align="center"
                            >
                                Mohon Lengkapi Nama & Tempatmu Bekerja
                            </Typography>
                        </Box>
                    </Container>
                </Box>

                <Box sx={style.content}>
                    <Container>
                        <ConsultationPerStep step={step} />
                    </Container>
                </Box>

            </ConsultationContext.Provider>
        </Box>
    )
}

export default ConsultationPage