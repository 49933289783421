import moment from 'moment'
import { useContext } from 'react'
// MATERIAL UI
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTheme } from '@mui/material'
// CONSTANTS
import { ConsultationContext } from '../../hooks/consultationContext'
import { fontPreset } from "../../constant/fontConstant";
import { priceFormat } from '../../constant/textFieldConstant'
import { paletteColor } from '../../constant/colorConstant'

const style = {
    centerAlign: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    rootFormContainer: {
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        columnGap: "60px",
        rowGap: { xs: "32px", md: "" },
    },
    formSection: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        rowGap: "28px"
    },
    rowForm: { 
        display: "flex",
        rowGap: "20px"
    },
    confirmationSection: {
        padding: "45px",
        position: "relative",
        flexDirection: "column",
        justifyContent: "space-between",
        rowGap: "40px"
    },
    buttonSection: {
        display: "flex",
        justifyContent: "flex-end",
        columnGap: "20px"
    },
    step4FontMobileTitle: {
        ...fontPreset.body10Med,
        color: paletteColor.white50,
        marginBottom: "8px",
    },
    step4FontMobile: {
        ...fontPreset.body14Reg,
        color: paletteColor.primaryGray,
        lineHeight: "normal"
    },
    step4ValueFont: {
        ...fontPreset.body16Reg,
        color: paletteColor.primaryGray,
        lineHeight: "normal"
    },
    step4FontWeb: {
        ...fontPreset.body12Bold,
        color: paletteColor.white50,
        marginBottom: "8px",
    },
}

const ConfirmationDataStep = () => {
    const theme = useTheme();
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

    const consultationState = useContext(ConsultationContext)

    const step = 4
    const loading = false
    
    const name = "NAMA"
    const contact = "email@gmail.com"
    const companyName = "COMPANY NAME"
    const companyType = "COMPANY TYPE"
    const eventType = "EVENT TYPE"
    const totalAttendance = "200"
    const cityName = "CITY TYPE"
    const budgetEstimation = 12000000
    const eventDesc = "Perusahaan kami ingin membuat event Townhall meeting untuk 1000 orang secara online dengan 10 orang di studio. Perkiraan sementara kami, kami akan membutuhkan greenscreen, lighting, dan juga beberapa kru untuk membantu kami."
    const eventDate = new Date()

    const submitConsultation = () => {
        consultationState.nextStep()
    }

    // const handleCloseErrorSubmitSnackbar = (event, reason) => {
    //     if (reason === 'clickaway') { return }
    
    //     setErrorSubmit({ error: false, helperText: "" })
    // }

    return (
        <div className="confirmation__content"
            style={{ 
                ...style.confirmationSection,
                display: step !== 4 ? "none" : "flex",
                padding: downMediumScreen ? "0 8px 45px 8px" : "0 0 0 0",
            }}
        >
            <div style={style.rootFormContainer}>
                <div className="confirmation__form" style={style.formSection}>
                    <div style={{ ...style.rowForm, flexDirection: downMediumScreen ? "column" : "row", columnGap: downMediumScreen ? "32px" : "40px" }}>
                        <div className="nama__confirm__section"
                            style={{ width: "100%" }}
                        >
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                            >
                                Nama Kamu
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobile : style.step4ValueFont}
                                flexWrap={"wrap"}
                            >
                                {name}
                            </Typography>
                        </div>

                        <div className="email__confirm__section"
                            style={{ width: "100%" }}
                        >
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                            >
                                Email Kamu
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobile : style.step4ValueFont}
                                flexWrap={"wrap"}
                            >
                                {contact}
                            </Typography>
                        </div>

                        <div className="company__type__section"
                            style={{ width: "100%" }}
                        >
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                            >
                                Jenis Instansi
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobile : style.step4ValueFont}
                                flexWrap={"wrap"}
                            >
                                {companyType}
                            </Typography>
                        </div>
                    </div>

                    <div style={{ ...style.rowForm, flexDirection: downMediumScreen ? "column" : "row", columnGap: downMediumScreen ? "32px" : "40px" }}>
                        <div className="company__name__section"
                            style={{ width: "100%", display: companyName === "" && "none" }}
                        >
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                            >
                                Nama Instansi
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobile : style.step4ValueFont}
                                flexWrap={"wrap"}
                            >
                                {companyName ?? ""}
                            </Typography>
                        </div>

                        <div className="event__type__section"
                            style={{ width: "100%" }}
                        >
                            <Typography
                                variant="body2"
                                sx={
                                    downMediumScreen
                                    ? style.step4FontMobileTitle
                                    : style.step4FontWeb
                                }
                            >
                                Jenis Event
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobile : style.step4ValueFont}
                                flexWrap={"wrap"}
                            >
                                {eventType}
                            </Typography>
                        </div>

                        <div className="total__attendance__section"
                            style={{ width: "100%" }}
                        >
                            <Typography
                                variant="body2"
                                sx={
                                    downMediumScreen
                                    ? style.step4FontMobileTitle
                                    : style.step4FontWeb
                                }
                            >
                                Total Jumlah Peserta
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobile : style.step4ValueFont}
                                flexWrap={"wrap"}
                            >
                                {totalAttendance}
                            </Typography>
                        </div>
                    </div>

                    <div style={{ ...style.rowForm, flexDirection: downMediumScreen ? "column" : "row", columnGap: downMediumScreen ? "32px" : "40px" }}>
                        <div className="event__date__confirm__section"
                            style={{ width: "100%" }}
                        >
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                            >
                                Tanggal Event Kamu
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobile : style.step4ValueFont}
                                flexWrap={"wrap"}
                            >
                                {moment(eventDate).format("DD [/] MM [/] YYYY")}
                            </Typography>
                        </div>

                        <div className="location__confirm__section"
                            style={{ width: "100%" }}
                        >
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                            >
                                Lokasi Event Kamu
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobile : style.step4ValueFont}
                                flexWrap={"wrap"}
                            >
                                {cityName}
                            </Typography>
                        </div>

                        <div className="description__confirm__section"
                            style={{ width: "100%" }}
                        >
                            <Typography
                                variant="body2"
                                sx={
                                    downMediumScreen
                                        ? style.step4FontMobileTitle
                                        : style.step4FontWeb
                                }
                            >
                                Deskripsi Event Singkat Kamu
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobile : style.step4ValueFont}
                                flexWrap={"wrap"}
                            >
                                {eventDesc}
                            </Typography>
                        </div>
                    </div>

                    <div style={{ ...style.rowForm, columnGap: downMediumScreen ? "32px" : "40px" }}>
                        <div className="hollow__section" style={{ flexBasis: "33.333%", display: downMediumScreen ? "none" : "block" }} />

                        <div className="hollow__section" style={{ flexBasis: "33.333%", display: downMediumScreen ? "none" : "block" }} />

                        <div className="budget__confirm__section" style={{ flexBasis: downMediumScreen ? "100%" : "33.333%" }}>
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobileTitle : style.step4FontWeb}
                            >
                                Estimasi Budget Kamu (dalam IDR)
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={downMediumScreen ? style.step4FontMobile : style.step4ValueFont}
                                flexWrap={"wrap"}
                            >
                                IDR {priceFormat(budgetEstimation)}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>

            <Box className="action__button" sx={style.buttonSection}>
                <Button
                    onClick={() => { consultationState.prevStep() }}
                    size="large"
                >
                    Edit
                </Button>
                
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={submitConsultation}
                    size="large"
                >
                    Kirim
                </LoadingButton>
            </Box>

            <Box className="error__submit__snackbar">
                {/* <Snackbar
                    open={errorSubmit.error}
                    autoHideDuration={2000}
                    onClose={handleCloseErrorSubmitSnackbar}
                    message={errorSubmit.helperText}
                /> */}
                
                <Snackbar
                    open={false}
                    autoHideDuration={2000}
                    onClose={() => style.step4ValueFont}
                    message={"Err message"}
                />
            </Box>
        </div>
    )
}

export default ConfirmationDataStep