import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// MATERIAL UI
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
// CONSTANT
import { fontPreset, mobileFontPreset } from "../../constant/fontConstant";
import { paletteColorConstant } from "../../constant/colorConstant";
// COMPONENT
import CaseStudyDetails from "../Home/CaseStudyDetails";

const style = {
  potfolioDesc: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

const PortFolioItem = ({
  title,
  body,
  imgSrc,
  reverse = false,
  dataAos = "",
  details
}) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box className="portfolio__item"
      sx={{
        display: "flex",
        flexDirection: downMediumScreen ? "column" : reverse ? "row-reverse" : "row",
        columnGap: "40px",
        rowGap: downMediumScreen ? "30.45px" : "20px",
      }}
      data-aos={dataAos}
      data-aos-duration={900}
      data-aos-offset={300}
    >
      <Box className="portofolio__img" 
        sx={{ width: downMediumScreen ? "100%" : "616px", height: downMediumScreen ? "264.55px" : "420px" }}
      >
        <LazyLoadImage
          alt={`porfolio-${title}`}
          src={imgSrc}
          width={downMediumScreen ? "100%" : "616px"}
          height={"100%"}
          style={{ objectFit: "cover" }}
          effect="blur"
        />
      </Box>

      <Box className="portfolio__desc" 
        sx={style.potfolioDesc}
      >
        <Typography
          component={"h2"}
          sx={ downMediumScreen ? mobileFontPreset.titleM : fontPreset.titleL}
          style={{ color: paletteColorConstant.black1 }}
        >
          {title}
        </Typography>

        <Typography
          variant="body1"
          sx={downMediumScreen ? fontPreset.body14Lig : fontPreset.body24Lig}
          style={{ 
            color: downMediumScreen ? paletteColorConstant.black1 : paletteColorConstant.black2,
            lineHeight: downMediumScreen ? "16.41px" : "28.13px",
            marginTop: downMediumScreen ? "12px" : "20px"
          }}
        >
          {body}
        </Typography>

        <Box className="portfolio__action__button"
          sx={{ 
            display: "flex",
            justifyContent: downMediumScreen ? "center" : "flex-start",
          }}
        >
          <CaseStudyDetails
            images={details.photoList}
            title={title}
            about={details.about}
            quotes={details.quotes}
            provides={details.provides}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PortFolioItem;
