// MATERIAL UI
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
// CONSTANTS
import { fontPreset } from '../../constant/fontConstant'
import { paletteColorConstant } from '../../constant/colorConstant'

const GetInTouchCard = ({ 
  avatar = <></>,
  description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  buttonTitle = 'test',
  onClickButton
}) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div 
      style={{ 
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: "space-between",
        maxWidth: downMediumScreen ? '100%' : '282px',
      }}
    >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: downMediumScreen ? '12px' : '20px' }}>
            { avatar }

            <Typography
              sx={{ color: paletteColorConstant.black2, textAlign: 'center', lineHeight: "normal", maxWidth: downMediumScreen ? "238px" : "100%" }}
              style={downMediumScreen ? fontPreset.body14Lig : fontPreset.body16Lig}
            >
                { description }
            </Typography>
        </div>

        <Button 
          onClick={onClickButton}
          variant='contained'
          color='primary'
          size='large'
          sx={{ marginTop: downMediumScreen ? '20px' : '28px', color: 'white' }}
        >
            { buttonTitle }
        </Button>
    </div>
  )
}

export default GetInTouchCard