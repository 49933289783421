import { Link } from "react-router-dom";
// MATERIAL UI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// ICONS
import CloseIcon from "@mui/icons-material/Close";
// CONSTANTS
import { stylePreset } from "../../../constant/stylePreset";
import { fontPreset } from "../../../constant/fontConstant";

const menu = [
  { label: "Home", link: "/" },
  { label: "Product & Services", link: "/product-services" },
  { label: "Portfolio", link: "/portfolio" },
  { label: "About", link: "/about-us" },
  { label: "Articles", link: "/articles" },
];

const MobileMenuDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
      sx={{ background: "rgba(44, 44, 44, 0.2)", backdropFilter: "blur(10px)" }}
      PaperProps={{
        style: { background: "transparent" },
      }}
    >
      <Box
        className="mobile__menu__root"
        sx={{
          position: "relative",
          padding: "38px 20px 0",
          ...stylePreset.columnFlex,
          rowGap: "60px",
        }}
      >
        <Box
          className="mobile__menu__close"
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <IconButton
            edge="start"
            onClick={handleClose}
            aria-label="close"
            sx={{ color: "white", height: "35px", width: "35px" }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>

        <Box
          className="mobile__menu__list"
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "40px",
            alignItems: "center",
          }}
        >
          {menu.map((val, index) => (
            <Typography
              key={`mobile-link-${index + 1}`}
              sx={{
                textDecoration: "none",
                ...fontPreset.body24Lig,
                color: "white",
              }}
              onClick={handleClose}
              component={Link}
              to={val.link}
            >
              {val.label}
            </Typography>
          ))}
        </Box>

        <Box
          className="mobile__menu__action"
          sx={{
            ...stylePreset.columnFlex,
            alignItems: "center",
            rowGap: "24px",
          }}
        >
          <Box>
            <Button
              color="primary"
              variant={"contained"}
              LinkComponent={Link}
              to={"/consultation"}
            >
              Konsultasi Gratis
            </Button>
          </Box>

          <Box>
            <Button variant="menuMobile" size="large" onClick={handleClose}>
              CLOSE MENU
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MobileMenuDialog;
