import { Helmet } from "react-helmet-async";
import "./style/home.css";
// MATERIAL UI
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from "@mui/material";
// CONSTANT
import { paletteColor } from "../constant/colorConstant";
// COMPONENTS
import HeroSlider from "../components/Home/sections/HeroSlider";
import ClientSection from "../components/Home/sections/ClientSection";
import ContactUsSection from "../components/Home/sections/ContactUsSection";
import ArticleSection from "../components/Home/sections/ArticleSection";
import AboutUsSection from "../components/Home/sections/AboutUsSection";
import OurServicesSection from "../components/Home/sections/OurServicesSection";
import PortfolioSection from "../components/Home/sections/PortfolioSection";

const style = {
  articleSection: {
    root: {
      minHeight: "476px",
      background: "linear-gradient(180deg, #FFF 24.48%, #F8F8F8 89.06%)"
    },
  },
  contactUsSection: {
    root: {
      minHeight: "471px",
      backgroundColor: "#F8F8F8",
      display: "flex",
      flexDirection: "column",
    },
  },
};

const Home = () => {
  const theme = useTheme();
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Helmet>
          <title>Divine Pro</title>
          <meta name="description" content="Jasa penyewaan multicam system, foto dokumentasi, video dokumentasi, drone, photo booth, dan lain-lain. Melayani untuk daerah Jakarta, Bogor, Depok, Tangerang, Bekasi, dan daerah lainnya di luar Jabodetabek." />

          <meta property="og:title" content="Divine Pro" />
          <meta property="og:description" content="Jasa penyewaan multicam system, foto dokumentasi, video dokumentasi, drone, photo booth, dan lain-lain. Melayani untuk daerah Jakarta, Bogor, Depok, Tangerang, Bekasi, dan daerah lainnya di luar Jabodetabek." />
          <meta property="og:site_name" content="Divine Pro" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content="/divine-logo.png" />
      </Helmet>
    
      <div className="homepage__root" style={{ position: "relative" }}>
        <div className="section__one">
          <HeroSlider />
        </div>

        <section className="portofolio__section">
          <PortfolioSection />
        </section>

        <section className="our__services"
          style={{ 
            background: `linear-gradient(180deg, #000000 19.76%, ${paletteColor.black150} 84.74%)`,
            padding: downMediumScreen ? "80px 0 80px 0" : "170px 0"
          }}
        >
          <OurServicesSection />
        </section>

        <section className="our__client"
          style={{ 
            padding: downMediumScreen ? "80px 0 120px 0" : "120px 0 300px 0",
            backgroundColor: paletteColor.black150,
            color: "white"
          }}
        >
          <ClientSection />
        </section>

        <section className="article__section"
          style={{ 
            ...style.articleSection.root,
            overflowX: "hidden",
            padding: downMediumScreen ? "80px 0 80px 0" : "178px 0 174px 0"
          }}
        >
          <ArticleSection />
        </section>

        <section className="contact_us" 
          style={{ ...style.contactUsSection.root, padding: downMediumScreen ? "80px 0 100px 0" : "40px 0 144px 0" }}
        >
          <ContactUsSection />
        </section>

        <section className="about_us"
          style={{ display: downMediumScreen ? "none" : "block" }}
        >
          <AboutUsSection />
        </section>
      </div>
    </>
  );
};

export default Home;
