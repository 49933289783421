import React from 'react'
import PropTypes from 'prop-types'
// MATERIAL UI
import { Box, Typography } from '@mui/material'
// COMPONENTS
import { NumberFormatCustom } from './NumberFormatCustom'
// CONSTANT
import { CustomTextField } from '../../constant/textFieldConstant'

const PriceTextField = ({ 
    label,
    placeholder = '100.000.000',
    size = 'small', 
    value, 
    onChange,
    textFieldError = { error: false, helperText: '' },
    setTextFieldError,
    readOnly
}) => {
  return (
    <Box>
        <Box mb={.5}>
            <Typography>{ label }</Typography>
        </Box>
        <CustomTextField
            placeholder={placeholder}
            size={size}
            value={value}
            onChange={(e) => {
                setTextFieldError && setTextFieldError({ error: false, helperText: '' })
                onChange(e.target.value)
            }}
            InputProps={{ 
                inputComponent: NumberFormatCustom,
                readOnly
            }}
            fullWidth
        />
    </Box>
  )
}

PriceTextField.propTypes = {
    label: PropTypes.string,
    textFieldLabel: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    textFieldError: PropTypes.object,
    backgroundColor: PropTypes.string,
    readOnly: PropTypes.bool
}

export default PriceTextField