import React, { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import validator from "validator";
import "../Home/style/ConsultationDialog.css";
// MATERIAL UI
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography, IconButton, DialogContent, useTheme, useMediaQuery } from "@mui/material";
// CONSTANT
import { stylePreset } from "../../constant/stylePreset";
import { paletteColorConstant } from "../../constant/colorConstant";
import { fontPreset, mobileFontPreset } from "../../constant/fontConstant";
import { CustomTextField, CustomDialog } from "../../constant/textFieldConstant";

const DownloadCompanyProfileForm = () => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [email, setEmail] = useState("");
  const [disable, setDisable] = useState("true");
  const [display, setDisplay] = useState("none");
  const [open, setOpen] = useState(false);
  const checkDisabledButton = () => {
    let state = true;
    if (disable === "true" || email === "") {
      return state;
    }
    state = false;
    return state;
  };
  const handleOnchange = (e) => {
    e.preventDefault();
    setDisable("false");
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    if (validator.isEmail(email)) {
      setOpen(true);
      
      let link = document.createElement('a')
      link.href = `${process.env.REACT_APP_API_PRODUCTION}/api/companyProfile/v1/?to=${email}`
      link.download = `divine-pro_company-profile.pdf`
      link.dispatchEvent(new MouseEvent('click'))
    } else {
      setEmail("");
      setDisplay("");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetAllValue = () => {
    setEmail("");
    setDisplay("none");
  };

  return (
    <Box
      sx={{
        ...stylePreset.columnFlex,
        rowGap: downMediumScreen ? "10px" : "20px",
        width: downMediumScreen ? "calc(100% - 20px)" : "70%",
        justifyContent: downMediumScreen ? "flex-end" : "center",
        height: "100%",
        paddingBottom: downMediumScreen ? "12px" : "0"
      }}
    >
      <Typography
        variant="h6"
        sx={{ color: paletteColorConstant.black2 }}
        style={ downMediumScreen ? mobileFontPreset.titleM : fontPreset.titleL }
      >
        Download Company Profile Kami
      </Typography>

      <Box className="email_field" sx={stylePreset.formSectionStyle}>
        <Typography
          sx={{ ...fontPreset.body14Reg, color: paletteColorConstant.black1 }}
        >
          Email Kamu
        </Typography>
        <CustomTextField
          placeholder="Contoh: divine@email.com"
          variant="outlined"
          size="medium"
          value={email}
          onChange={(e) => handleOnchange(e)}
          fullWidth
        />
      </Box>
      <Typography
        sx={{ color: "red", ...fontPreset.body14Lig, display: display }}
      >
        Mohon masukkan email sesuai format dalam contoh
      </Typography>

      <Box className="about_us_section" sx={{ marginTop: downMediumScreen ? "10px" : "20px" }}>
        <Button
          variant="contained"
          size={downMediumScreen ? "medium" : "large"}
          fullWidth
          disabled={checkDisabledButton()}
          onClick={() => handleSubmit()}
        >
          Download
        </Button>
      </Box>

      <CustomDialog
        open={open}
        fullWidth
        maxWidth={"xmd"}
        PaperProps={{
          style: { borderRadius: "12px" },
        }}
      >
        <DialogContent sx={{ overflow: "hidden" }}>
          <>
            <Box className="success__section"
              sx={{ height: downMediumScreen ? "580px" : "600px", position: "relative" }}
            >
                <Box className="success-dialog-img-wrapper">    
                  <LazyLoadImage className="success-dialog-image"
                    src={"/images/home/consultation_dialog/success_step.png"}
                    alt={"divine-pro-team"}
                    style={{ objectPosition: downMediumScreen && "60% 50%" }}
                  />

                  <div className="success-dialog-filter" />

                  <div className="success-dialog-content">
                    <IconButton
                      aria-label="close"
                      size="large"
                      onClick={() => {
                        handleClose();
                        resetAllValue();
                      }}
                      sx={{ position: "absolute", top: downMediumScreen ? "20px" : "40px", right: downMediumScreen ? "20px" : "60px" }}
                    >
                      <CloseIcon sx={{ color: paletteColorConstant.white1 }} />
                    </IconButton>
                  </div>
                </Box>
            </Box>

            <Box className="success__text"
              sx={{ 
                position: "absolute",
                left: "0",
                bottom: downMediumScreen ? "130px" : "22.5%",
                width: "100%",
                paddingX: downMediumScreen ? "20px" : "0"
              }}
            >
              <Typography
                component={"h3"}
                sx={{
                  fontSize: downMediumScreen ? fontPreset.titleM : fontPreset.titleXl,
                  color: paletteColorConstant.white1,
                  lineHeight: downMediumScreen ? "28px" : "49.22px",
                }}
                align={"center"}
              >
                Terima Kasih, <br />
                Company Profile sudah terkirim
              </Typography>
            </Box>
          </>
        </DialogContent>
      </CustomDialog>
    </Box>
  );
};

export default DownloadCompanyProfileForm