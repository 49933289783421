import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
// MATERIAL UI
import { Typography, Box, Container, useTheme, useMediaQuery, Grid } from "@mui/material";
// COMPONENTS
import PersonCard from "../components/AboutUs/PersonCard";
import GetInTouchForm from "../components/AboutUs/GetInTouchForm";
import DownloadCompanyProfileForm from "../components/AboutUs/DownloadCompanyProfileForm";
// CONSTANT
import { paletteColorConstant } from "../constant/colorConstant";
import { fontPreset, fontStyleConstant, mobileFontPreset } from "../constant/fontConstant";
import { boxShadowStyle } from "../constant/shadowConstant";
import { 
  EMBED_YOUTUBE_LINK,
  LEADERSHIP_TEAM,
  MISI_PARAGRAF_ATAS,
  MISI_PARAGRAF_BAWAH,
  PERJALANAN_DIVINE_PRO,
  VISI_PARAGRAF_ATAS,
  VISI_PARAGRAF_BAWAH
} from "../constant/utils/contentBody";

const style = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  section_one: {
    color: paletteColorConstant.white1,
    backgroundImage: "url(/images/AboutUs/section1.png)",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    maxHeight: "639px",
    webkitFilter: "grayscale(100%)",
    filter: "grayscale(100%)",
  },
  section_two: {
    borderRadius: "20px",
    border: "none",
    position: "relative",
  },
  section_three: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    backgroundImage: "url(/images/AboutUs/steps1.png)",
    backgroundRepeat: "no-repeat",
  },
  section_four: {
    backgroundImage: "url(/images/AboutUs/our_vision_illus.png)",
    backgroundSize: "cover",
    backgroundPosition: "0 70%",
    backgroundRepeat: "no-repeat",
    title: {
      ...fontStyleConstant.body4,
      fontWeight: "600",
      marginBottom: "20px",
    },
    body: {
      ...fontPreset.body20Reg,
      color: paletteColorConstant.white2,
      lineHeight: "28.13px",
    },
    mobileTitle: {
      ...fontPreset.body14Med,
      marginBottom: "12px",
    },
    mobileBody: {
      ...fontPreset.caption,
      color: paletteColorConstant.white2,
      lineHeight: "11.72px",
    },
    backgroundLinear: {
      background: "linear-gradient(180deg, rgba(66, 163, 227, 0.25) 1.33%, #144681 100%)",
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
  },
  section_five: { padding: { xs: "60px 0", md: "80px 0" } },
  section_six: { padding: { xs: "40px 0", md: "80px 0" } },
  section_seven: {
    display: "flex",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  section_one_title: {
    textAlign: "center",
    position: "absolute",
  },
  iframe: {
    position: "absolute",
    borderRadius: "20px",
    border: "none",
    ...boxShadowStyle.generalShadow,
  }
};

const AboutUs = () => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const downLarge2Screen = useMediaQuery(theme.breakpoints.down("lg2"))

  const scrollEvent = (e) => {
    let parallax = document.querySelector(".parallax");
    let offset = window.pageYOffset;

    parallax.style.backgroundPositionY = "-" + offset * 0.4 + "px";
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);

    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  return (
    <>
      <Helmet>
          <title>Tentang Kami</title>
          <meta name="description" content="Tentang Divine Pro" />

          <meta property="og:title" content="Tentang Kami" />
          <meta property="og:description" content="Tentang Divine Pro" />
          <meta property="og:site_name" content="Divine Pro" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content="/divine-logo.png" />
      </Helmet>

      <div className="AboutUs__container" style={style.container}>
        <Box className="parallax" 
          sx={{ 
              ...style.section_one,
              backgroundSize: downLarge2Screen ? "cover" : "contain",
              backgroundPosition: downLarge2Screen ? "45% 90%" : "0 0",
              height: downMediumScreen ? "262px" : "calc(90vh - 92px)",
            }}
        >
          <Typography
            sx={{ ...style.section_one_title, width: downMediumScreen ? "100%" : "783px", top: downMediumScreen ? "calc(50% - 20px)" : "35%", paddingX: "12px" }}
            style={ downMediumScreen ? { ...fontPreset.body16Reg, lineHeight: "18.75px" } : fontPreset.body32Reg }
          >
            Sejak 2018, Divine Production terus tumbuh meningkatkan
            kualitas pelayanannya
          </Typography>
        </Box>

        <Box sx={style.section_two}>
          <iframe
            width={downMediumScreen ? "80%" : "783"}
            height={downMediumScreen ? "200px" : "440"}
            style={{
              ...style.iframe,
              top: downMediumScreen ? "calc(50% - 60px)" : "-146px",
              left: downMediumScreen ? "10%" : "calc(50% - 391.5px)",
              background: paletteColorConstant.gray1
            }}
            src={EMBED_YOUTUBE_LINK}
            title="divine-pro-youtube"
            loading="lazy"
          />
        </Box>

        <Box 
          sx={{ 
            ...style.section_three,
            paddingTop: downMediumScreen ? "160px" : "370px",
            backgroundPosition: downMediumScreen ? "0% 50%" : "0 20%",
            backgroundSize: "contain",
            backgroundColor: "white"
          }}
        >
          <Typography
            sx={{ color: paletteColorConstant.black2 }}
            style={downMediumScreen ? mobileFontPreset.titleM : fontPreset.titleL}
          >
            Perjalanan divinepro
          </Typography>

          <Box>
            <Container sx={{ display: "flex", width: "100%" }}>
              <Box className="left__column__section__3"
                sx={{ width: downMediumScreen ? "50%" : "50%" }}
              />

              <Box className="right__column__section__3"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: downMediumScreen ? "55%" : "50%",
                  padding: downMediumScreen ? "20px 0 95px" : "60px 0 156px 0",
                  rowGap: downMediumScreen ? "20px" : "40px",
                }}
              >
                { PERJALANAN_DIVINE_PRO.map((journey, index) => (
                    <Box
                      key={`journey-${index + 1}`}
                      sx={{ display: "flex", flexDirection: "column", rowGap: downMediumScreen ? "4px" : "10px" }}
                      data-aos="fade-up"
                      data-aos-offset="100"
                      data-aos-duration="800"
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: paletteColorConstant.black1 }}
                        style={downMediumScreen ? fontPreset.body12Med : fontPreset.titleM }
                      >
                        <span style={{ color: paletteColorConstant.primaryBlue150 }}>
                          { journey.tahun }
                        </span>{" "}
                        { journey.titleTahun }
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: paletteColorConstant.black2 }}
                        style={ downMediumScreen ? { ...fontPreset.caption, lineHeight: "11.72px"  } : { ...fontPreset.body20Reg, lineHeight: "23.44px" } }
                      >
                        { journey.body }
                      </Typography>
                    </Box>
                  ))
                }
              </Box>
            </Container>
          </Box>
        </Box>

        <Box sx={{ ...style.section_four, height: downMediumScreen ? "200px" : "560px" }}>
          <Box className="background__linear"
            sx={style.section_four.backgroundLinear}
          >
            <Container>
              <Box className="section__wrapper"
                sx={{ 
                  display: "flex",
                  padding: downMediumScreen ? "22px" : "60px 40px",
                  columnGap: downMediumScreen ? "46px" : "103px",
                }}
              >
                <Box sx={{ width: "50%", color: paletteColorConstant.white2 }}>
                  <Typography 
                    variant="body1"
                    component={'h1'}
                    sx={downMediumScreen ? style.section_four.mobileTitle : style.section_four.title}
                  >
                    Visi Kami
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={downMediumScreen ? style.section_four.mobileBody : style.section_four.body}
                  >
                    { VISI_PARAGRAF_ATAS }
                    <br /><br />
                    <span style={{ fontStyle: "italic" }}>
                      { VISI_PARAGRAF_BAWAH }
                    </span>
                  </Typography>
                </Box>

                <Box sx={{ width: "50%", color: paletteColorConstant.white2 }}>
                  <Typography
                    variant="body1"
                    component={'h1'}
                    sx={downMediumScreen ? style.section_four.mobileTitle : style.section_four.title}
                  >
                    Misi Kami
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={downMediumScreen ? style.section_four.mobileBody : style.section_four.body}
                  >
                    { MISI_PARAGRAF_ATAS }
                    <br /><br />
                    <span style={{ fontStyle: "italic" }}>
                      { MISI_PARAGRAF_BAWAH }
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>

        <Box sx={style.section_five}>
          <Container>
            <Typography
              variant="h3"
              sx={{ color: paletteColorConstant.black2 }}
              style={downMediumScreen ? mobileFontPreset.titleXL : fontPreset.titleL}
              align="center"
            >
              Meet the Leadership Team
            </Typography>

            <Box className="team__section"
              sx={{ marginTop: downMediumScreen ? "40px" : "60px" }}
            >
              <Grid 
                container
                columnSpacing={"38px"}
                rowSpacing={"32px"}
                justifyContent="center"
              >
                <Grid item xs={12} sm={4}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <PersonCard data={LEADERSHIP_TEAM[1]} />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <PersonCard data={LEADERSHIP_TEAM[0]} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>

        <Box sx={style.section_six}>
          <GetInTouchForm />
        </Box>

        <Box 
          sx={{ 
            ...style.section_seven,
            height: downMediumScreen ? "225px" : "618px",
            backgroundImage: downMediumScreen ? "url(/images/AboutUs/about_us_section_mobile.png)" : "url(/images/AboutUs/about_us_section.png)",
            backgroundPosition: "0 0",
          }}
        >
          <Box sx={{ width: downMediumScreen ? "30%" : "50%" }} />
          <Box sx={{ width: downMediumScreen ? "70%" : "50%" }}>
            <DownloadCompanyProfileForm />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default AboutUs;
