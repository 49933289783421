import axios from 'axios';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: null,
    loading: false,
    success: false,
    error: false
}

export const getAllProducts = 
    createAsyncThunk(
        'products/getAllProducts',
        async ({ sort = [], page = [], category = "" }, thunkAPI) => {
            
            let params = []
            if(category !== "") params.push(category)
            if(sort.length !== 0) params.push(...sort)
            if(page.length !== 0) params.push(...page)

            try {
                const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/productservice/?${params.join("&")}`)
                return res.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error)
            }
        }
    )

const productSlice = createSlice({
    name: 'products',
    initialState,
    extraReducers: {
        [getAllProducts.pending]: (state) => {
            state.loading = true
            state.products = null
            state.success = false
            state.error = false
        },
        [getAllProducts.fulfilled]: (state, action) => {
            state.products = action.payload
            state.success = true
            state.loading = false
            state.error = false
        },
        [getAllProducts.rejected]: (state, action) => {
            state.error = action.payload
            state.products = null
            state.loading = false
            state.success = false
        }
    }
})

// export const { clearCart } = productSlice.actions
export default productSlice.reducer