import { useContext, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'
// MATERIAL UI
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// CONSTANTS
import { ConsultationContext } from '../../hooks/consultationContext'
import { fontPreset } from "../../constant/fontConstant";
import { CustomAutoCompleteTextField, CustomTimePickerField } from '../../constant/textFieldConstant'
// COMPONENTS
import PriceTextField from '../common/PriceTextField'

const style = {
    rootContainer: { 
        display: "flex",
        columnGap: "clamp(1.25rem, -1.499rem + 10.2767vw, 7.75rem)",
        flexDirection: {xs: "column", sm: "row"},
        alignItems: {xs: "center", sm: "flex-start"},
        rowGap: "40px"
    },
    imageContainer: {
        maxWidth: "clamp(12.5rem, 2.2441rem + 38.3399vw, 36.75rem)",
        flexBasis: "1/2"
    },
    formField: {
        display: "flex",
        flexDirection: "column",
        rowGap: "40px",
        flex: "1 1 0%",
        flexBasis: "1/2",
        width: "100%",
        padding: {xs: "0 8px", sm: "0 0" }
    },
    formSection: {
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
    },
    buttonSection: {
        display: "flex",
        justifyContent: "flex-end",
        columnGap: "20px"
    },
    labelInput: { 
        ...fontPreset.body16Reg,
        marginBottom: "8px"
    },
    placeholderImage: { 
        aspectRatio: "1 / 1",
        height: "100%",
        width: "clamp(12.5rem, 2.2441rem + 38.3399vw, 36.75rem)" 
    },
    datePickerProps: { 
        sx: { 
            borderRadius: "6px",
            '& .MuiPickersDay-root': { 
                '&.Mui-selected': { color: "white" }
            },
        }
    }
}

const EventDateStep = () => {
    const cityName = ""

    const consultationState = useContext(ConsultationContext)

    const cityState = useSelector((state) => state.cityList)
    const { cityList } = cityState

    const [budgetEstimation, setBudgetEstimation] = useState("");
    const [eventDate, setEventDate] = useState(new Date());

    return (
        <Box sx={style.rootContainer}>
            {/* IMAGE */}
            <Box sx={style.imageContainer}>
                <LazyLoadImage 
                    src={"/images/consultation/pie-chart-illus.png"}
                    alt='pie chart image'
                    width={"100%"}
                    style={{ aspectRatio: "1 / 1" }}
                    placeholder={<Skeleton sx={style.placeholderImage} />}
                />
            </Box>

            {/* FORMS */}
            <Box sx={style.formField}>
                <div className="tanggal_event_field">
                    <Typography 
                        variant="body1"
                        sx={style.labelInput}
                    >
                        Tanggal Event Kamu
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            inputFormat="dd/MM/yyyy"
                            value={eventDate}
                            onChange={(newValue) => setEventDate(newValue)}
                            PaperProps={style.datePickerProps}
                            renderInput={(params) => ( <CustomTimePickerField {...params} fullWidth /> )}
                            disablePast
                        />
                    </LocalizationProvider>
                </div>

                <div className="lokasi_event_field">
                    <Typography 
                        sx={style.labelInput}
                    >
                        Lokasi Event Kamu
                    </Typography>
                    <Autocomplete
                        autoComplete
                        freeSolo
                        options={
                            cityList !== null ? 
                                cityList?.data?.length !== 0 ? cityList.data.map((option) => option.city) : [] 
                            : []
                        }
                        value={cityName}
                        noOptionsText={'List kota tidak tersedia'}
                        // onChange={(event, newValue) => { setCityName(newValue) }}
                        disabled={cityState.loading || cityList === null}
                        renderInput={(params) => (
                            <CustomAutoCompleteTextField
                                {...params}
                                placeholder={cityState.loading ? "Mengambil Data" : "Masukkan Nama Kota"}
                            />
                        )}
                        autoSelect
                    />
                </div>

                <div className="estimasi_budget_field">
                    <PriceTextField
                        label={"Estimasi Budget Kamu (dalam IDR)"}
                        placeholder="100.000.000"
                        value={budgetEstimation}
                        onChange={setBudgetEstimation}
                    />
                </div>

                <div className="action__button"
                    style={style.buttonSection}
                >
                    <Button
                        sx={{ marginTop: "-10px" }}
                        onClick={() => { consultationState.prevStep() }}
                        size="large"
                    >
                        Sebelumnya
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ marginTop: "-10px" }}
                        onClick={() => { consultationState.nextStep() }}
                        // disabled={checkDisabledButtonTwo()}
                        size="large"
                    >
                        Selanjutnya
                    </Button>
                </div>
            </Box>
        </Box>
    )
}

export default EventDateStep