import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../screen/style/TestiSwiper.css";
// import required modules
import { Navigation, Pagination, Autoplay } from "swiper";
// MATERIAL UI
import { Box, useMediaQuery, useTheme } from "@mui/material";
// MUI ICONS
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// CONSTANT
import { paletteColorConstant } from "../../constant/colorConstant";
// COMPONENTS
import WhatWeDoSlide from "./WhatWeDoSlide";

const whatWeDoData = [
  {
    desc: "Dokumentasi secara live dengan multicam, drone, atau green screen",
    image: "./images/Product_And_Service/what_we_do/what-we-do-1.png",
  },
  {
    desc: "Menyediakan beragam peralatan untuk kebutuhan green screen",
    image: "./images/Product_And_Service/what_we_do/what-we-do-2.png",
  },
  {
    desc: "Menyiarkan konten langsung ke berbagai platform",
    image: "./images/Product_And_Service/what_we_do/what-we-do-3.png",
  },
  {
    desc: "Dokumentasi berbagai acara dengan kualitas hasil Full HD.",
    image: "./images/Product_And_Service/what_we_do/what-we-do-4.png",
  },
  {
    desc: "Menyediakan gerai foto yang hasilnya bisa dicetak secara real-time.",
    image: "./images/Product_And_Service/what_we_do/what-we-do-5.png",
  },
  {
    desc: "Menyediakan alat dan mekanisme penataan cahaya",
    image: "./images/Product_And_Service/what_we_do/what-we-do-6.png",
  },
  {
    desc: "Menyediakan alat dan mekanisme penataan suara", 
    image: "./images/Product_And_Service/what_we_do/what-we-do-7.png",
  },
  {
    desc: "Menyediakan studio yang bertempat di Bintaro berukuran 8x4 meter",
    image: "./images/Product_And_Service/what_we_do/what-we-do-8.png",
  },
  {
    desc: "Menyediakan tim show yang solid, berpengalaman dan berkompeten",
    image: "./images/Product_And_Service/what_we_do/what-we-do-9.png",
  },
];

const style = {
  nextPrevSwiper: {
    fontSize: "28px",
    padding: 0,
    transition: "color .25s ease",
    color: "#CACACA",
  },
  enableButton: {
    backgroundColor: "white",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "9px",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    opacity: 0,
    transition: "opacity .2s ease",
    "&:hover": {
      backgroundColor: paletteColorConstant.primaryBlue150,
      "& .icon__slider__pagination": {
        color: "#FFF",
      },
    },
  },
  prevButton: {
    position: "absolute",
    backgroundColor: "#004459",
    top: "22%",
    left: "calc((100% - 1152px) / 2)",
    "&:hover": {
      color: "#FFFFFF",
    },
    zIndex: 10,
  },
  nextButton: {
    position: "absolute",
    top: "22%",
    right: "calc((100% - 1152px) / 2)",
    zIndex: 10,
  },
};

const WhatWeDoSlider = () => {
  const swiperRef = React.useRef(null);
  const [navigation, setNavigation] = useState(null);
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))


  return (
    <Box className="whatWeDo__swiper__wrap"
      sx={{
        marginTop: downMediumScreen ? "40px" : "60px",
        position: "relative",
        "&:hover": { "& .slider__pagination__button": { opacity: 1 } },
      }}
    >
      <Swiper
        ref={swiperRef}
        modules={[Pagination, Navigation, Autoplay]}
        navigation={{ prevEl, nextEl }}
        slidesPerView={1}
        slidesPerGroup={1}
        pagination={{ clickable: true, el: navigation }}
        style={{
          height: "100%",
          width: "100%",
        }}
        className="sliderWhatWeDoWraper"
        autoplay={{ delay: 3000 }}
        loop="true"
      >
        {[1, 2, 3].map((data, index) => (
          <SwiperSlide
            key={`article-${index}`}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <WhatWeDoSlide
              data={
                data === 1 ? 
                  whatWeDoData.slice(0, 3) : 
                data === 2 ?
                  whatWeDoData.slice(3, 6) : 
                // DATA === 3
                    whatWeDoData.slice(6, 9)
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Box className="slider__pagination__button"
        ref={(node) => setPrevEl(node)}
        sx={{ ...style.prevButton, ...style.enableButton }}
      >
        <ArrowBackIosNewIcon
          className="icon__slider__pagination"
          sx={style.nextPrevSwiper}
        />
      </Box>
      <Box className="slider__pagination__button"
        ref={(node) => setNextEl(node)}
        sx={{ ...style.enableButton, ...style.nextButton }}
      >
        <ArrowForwardIosIcon
          className="icon__slider__pagination"
          sx={style.nextPrevSwiper}
        />
      </Box>
    </Box>
  );
};

export default WhatWeDoSlider;
