import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// MATERIAL UI
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
//CONSTANT
import { fontPreset, mobileFontPreset } from "../../constant/fontConstant";
import { paletteColorConstant } from "../../constant/colorConstant";
const style = {
  card: { width: "238px" },
};

export const WhatWeDoCard = ({ props }) => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Box className="whatwedo__card" sx={style.card}>
      <Box className="whatwedo__content">
        <LazyLoadImage 
          src={props.image}
          alt="what-we-do"
          width={downMediumScreen ? 60 : 200}
          height={downMediumScreen ? 60 : 200}
        />
        <Typography
          sx={{
            color: paletteColorConstant.black2,
            textAlign: "center",
            marginTop: "12px",
          }}
          style={downMediumScreen ? mobileFontPreset.body10Lig : fontPreset.body20Lig}
        >
          {props.desc}
        </Typography>
      </Box>
    </Box>
  );
};
