import { useEffect } from "react"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from "react-redux"
import { Link as RouteLink } from "react-router-dom"
import "../../../screen/style/home.css"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "../../../screen/style/SwiperCustom.css"
// MATERIAL UI
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
// ICONS
import YoutubeIcon from "../../icons/YoutubeIcon"
import InstagramIcon from "../../icons/InstagramIcon"
import LinkedInIcon from "../../icons/LinkedInIcon"
// CONSTANT
import { fontPreset } from "../../../constant/fontConstant"
import {
  BODY_BANNER,
  DIVINE_IG_LINK,
  DIVINE_LINKEDIN_LINK,
  DIVINE_YOUTUBE_LINK,
  TITLE_BANNER_LINE_1,
  TITLE_BANNER_LINE_2,
  TITLE_BANNER_LINE_3
} from "../../../constant/utils/contentBody"
// ACTIONS
import { getHeroBanner } from "../../../features/asset/heroBannerSlice"

const style = {
  content: {
    height: "100%",
    width: "100%",
    position: "absolute",
    inset: 0,
    display: "flex",
  },
  textSection: {
    display: "flex",
    flexDirection: "column",
    color: "white",
  },
  actionSection: {
    display: "flex",
    justifyContent: 'space-between'
  },
  actionSectionMobile: {
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: '20px'
  },
  socialMediaMobile: { 
    display: "flex",
    columnGap: "40px",
    alignItems: "center",
    justifyContent: "center"
  },
  contentContainer: { 
    display: 'flex',
    flexDirection: 'column',
    rowGap: "54px"
  }
};

const SOCIAL_MEDIA = [
  { href: DIVINE_YOUTUBE_LINK, icon: <YoutubeIcon /> },
  { href: DIVINE_IG_LINK, icon: <InstagramIcon /> },
  { href: DIVINE_LINKEDIN_LINK, icon: <LinkedInIcon /> },
]

const HeroSlider = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const heroBanner = useSelector((state) => state.heroBanner)
  const { heroBannerItem, loading } = heroBanner

  useEffect(() => {
    dispatch(getHeroBanner())
  }, [])

  return (
    <Swiper
      style={{ position: "relative" }}
    >
      <SwiperSlide className="img-wrapper"
        style={{ height: downMediumScreen ? "660px" : "90vh", maxHeight: "718px" }}
      >
        { 
          loading ? 
            <Skeleton 
              variant="rectangular"
              animation="wave"
              height={downMediumScreen ? "100%" : "90vh"}
              sx={{ maxHeight: "718px" }}
              width={"100%"}
            /> :
          heroBannerItem && 
            <LazyLoadImage
              className="section-four-image zoom"
              src={heroBannerItem.data[0]?.picture}
              height={"100%"}
              alt={"banner-img-1"}
              style={{ objectPosition: downMediumScreen ? '75% 100%' : '90.5% 0%' }}
            />
        }
        
        <Box className="hero__content" 
          sx={{ 
            ...style.content,
            padding: downMediumScreen ? '250px 0 40px 0' : "0 0 0 0",
            alignItems: !downMediumScreen ? 'center' : 'flex-start'
          }}
        >
          <Container sx={style.contentContainer}>

            <Box className="text__section" 
              sx={{ 
                ...style.textSection,
                width: downMediumScreen ? "100%" : "40%",
                rowGap: downMediumScreen ? "10px" : "2rem",
                maxWidth: downMediumScreen ? "323px" : "100%",
              }}
            >
              <Box>
                <Typography variant="h1" 
                  sx={downMediumScreen ? fontPreset.titleL : fontPreset.titleXl}
                >
                  { TITLE_BANNER_LINE_1 }
                </Typography>
                <Typography variant="h1" 
                  sx={downMediumScreen ? fontPreset.titleL : fontPreset.titleXl}
                >
                  { TITLE_BANNER_LINE_2 } 
                </Typography>
                <Typography variant="h1" 
                  sx={downMediumScreen ? fontPreset.titleL : fontPreset.titleXl}
                >
                  { TITLE_BANNER_LINE_3 }
                </Typography>
              </Box>

              <Typography variant="body1" 
                sx={downMediumScreen ? fontPreset.body14Lig : fontPreset.body20Lig}
                style={{ lineHeight: 'normal' }}
              >
                { BODY_BANNER }
              </Typography>

              <Box className="action__section" 
                sx={{ ...style.actionSection, marginTop: downMediumScreen ? '6px' : '0px' }}
              >
                <Button
                  color="primary"
                  variant={"contained"}
                  size={"large"}
                  LinkComponent={RouteLink}
                  to={"/consultation"}
                >
                  Konsultasi Gratis
                </Button>

                <Box className='social__media__icons' 
                  sx={{ display: { xs: "none", md: "flex" }, columnGap: "20.35px", alignItems: "center" }}
                >
                  { 
                    SOCIAL_MEDIA.map((media, index) => (
                      <Link 
                        href={media.href}
                        underline="none"
                        target="_blank"
                        rel={"noopener"}
                        sx={{ width: "40px", height: "40px", display: "flex", alignItems: 'center' }}
                        key={`media-hero-${index + 1}`}
                      >
                        { media.icon }
                      </Link>
                    ))
                  }
                </Box>
              </Box>
            </Box>

            { 
              downMediumScreen &&
                <Box className='social__media__icons__mobile'
                  sx={style.socialMediaMobile}
                >
                  { 
                    SOCIAL_MEDIA.map((media, index) => (
                      <Link 
                        href={media.href}
                        underline="none"
                        target="_blank"
                        rel={"noopener"}
                        sx={{ width: "40px", height: "40px", display: "flex", alignItems: 'center' }}
                        key={`media-hero-${index + 1}`}
                      >
                        { media.icon }
                      </Link>
                    ))
                  }
                </Box>
            }
          </Container>
        </Box>
      </SwiperSlide>
    </Swiper>
  );
};

export default HeroSlider;
