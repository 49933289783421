import { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useLocation, useNavigate } from "react-router-dom";
// MATERIAL UI
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useTheme } from "@mui/material";
// ICONS
import MenuIcon from "@mui/icons-material/Menu";
// CONSTANTS
import { fontPreset } from "../../constant/fontConstant";
import { paletteColor, paletteColorConstant } from "../../constant/colorConstant";
// COMPONENTS
import MobileMenuDialog from "./Header/MobileMenuDialog";

const navItems = [
  { title: "Product & Service", url: "/product-services", child: "/product" },
  { title: "Portfolio", url: "/portfolio", child: null },
  { title: "About", url: "/about-us", child: null },
  { title: "Articles", url: "/articles", child: "/article" },
];

const style = {
  menuButton: {
    textTransform: "capitalize",
    ...fontPreset.body16Lig,
  },
  menuNavDesktop: {
    display: { xs: "none", md: "flex" },
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  menuNavDesktopItems: { 
    display: { xs: "none", md: "flex" },
    columnGap: "40px"
  }
};

function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  // MOBILE MENU
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const handleOpenMobileMenu = () => {
    setOpenMobileMenu(true)
  }
  const handleCloseMobileMenu = () => {
    setOpenMobileMenu(false)
  }
  // DESKTOP MENU
  const handleOnClickMenu = (item) => {
    navigate(item.url);
  }

  // CHECK ACTIVE URL
  const checkURL = (url, childUrl) => {
    return location.pathname.includes(url) || location.pathname.includes(childUrl)
  }

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar
          component="header"
          sx={{
            backgroundColor: "rgba(30, 30, 30, 0.20)",
            backdropFilter: "blur(5px)",
            boxShadow: 0,
            height: { xs: "80px", md: '92px'},
            justifyContent: "center",
          }}
        >
          <Container>
            <Toolbar 
              style={{ padding: 0 }}
              sx={{ 
                display: 'flex',
                flexDirection: { xs: 'row-reverse', md: "row" },
                justifyContent: { xs: 'space-between', md: 'flex-start' },
              }}
            >
              <Box className="hamburger-menu" 
                sx={{ display: 'flex', alignItems: 'center', columnGap: '20px', '&:hover': { cursor: "pointer" } }}
                onClick={handleOpenMobileMenu}
              >
                <Typography
                  sx={{ display: { xs: 'flex', sm : 'none' }, ...fontPreset.body16Lig, color: paletteColorConstant.gray2 }}
                >
                  Menu
                </Typography>
                <IconButton
                  color="inherit"
                  aria-label="open mobile menu"
                  edge="start"
                  sx={{ display: { md: "none" }, color: 'white', padding: { xs: "4px", md: "8px" } }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            
              <Link to={"/"}>
                <LazyLoadImage
                  src={"/divine-logo.png"}
                  alt={"Divine Pro Logo"}
                  height={downMediumScreen ? "65px" : "80px"}
                  width={downMediumScreen ? "65px" : "80px"}
                />
              </Link>

              <Box className="menu__navbar__desktop" sx={style.menuNavDesktop}>
                  <Box sx={style.menuNavDesktopItems} className="side-nav-items">
                      {navItems.map((item) => (
                        <Button
                            key={`menu-${item.title}-button`}
                            sx={{
                              ...style.menuButton,
                              "&:hover": { color: paletteColor.blue50 },
                              color: checkURL(item.url.slice(0), item.child?.slice(0)) ? paletteColor.primaryBlue : paletteColor.white0,
                              fontWeight: checkURL(item.url.slice(0), item.child?.slice(0)) ? "bold" : "400",
                            }}
                            onClick={() => handleOnClickMenu(item)}
                            size="large"
                        >
                            {item.title}
                        </Button>
                      ))}
                  </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>

      <Box component="nav">
        <MobileMenuDialog 
          open={openMobileMenu}
          handleClose={handleCloseMobileMenu}
        />
      </Box>
    </>
  );
};

export default Header;
