import React from 'react'
// MATERIAL UI
import { Box, Skeleton } from '@mui/material'

const ArticleDetailLoading = () => {
  return (
    <Box>
        <Skeleton
            animation="wave"
            variant="rectangular" 
            width={"100%"}
            height={80}
            sx={{ borderRadius: "20px", padding: "0", margin: "0" }}
        />

        <Skeleton
            animation="wave"
            variant="rectangular" 
            width={"100%"}
            height={320}
            sx={{ borderRadius: "20px", padding: "0", margin: "50px 0 0 0" }}
        />
    </Box>
  )
}

export default ArticleDetailLoading