import React from 'react'
// MUI
import { Box, Container, Skeleton, useMediaQuery, useTheme } from '@mui/material'

const ProductDetailSkeleton = () => {
    const theme = useTheme()
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

    return (
        <Container sx={{ padding: downMediumScreen ? "0px" : "auto" }}>
            <Box className="title__article__dialog"
                sx={{ display: "flex", justifyContent: "center" }}
            >
                <Skeleton 
                    variant="rectangular"
                    animation="wave"
                    height={48}
                    width={downMediumScreen ? 160 : 320}
                />
            </Box>

            <Box className="image__article__dialog"
                sx={{ marginTop: "40px", 
                    display: "flex",
                    flexDirection: downMediumScreen ? "column" : "",
                    columnGap: "60px",
                    rowGap: downMediumScreen ? "60px" : ""
                }}
            >
                <Box className="left__column"
                    sx={{ display: downMediumScreen ? "flex" : "", width: downMediumScreen ? "100%" : "50%" }}
                >
                    <Skeleton 
                        variant="rectangular"
                        animation="wave"
                        height={372}
                        width={"100%"}
                    />
                </Box>

                <Box className="right__column"
                    sx={{
                        display: "flex",
                        width: downMediumScreen ? "100%" : "50%",
                        flexDirection: "column",
                        rowGap: "40px",
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "12px" }}>
                        <Skeleton 
                            variant="rectangular"
                            animation="wave"
                            height={30}
                            width={150}
                        />

                        <Skeleton 
                            variant="rectangular"
                            animation="wave"
                            height={75}
                            width={"100%"}
                        />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "12px" }}>
                        <Skeleton 
                            variant="rectangular"
                            animation="wave"
                            height={30}
                            width={150}
                        />

                        <Skeleton 
                            variant="rectangular"
                            animation="wave"
                            height={50}
                            width={"100%"}
                        />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Skeleton 
                            variant="rectangular"
                            animation="wave"
                            height={30}
                            width={"100%"}
                        />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "12px" }}>
                        <Skeleton 
                            variant="rectangular"
                            animation="wave"
                            height={30}
                            width={150}
                        />

                        <Skeleton 
                            variant="rectangular"
                            animation="wave"
                            height={68}
                            width={"100%"}
                        />
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default ProductDetailSkeleton