import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// CONSTANTS
import { capturePageview } from '../constant/utils/captureEvents'

const RecordPageVisit = () => {
    const location = useLocation()

    useEffect(() => {
        capturePageview({ page: window.location.href })
        return () => {}
    }, [location.pathname])
    

    return null
}

export default RecordPageVisit