import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
// MATERIAL UI
import { Box, Container, Typography, useTheme, useMediaQuery, Breadcrumbs, Link } from "@mui/material";
  // MUI ICONS
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// CONSTANT
import { paletteColorConstant } from "../constant/colorConstant";
import { fontPreset } from '../constant/fontConstant';
import { articleDetailDummy } from '../constant/dummy';
// ACTIONS
import { getArticleById } from '../features/article/detailArticleSlice';
// COMPONENTS
import ArticleDetailLoading from '../components/Articles/ArticleDetailLoading';
import ArticleDetailDummy from '../components/Articles/ArticleDetailDummy';

const ArticleDetails = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const { slug } = params

    const theme = useTheme();
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

    const articleDetailState = useSelector((state) => state.articleDetail)
    const { loading, error, success, data } = articleDetailState

    useEffect(() => {
        if (slug !== "dummy") dispatch(getArticleById({ id: slug ?? '' }))
    }, [])

    const printTitle = (title) => {
        if(slug !== "dummy") return title.substring(0, 25) + "..."
        else return articleDetailDummy.title.substring(0, 25) + "..."
    }

    const breadcrumbsNavigation = [
        <Link 
            underline="none"
            key="1"
            color="inherit"
            href="/"
            sx={{ 
                color: paletteColorConstant.primaryBlue150,
                "&:hover" : {
                    color: paletteColorConstant.secondaryBlue1,
                }
            }}
        >
            Divine
        </Link>,
        <Link
            underline="none"
            key="2"
            color="inherit"
            href="/articles"
            sx={{ 
            color: paletteColorConstant.primaryBlue150,
            "&:hover" : {
                color: paletteColorConstant.secondaryBlue1,
            }
            }}
        >
            Articles
        </Link>,
        <Typography 
            key="3"
            sx={{ color: paletteColorConstant.black1 }}
        >
            { printTitle(data?.data?.title ?? "") }
        </Typography>,
    ];
    

    return (
        <Box sx={{ paddingTop: downMediumScreen ? "30px" : "65px" }}>
            <Container>
                <Box className='breadcrumbs__navigation'>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                        >
                        {breadcrumbsNavigation}
                    </Breadcrumbs>
                </Box>

                <article>
                    <Box className='article__root'
                        sx={{ padding: downMediumScreen ? "72px 0 30px 0" : "80px 0", position: "relative" }}
                    >
                        {   slug === "dummy" ?
                                <ArticleDetailDummy /> :
                            loading ? 
                                <ArticleDetailLoading /> :
                            success ? 
                                <>
                                    <Box className="title__article__dialog">
                                        <Typography
                                            component={"h1"}
                                            sx={{ ...fontPreset.titleXl,
                                                fontSize: downMediumScreen
                                                    ? fontPreset.titleXlMobile.fontSize
                                                    : fontPreset.titleXl,
                                                color: paletteColorConstant.black1,
                                                flexGrow: 1,
                                                lineHeight: downMediumScreen ? "28px" : "49.22px",
                                            }}
                                            align="center"
                                        >
                                            { data?.data?.title ?? articleDetailDummy.title }
                                        </Typography>
                                    </Box>

                                    <Box className="image__article__dialog" 
                                        sx={{ marginTop: "50px" }}
                                    >
                                        <LazyLoadImage
                                            alt={`article-big-event`}
                                            src={data?.data?.photo ?? articleDetailDummy.image}
                                            width={"100%"}
                                        />
                                    </Box>

                                    <Box className="body__article__dialog"
                                        dangerouslySetInnerHTML={{ __html: data?.data?.body ?? <p style={{ color: "red" }}>Body</p> }} 
                                        sx={{ marginTop: "50px" }}
                                    /> 
                                </>
                                :
                            error ?
                                <Box>Error...</Box> :
                            null
                        }
                    </Box>
                </article>
            </Container>
        </Box>
    )
}

export default ArticleDetails