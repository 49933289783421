import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// MATERIAL UI
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
// ICONS
// CONSTANTS
import { paletteColorConstant } from '../../constant/colorConstant'
import { articleDetailDummy } from '../../constant/dummy'
import { fontPreset } from '../../constant/fontConstant'
import { stylePreset } from '../../constant/stylePreset'

const style = {
    artcileSection: {
        ...stylePreset.columnFlex,
        rowGap: "12px",
    },
};
  
const fontStyle = {
    subTitle: { ...fontPreset.titleS, color: paletteColorConstant.black1 },
    body: {
        ...fontPreset.body16Lig,
        color: paletteColorConstant.black2,
        lineHeight: "18.75px",
    },
};

const ArticleDetailDummy = () => {
    const theme = useTheme()
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

    return (
        <>
            <Box className="title__article__dialog">
                <Typography
                    component={"h1"}
                    sx={{ ...fontPreset.titleXl,
                        fontSize: downMediumScreen
                            ? fontPreset.titleXlMobile.fontSize
                            : fontPreset.titleXl,
                        color: paletteColorConstant.black1,
                        flexGrow: 1,
                        lineHeight: downMediumScreen ? "28px" : "49.22px",
                    }}
                    align="center"
                >
                    { articleDetailDummy.title }
                </Typography>
            </Box>

            <Box className="image__article__dialog" 
                sx={{ marginTop: "50px" }}
            >
                <LazyLoadImage
                    alt={`article-big-event`}
                    src={articleDetailDummy.image}
                    width={"100%"}
                    // width={'1152px'}
                    // style={{ objectFit: 'cover' }}
                />
                <Typography
                    sx={{ ...fontPreset.body12Lig, color: paletteColorConstant.gray1, fontStyle: "italic" }}
                >
                    src : ini dari link
                </Typography>
            </Box>

            <Box 
                dangerouslySetInnerHTML={{ 
                    __html: `<div>
                        Siapa yang nggak kangen nonton konser secara offline? Tenang aja, kamu nggak sendiri kok!

                        Bagaimana tidak? Hampir tiga tahun setelah munculnya virus Corona, baru belakangan ini industri hiburan Indonesia perlahan-lahan mulai bangkit. Event konser mulai ramai, diiringi dengan jumlah penontonnya yang membludak.

                        Hausnya dahaga penonton terkait event konser ini, perlu menjadi perhatian serius bagi para penyelenggara/ promotor konser. Berbagai perlengkapan yang mendukung, serta persiapan tim yang matang akan membuat konser terselenggara apik. Harapannya, penonton akan menantikan event serupa di kemudian hari.

                        Agar dapat menyelenggarakan konser dengan baik, berikut divine sajikan 5 tips biar konser kamu berkesan di hati penonton.

                        <p><strong>1. Hire Tim Show Management</strong></p>

                        <p>Banyak yang belum tahu seberapa pentingnya show management dalam kelancaran suatu event. Jika diibaratkan anatomi tubuh, show management bisa dianggap seperti tulang punggung dalam sebuah event. Mengapa? Karena dalam suatu event, mereka membutuhkan seorang expertis yang bisa menganalisa dan memberikan perencanaan yang matang. Perlu ada seseorang yang menjaga / mengarahkan sequence by sequence mood satu konsep, agar acara bisa berjalan dengan mulus sesuai dengan keinginan client.</p>

                        <p>Ada banyak divisi dalam tim show management jika acara kamu mau berjalan dengan lancar. Di antaranya yakni: Show Director, Stage Manager, Stage Crew, Music Director, Lighting Designer, LO MC dan LO setiap penampilan, hingga Sound Engineer.</p>

                        <p>&nbsp;</p>

                        <p><strong>2. Soundsystem yang Berkualitas</strong></p>

                        <p>Perlu menjadi kesepakatan bersama bahwa soundsystem yang baik bukan hanya soal pengeras suara, namun lebih dari itu. Soundsystem yang baik perlu memperhatikan banyak aspek. Mulai sound monitor di panggung dan sound PA di floor; peralatan audio untuk setiap alat musik penampil; kebutuhan mic penampil dan pembawa acara; memastikan tidak ada suara yang berbalik (feedback) dari setiap alat-alatnya; hingga memastikan adanya channel berlebih untuk input dan output dari tim-tim lain seperti dokumentasi, multicamsystem, dll.</p>

                        <p>Karenanya, dibutuhkan tim yang handal dan alat-alat yang termutakhir, agar suara yang dihasilkan dapat terdengar &lsquo;sopan&rsquo; di telinga penonton.</p>

                        <p>&nbsp;</p>

                        <p><strong>3. Siapkan Kebutuhan Multimedia Agar Mata Penonton Terhibur</strong></p>

                        <p>Banyak hal yang bisa kamu tambahkan agar konser kamu membuat penonton terkesan, salah satunya yakni dengan adanya penambahan LED. Dengan adanya LED, kamu bisa menampilkan berbagai aset-aset. Mulai dari menampilkan artis yang sedang on stage, hingga beragam aset video bergerak, yang dapat membuat kesan &ldquo;megah&rdquo; pada konser yang kamu buat.</p>

                        <p>Dan jangan lupa, menampilkan artis yang disukai oleh penonton di LED dengan ukuran besar, bisa buat mereka berteriak histeris, sehingga konser kamu akan selalu diingat oleh penonton.</p>

                        <p>Lighting Stage yang Memukau</p>

                        <p>Tak hanya berfungsi sebagai penerang konser saat event malam hari, lampu pentas juga mempunyai elemen artistik yang bisa memberikan mood penampil di atas panggung.</p>

                        <p>Semisal lagu yang dimainkan bernuansa pop slow, lighting stage bisa dimainkan agar penonton yang hadir benar-benar terbawa suasana lagunya.</p>

                        <p>&nbsp;</p>

                        <p><strong>5. After Movie</strong></p>

                        <p>Dokumentasi atau yang kini biasa disebut After Movie menjadi salah satu bagian yang paling penting dalam penyelenggaraan suatu konser. Karena, video after movie ini diperlukan untuk penyelenggaraan event selanjutnya, baik bagi pihak penyelenggara maupun penonton.</p>

                        <p>Bagi penyelenggara, after movie bisa dijadikan sebagai bahan evaluasi ke depannya. Misalnya, saat menjadi penyelenggara konser ternyata terjadi kericuhan. Jika ada dokumentasi, maka kita bisa mengamati bagian mana yang kurang aman, kurang pencahayaan dan sebagainya.</p>

                        <p>Selain itu, after movie juga bisa dijadikan sebagai bahan promosi bagi penyelenggara/ promotor, saat ingin menyelenggarakan event yang serupa dikemudian hari. Ibaratnya, video ini bisa dijadikan sebagai portolio oleh pihak promotor.</p>

                        <p>Sedangkan bagi penonton, video after movie bisa membuat penonton yang hadir saat konser, merasakan kembali atmosfer dari acara tersebut. Tentunya, membuat mereka jadi menantikan kembali konser yang kamu selenggarakan selanjutnya.</p>

                        Jika 5 tips itu dipenuhi, dijamin konser kamu bakal berkesan bagi audience yang hadir! Kalo kamu merasa sulit, kamu bisa menghubungi tim Divine Production untuk konsultiasi lebih lanjut, kok terkait penyelenggaraan event-event konser seperti ini! 

                        Selamat ‘berdendang ria’ guys, jangan lupa tetap menerapkan protokol kesehatan, yah!
                    </div>`
                }}
                sx={{ padding: "60px 0", ...stylePreset.columnFlex, rowGap: "40px" }}
            />

            {/* <Box className="body__article__dialog"
                sx={{
                    padding: "60px 0",
                    ...stylePreset.columnFlex,
                    rowGap: "40px",
                }}
            >
                <Typography
                    sx={{ ...fontStyle.body,
                        fontSize: downMediumScreen
                            ? fontPreset.body14Lig.fontSize
                            : fontStyle.body.fontSize,
                    }}
                >
                    { articleDetailDummy.content }
                </Typography>

                { articleDetailDummy.contentPoint.map((val, index) => (
                    <Box className="section__article"
                        key={`content-${index + 1}`}
                        sx={{ ...style.artcileSection }}
                    >
                        <Typography
                            sx={{ ...fontStyle.subTitle,
                                fontSize: downMediumScreen
                                ? fontPreset.titleMMobile.fontSize
                                : fontStyle.subTitle.fontSize,
                            }}
                        >
                            { val.title }
                        </Typography>

                        <Typography
                            sx={{ ...fontStyle.body,
                                fontSize: downMediumScreen
                                ? fontPreset.body14Lig.fontSize
                                : fontStyle.body.fontSize,
                            }}
                        >
                            { val.desc }
                        </Typography>
                    </Box>
                )) 
                }
            
                <Box className="section__article"
                    sx={{ ...style.artcileSection }}
                >
                    <Typography
                        sx={{ ...fontStyle.body,
                            fontSize: downMediumScreen
                            ? fontPreset.body14Lig.fontSize
                            : fontStyle.body.fontSize,
                        }}
                    >
                        Jika 5 tips itu dipenuhi, dijamin konser kamu bakal berkesan bagi audience yang hadir! Kalo kamu merasa sulit, kamu bisa menghubungi tim Divine Production untuk konsultiasi lebih lanjut, kok terkait penyelenggaraan event-event konser seperti ini! <br/><br />
                        Selamat ‘berdendang ria’ guys, jangan lupa tetap menerapkan protokol kesehatan, yah!
                    </Typography>
                </Box>
            </Box> */}
        </>
    )
}

export default ArticleDetailDummy